/*-----------------------------------------------
    Generated by SwaggerToTS
    Do not customize this file, update the generator
    Copyright C Space 2024
-----------------------------------------------*/
/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from 'axios';
import { appId } from '../azure/auth';
import {
    getAuthToken,
    clientApiScope,
    clientApiUri
} from '../azure/auth';
import {
    ChartDataDetailModel,
    ChartUpdateModel,
    ChartInputModel,
} from './ClientApiTypes';

/**
 * Get Chart by chart Id.
*/
export async function chartFetchById(clientId: string, accountId: string, chartId: string): Promise<ChartDataDetailModel> { 
    const token = await getAuthToken([clientApiScope]);
    
    const resp = await axios.get<ChartDataDetailModel>(
        `${clientApiUri}/Chart/${clientId}/${accountId}/${chartId}`,
        { headers: { 
            'authorization': `bearer ${token}`,
			'cspace-appId': `${appId}` 
        } });
    return resp.data;
}

/**
 * Update chart
*/
export async function chartSave(clientId: string, accountId: string, chartId: string, input: ChartUpdateModel): Promise<ChartDataDetailModel> { 
    const token = await getAuthToken([clientApiScope]);
    const resp = await axios.put<ChartDataDetailModel>(
        `${clientApiUri}/Chart/${clientId}/${accountId}/${chartId}`,
            input,
        { headers: {
            'authorization': `bearer ${token}`,
			'cspace-appId': `${appId}`
        } });
    return resp.data;
}

/**
 * Delete chart
*/
export async function chartDeleteById(clientId: string, accountId: string, chartId: string): Promise<void> { 
    const token = await getAuthToken([clientApiScope]);
    const resp = await axios.delete(
        `${clientApiUri}/Chart/${clientId}/${accountId}/${chartId}`,
        { headers: { 
            'authorization': `bearer ${token}`,
			'cspace-appId': `${appId}`  
        } });
    return resp.data;
}

/**
 * Create new chart
*/
export async function chartCreate(clientId: string, accountId: string, input: ChartInputModel): Promise<ChartDataDetailModel> { 
    const token = await getAuthToken([clientApiScope]);
    const resp = await axios.post<ChartDataDetailModel>(
        `${clientApiUri}/Chart/${clientId}/${accountId}`,
            input,
        { headers: { 
            'authorization': `bearer ${token}`,
			'cspace-appId': `${appId}`  
        } });
    return resp.data;
}

/**
 * Manually kicks off a data refresh of the chart. Just a stub.
*/
export async function chartRefreshData(clientId: string, accountId: string, chartId: string): Promise<boolean> { 
    const token = await getAuthToken([clientApiScope]);
    const resp = await axios.post<boolean>(
        `${clientApiUri}/Chart/${clientId}/${accountId}/${chartId}/refresh`,
            {},
        { headers: { 
            'authorization': `bearer ${token}`,
			'cspace-appId': `${appId}`  
        } });
    return resp.data;
}

