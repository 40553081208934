import { useMutation, useQuery } from '@tanstack/react-query';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import Button from '../../../common/ButtonNew';
import { accountFetchByName as getAccountInfo } from '../../../common/clientApi/AccountApi';
import { accountTeamMemberCanEdit } from '../../../common/clientApi/AccountPermissions';
import {
    AccountDetailModel,
    ApiResultError,
    TeamMemberCreateModel,
    TeamMemberFetchListParams,
    TeamMemberViewModel,
    TeamMemberViewModelPagedList,
    UserFetchListParams,
    UserViewModel,
    UserViewModelPagedList,
} from '../../../common/clientApi/ClientApiTypes';
import { teamMemberCreate, teamMemberFetchList } from '../../../common/clientApi/TeamMemberApi';
import { globalUserFetchList } from '../../../common/clientApi/UserApi';
import ErrorDisplay from '../../../common/components/ErrorDisplay';
import { useAccountPermission } from '../../../common/userPermission';
import AddTeamMemberModal from './AddTeamMemberModal';
import AddTeamMemberWidget from './AddTeamMemberWidget';
import TeamMemberTile from './TeamMemberTile';
import { getModalList, teamMemberTiles, useQueryStringTM, useQueryStringUser } from './TeamMemberUtils';
import { teamMemberHeroCopy } from '../../../common/mediaApi/UserApi';
import { AxiosError } from 'axios';
import { getApiErrorStatus } from '../../../common/utils';

type AccountInfoParams = {
    client: string;
    account: string;
};

export default function TeamMembers() {
    const [memberList, setMemberList] = useState<TeamMemberViewModel[]>([]);
    const [userList, setUserList] = useState<UserViewModel[]>([]);

    const queryParamsTM = useQueryStringTM();
    const queryParamsUser = useQueryStringUser();

    const [showAddModal, setShowAddModal] = useState<boolean>(false);
    const { client, account } = useParams<AccountInfoParams>();
    const fetchParamsTeamMember = useRef<TeamMemberFetchListParams>(queryParamsTM);
    const fetchParamsUser = useRef<UserFetchListParams>(queryParamsUser);

    const tryCopyTeamMemberPhoto = async (clientId: string, accountId: string, userId: string) => {
        try {
            const media = await teamMemberHeroCopy(clientId, accountId, userId);
            return media.mediaId;
        } catch (ex) {
            const error = ex as AxiosError<ApiResultError>;
            const status = getApiErrorStatus(error);
            if (status === 404) {
                // We got NotFound (404) when there is no UserProfile photo to copy - in that case we should return null
                return null;
            }

            throw ex;
        }
    };

    const createTeamMember = async (memberModel: TeamMemberCreateModel) => {
        if (!accountDetails || !memberModel) {
            return;
        }

        const mediaId = await tryCopyTeamMemberPhoto(
            accountDetails.clientId,
            accountDetails.accountId,
            memberModel.userId
        );
        memberModel.mediaId = mediaId;
        await teamMemberCreate(accountDetails.clientId, accountDetails.accountId, memberModel);
    };

    const showModalHandler = () => {
        setShowAddModal(true);
    };

    const {
        mutate: addMemberMutate,
        isLoading: addMemberIsLoading,
        isError: isAddMemberError,
    } = useMutation({
        mutationFn: (memberModel: TeamMemberCreateModel) => createTeamMember(memberModel),
        onSuccess: () => {
            teamMembersRefetch();
            setShowAddModal(false);
        },
    });

    const { data: accountDetails, error: accountInfoFetchError } = useQuery<AccountDetailModel, Error>({
        queryKey: ['accountInfo', client!, account!],
        queryFn: () => getAccountInfo(client!, account!),
        staleTime: 10000,
        enabled: !!client && !!account,
    });

    const {
        data: teamMembersData,
        refetch: teamMembersRefetch,
        error: teamMembersFetchError,
    } = useQuery<TeamMemberViewModelPagedList, Error>({
        queryKey: ['teamMembers', accountDetails?.clientId, accountDetails?.accountId, fetchParamsTeamMember],
        queryFn: () =>
            teamMemberFetchList(accountDetails!.clientId, accountDetails!.accountId, fetchParamsTeamMember.current),
        staleTime: 10000,
        enabled: !!accountDetails,
    });

    const { data: userData, error: accountUsersFetchError } = useQuery<UserViewModelPagedList, Error>({
        queryKey: ['accountUsers', accountDetails?.accountId, fetchParamsUser],
        queryFn: () => globalUserFetchList(accountDetails!.accountId, fetchParamsUser.current),
        staleTime: 10000,
        enabled: !!accountDetails,
    });

    const canEditTeamMember = useAccountPermission(accountTeamMemberCanEdit);

    useEffect(() => {
        if (teamMembersData && teamMembersData.list) {
            const members = teamMembersData.list.filter((x) => x.userId);
            setMemberList(members);
        }
    }, [teamMembersData]);

    useEffect(() => {
        if (userData && userData.list) {
            setUserList(userData.list);
        }
    }, [userData]);

    return (
        <ErrorDisplay
            config={{
                default:
                    'The server encountered an internal error or misconfiguration and was unable to load the list of team members.',
            }}
            errorMessageClassName="flex-1 bg-white p-4"
            error={accountInfoFetchError ?? accountUsersFetchError ?? teamMembersFetchError}
        >
            <div className="flex-1">
                {showAddModal && (
                    <AddTeamMemberModal
                        isError={isAddMemberError}
                        isLoading={addMemberIsLoading}
                        users={getModalList(userList, memberList)}
                        closeHandler={() => setShowAddModal(false)}
                        createMember={addMemberMutate}
                    />
                )}
                <div className="flex justify-between border-b-[1px] bg-white pb-4 pl-6 pt-10">
                    <h3>Team Members</h3>
                    {memberList.length > 0 && canEditTeamMember && (
                        <Button onClick={showModalHandler} buttonStyle="text">
                            Add More Members
                        </Button>
                    )}
                </div>
                <div className="max-h-[440px] min-h-[307px] overflow-y-auto bg-white">
                    {memberList.length == 0 && canEditTeamMember && (
                        <AddTeamMemberWidget onClick={showModalHandler} label={'Add Team Members'} />
                    )}
                    <div>
                        {teamMemberTiles(memberList, userList).map((m) => (
                            <TeamMemberTile
                                key={m.teamMemberId}
                                member={m}
                                clientId={accountDetails?.clientId ?? ''}
                                accountId={accountDetails?.accountId ?? ''}
                                refetchTeamMembersHandler={() => teamMembersRefetch()}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </ErrorDisplay>
    );
}
