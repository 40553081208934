import { ChartDisplayType, ExportPowerPointCreateModel, ExportPowerPointSlideModel } from './ClientApiTypes';
import { ChartData } from '../../client/Audiences/ChartModels';
import { formatDate } from '../dateUtils';

export type PowerPointChartSlide = ExportPowerPointSlideModel & {
    title: string;
    chartType: ChartDisplayType;
    data: PowerPointChartData[];
};

export type PowerPointChartData = {
    label: string;
    value: number;
};

export type PowerPointTitleSlide = ExportPowerPointSlideModel & {
    title: string;
    description: string;
    date: string;
};

function chartDataToSlide(chart: ChartData): PowerPointChartSlide {
    const vm: PowerPointChartSlide = {
        slideType: 'Chart',
        title: chart.title,
        chartType: chart.chartType,
        data: chart.data,
    };

    return vm;
}

export function createTitleSlide(title: string, description: string): ExportPowerPointSlideModel {
    // @ts-ignore
    const vm: ExportPowerPointSlideModel = {
        slideType: 'Title',
        title: title,
        description: description,
        date: formatDate(Date()),
    };

    return vm;
}

export function createKpiChartsExport(
    titleInfo: ExportPowerPointSlideModel,
    charts: ChartData[]
): ExportPowerPointCreateModel {
    const chartList = charts.map(chartDataToSlide);

    const list: ExportPowerPointSlideModel[] = [titleInfo, ...chartList];

    return { slideList: list };
}

export function createKpiChartExport(chart: ChartData): ExportPowerPointCreateModel {
    const chartSlide = chartDataToSlide(chart);

    const list: ExportPowerPointSlideModel[] = [chartSlide];

    return { slideList: list };
}
