/*-----------------------------------------------
    Generated by SwaggerToTS
    Do not customize this file, update the generator
    Copyright C Space 2024
-----------------------------------------------*/
/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from 'axios';
import { appId } from '../azure/auth';
import {
    getAuthToken,
    clientApiScope,
    clientApiUri
} from '../azure/auth';
import {
    ExportPowerPointCreateModel,
} from './ClientApiTypes';

/**
 * Creates a new Audience Chart PowerPoint Export
*/
export async function powerPointExport(input: ExportPowerPointCreateModel): Promise<Blob> { 
    const token = await getAuthToken([clientApiScope]);
    const resp = await axios.post<Blob>(
        `${clientApiUri}/PowerPointExport/PowerPointExport`,
            input,
        { headers: { 
            'authorization': `bearer ${token}`,
			'cspace-appId': `${appId}`  
        },
        responseType: 'blob' 
    });
    return resp.data;
}

