import PageHeader from '../../common/components/PageHeader';
import SettingsNavigation from './SettingsNavigation';
import SettingsFinancialsContent from './SettingsFinancials/SettingsFinancialsContent';
import CommunityConfigurationSettings from './CommunityConfiguration/CommunityConfigurationSettings';
import MarketingContent from './MarketingContent/MarketingContent';
import TeamMembersContainer from './TeamMembers/TeamMembersContainer';
import { WithAccountPermission } from '../../common/userPermission';
import { accountSettingsCanView } from '../../common/clientApi/AccountPermissions';
import { Forbidden } from '../../common/ErrorPage';

type Props = {
    tab: SettingsTab;
};

export enum SettingsTab {
    Financials,
    MarketingContent,
    TeamMembers,
    CommunityComposition,
}

export default function SettingsPage({ tab }: Props) {
    return (
        <WithAccountPermission permission={accountSettingsCanView} permissionDeniedElem={<Forbidden />}>
            <>
                <PageHeader title="Settings" />
                <div className="mb-8 mt-16 px-16">
                    <SettingsNavigation />
                </div>
                {tab === SettingsTab.Financials && <SettingsFinancialsContent />}
                {tab === SettingsTab.CommunityComposition && <CommunityConfigurationSettings />}
                {tab === SettingsTab.MarketingContent && <MarketingContent />}
                {tab === SettingsTab.TeamMembers && <TeamMembersContainer />}
            </>
        </WithAccountPermission>
    );
}
