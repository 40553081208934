import { useEffect, useState } from 'react';

export type ValidationItem<TValue, TModel> = {
    message: string;
    isInvalid: (value: TValue, model: TModel) => boolean;
};

export type ValidationConfiguration<TModel> = {
    [key in keyof TModel]?: ValidationItem<TModel[keyof TModel], TModel>[];
};

type KeyType = string | number | symbol;

type EditedType<K extends KeyType> = { [key in K]?: boolean };

type Messages<K extends KeyType> = { [key in K]?: string };

type ValidationResult<K extends KeyType> = {
    validationMessages: Messages<K>;
    isModelInvalid: boolean;
};

type MessageObject = {
    apiErrorMessage?: string;
    validationErrorMessage?: string;
};

type MessagesInternal<K extends KeyType> = { [key in K]?: MessageObject };

export type ApiErrors<K extends KeyType> = { [fieldName in K]?: string[] | null };

export const useValidation = <TModel extends object>(params: {
    model: TModel;
    configuration: ValidationConfiguration<TModel>;
    edited?: EditedType<keyof TModel>;
    focused?: string;
    apiErrors?: ApiErrors<keyof TModel>;
}): ValidationResult<keyof TModel> => {
    const { model, configuration, edited, focused, apiErrors } = params;
    const [messages, setMessages] = useState<MessagesInternal<keyof TModel>>({});

    const getApiError = (key: keyof TModel, errors?: ApiErrors<keyof TModel>): string | undefined =>
        errors?.[key]?.join('; ');

    useEffect(() => {
        const newMessages: MessagesInternal<keyof TModel> = { ...messages };

        Object.keys(model).forEach((k) => {
            const key = k as keyof TModel;
            const apiMsg = getApiError(key, apiErrors);
            newMessages[key] = {
                ...newMessages[key],
                apiErrorMessage: apiMsg,
            };
        });

        setMessages(newMessages);
    }, [apiErrors]);

    useEffect(() => {
        const newMessages: MessagesInternal<keyof TModel> = {};

        Object.entries(configuration).forEach(([k, v]) => {
            const key = k as keyof TModel;
            const newValue = model[key];
            const itemConf = v as ValidationItem<TModel[keyof TModel], TModel>[];
            const itemMsg = itemConf.find((conf) => conf.isInvalid(newValue as TModel[keyof TModel], model))?.message;
            newMessages[key] = {
                ...newMessages[key],
                validationErrorMessage: itemMsg,
            };
        });

        setMessages(newMessages);
    }, [model, configuration]);

    const getValidationMessages = (): Messages<keyof TModel> => {
        const res: Messages<keyof TModel> = {};

        const wasEdited = (key: keyof TModel): boolean => {
            if (!edited) {
                return false;
            }
            return edited[key] ?? false;
        };

        Object.entries(messages)
            .filter(([k]) => k !== focused && wasEdited(k as keyof TModel))
            .forEach(([k, v]) => {
                res[k as keyof TModel] =
                    (v as MessageObject).apiErrorMessage ?? (v as MessageObject).validationErrorMessage;
            });
        return res;
    };

    const getIsModelInvalid = (): boolean => {
        if (!messages) {
            return false;
        }
        return Object.values(messages).some((v) =>
            Boolean((v as MessageObject).apiErrorMessage ?? (v as MessageObject).validationErrorMessage)
        );
    };

    return {
        validationMessages: getValidationMessages(),
        isModelInvalid: getIsModelInvalid(),
    };
};
