import { PlusCircleIcon } from '@heroicons/react/24/solid';

type Props = {
    onClick: () => void;
    label: string;
};

export default function AddTeamMemberWidget({ onClick, label }: Props) {
    return (
        <div className="flex h-[307px] flex-col justify-between bg-white px-6 py-8">
            <button
                onClick={onClick}
                className="group flex flex-grow cursor-pointer flex-col items-center justify-center gap-4 rounded border-2 border-dashed border-primary-100 bg-background p-10 transition-colors hover:bg-primary-50"
            >
                <PlusCircleIcon className="h-12 w-12 text-primary-500 transition-transform group-hover:scale-125" />
                <div className="bg-white py-[8px] px-[12px] uppercase font-bold text-secondary-500 border border-secondary-500 border-solid focus:bg-secondary-800 focus:text-white pointer-events-none w-full bg-transparent text-xs leading-[18px]">
                    <span>{label}</span>
                </div>
            </button>
        </div>
    );
}
