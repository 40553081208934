/*-----------------------------------------------
    Generated by SwaggerToTS
    Do not customize this file, update the generator
    Copyright C Space 2024
-----------------------------------------------*/
/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from 'axios';
import { appId } from '../azure/auth';
import {
    getAuthToken,
    clientApiScope,
    clientApiUri
} from '../azure/auth';
import {
    AccountDataProviderViewModelPagedList,
    AccountDataProviderFetchListParams,
    AccountDataProviderViewModel,
    AudienceChartType,
    AccountDataProviderFetchForAudienceChartsParams,
} from './ClientApiTypes';

/**
 * Returns paged list of Account Data Providers
*/
export async function accountDataProviderFetchList(accountId: string, input: AccountDataProviderFetchListParams): Promise<AccountDataProviderViewModelPagedList> { 
    const token = await getAuthToken([clientApiScope]);
    const q: string[] = [];
    if (input.filter) { q.push(`filter=${encodeURIComponent(input.filter)}`); }
    if (input.page) { q.push(`page=${encodeURIComponent(input.page)}`); }
    if (input.pageSize) { q.push(`pageSize=${encodeURIComponent(input.pageSize)}`); }
    if (input.orderBy) { q.push(`orderBy=${encodeURIComponent(input.orderBy)}`); }
    const p = q.length > 0 ? `?${q.join('&')}` : '';
    const resp = await axios.get<AccountDataProviderViewModelPagedList>(
        `${clientApiUri}/AccountDataProvider/${accountId}${p}`,
        { headers: { 
            'authorization': `bearer ${token}`,
			'cspace-appId': `${appId}` 
        } });
    return resp.data;
}

/**
 * Returns list of Account Data Providers if they have charts
*/
export async function accountDataProviderFetchForAudienceCharts(clientId: string, accountId: string, input: AccountDataProviderFetchForAudienceChartsParams): Promise<AccountDataProviderViewModel[]> { 
    const token = await getAuthToken([clientApiScope]);
    const q: string[] = [];
    if (input.chartType) { q.push(`chartType=${encodeURIComponent(input.chartType)}`); }
    const p = q.length > 0 ? `?${q.join('&')}` : '';
    const resp = await axios.get<AccountDataProviderViewModel[]>(
        `${clientApiUri}/AccountDataProvider/${clientId}/${accountId}${p}`,
        { headers: { 
            'authorization': `bearer ${token}`,
			'cspace-appId': `${appId}` 
        } });
    return resp.data;
}

