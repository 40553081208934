import React from 'react';
import { ReactChildren } from '../utils';

type Props = {
    title: string;
    subtitle?: string;
    actions?: ReactChildren;
};

export default function PageHeader({ title, subtitle, actions }: Props) {
    return (
        <div className={`flex items-center justify-between bg-white px-8 py-8 lg:px-16`}>
            <div className="flex flex-col items-start justify-start gap-1">
                <h1>{title}</h1>
                {subtitle && <span>{subtitle}</span>}
            </div>
            <div className="flex items-center gap-1">{actions}</div>
        </div>
    );
}
