import { useEffect, useRef, useState } from 'react';
import { KpiChartData } from '../../common/components/KpiChart';
import { isEllipsisActive, toPercentages } from '../../common/utils';
import TooltipContent from '../../common/TooltipContent';
import { sortKpiChartData } from './ChartUtils';

type Props = {
    data: KpiChartData[];
};

export default function CommunityCompositionChartLegend({ data }: Props) {
    const sortedData = sortKpiChartData(data);
    const percentages = toPercentages(sortedData.map((e) => e.value));

    return (
        <div className="flex min-w-[80px] flex-col justify-start gap-3">
            {sortedData?.map((item, idx) => (
                <LegendItem key={idx} percentage={percentages[idx]} item={item} />
            ))}
        </div>
    );
}

type LegendItemProps = {
    percentage: number;
    item: KpiChartData;
};

function LegendItem({ percentage, item }: LegendItemProps) {
    const labelRef = useRef<HTMLSpanElement>(null);
    const [isTruncated, setIsTruncated] = useState(false);

    useEffect(() => {
        setIsTruncated(isEllipsisActive(labelRef.current));
    }, [labelRef.current?.offsetWidth]);

    return (
        <div className="group relative flex items-center text-sm">
            <div style={{ backgroundColor: item.color }} className="mr-2 inline-block h-3.5 w-3.5 flex-none" />
            <span ref={labelRef} className="truncate pt-0.5">
                {item.label}
            </span>
            <span>&nbsp;{`(${percentage}%)`}</span>
            {isTruncated && (
                <TooltipContent className="-top-12 -ml-3">{`${item.label} (${percentage}%)`}</TooltipContent>
            )}
        </div>
    );
}
