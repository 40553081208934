import React, { useMemo } from 'react';
import { CHART_ITEMS_ORDINAL } from '../../../../common/consts/enums';
import { commaSeparatedNumber } from '../../../../common/utils';

type Props = {
    label: string;
    value: number;
    ordinal: number;
    color: string;
    currency: string;
};

export default function ProjectBudgetWidgetLegendEntry({ label, value, ordinal, color, currency }: Props) {
    const getCurrencySymbol = useMemo(() => {
        switch (currency) {
            case 'USD':
                return '$';
            case 'EUR':
                return '€';
            case 'GBP':
                return '£';
            default:
                return '';
        }
    }, [currency]);

    const isTotal = ordinal === CHART_ITEMS_ORDINAL.TOTAL;
    const isRemaining = ordinal === CHART_ITEMS_ORDINAL.REMAINING;
    const isBudgetOverflown = isRemaining && value <= 0;

    const orderUtilityClass = useMemo(() => {
        if (isTotal) {
            return 'order-first';
        } else if (isRemaining) {
            return 'order-last';
        } else {
            return '';
        }
    }, [isTotal, isRemaining]);

    return (
        <div
            className={`${orderUtilityClass} flex items-center justify-between gap-4 ${
                isRemaining ? 'border-none' : 'border-b'
            } border-gray-200 py-2`}
        >
            <span
                className={`transition-colors ${isBudgetOverflown ? 'text-error-700' : 'text-black'} ${
                    isTotal ? 'text-lg uppercase' : 'text-base'
                } font-haas font-medium`}
            >
                {label}
            </span>
            <span
                style={{ color }}
                className={`transition-colors ${isBudgetOverflown && '!text-error-700'} ${
                    isRemaining ? 'text-xl' : 'text-lg'
                } font-haas font-medium`}
            >
                {`${getCurrencySymbol}${commaSeparatedNumber(value)}`}
            </span>
        </div>
    );
}
