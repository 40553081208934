import CommunityConfigurationPreview from './CommunityConfigurationPreview';
import CommunityConfigurationProfileFields from './CommunityConfigurationProfileFields';
import ErrorDialog from '../../../common/ErrorDialog';
import ErrorDisplay from '../../../common/components/ErrorDisplay';
import SkeletonText from '../../../common/components/skeleton/SkeletonText';
import UnsavedChangesModal from '../../../common/UnsavedChangesModal';
import { accountDataProviderFetchList } from '../../../common/clientApi/AccountDataProviderApi';
import { accountId, clientId } from '../../Tenant';
import { audiencesCanView } from '../../../common/clientApi/AccountPermissions';
import { CommunitySelect } from '../../Audiences/CommunitySelect';
import { fetchShowChartN, toggleShowChartN } from '../../../common/clientApi/AccountApi';
import { InitialFieldValues } from './CommunityConfigurationTypes';
import { Loading } from '../../../common/components/form/Form';
import { PermissionDenied, WithAccountPermission } from '../../../common/userPermission';
import { PError } from '../../../common/apiTypes';
import { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useUnsavedChanges } from '../../../common/hooks/useUnsavedChanges';
import {
    AccountDataProviderViewModel,
    AccountDataProviderViewModelPagedList,
} from '../../../common/clientApi/ClientApiTypes';

export default function CommunityConfigurationSettings() {
    const [dataProvider, setDataProvider] = useState<AccountDataProviderViewModel | null>(null);
    const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState<boolean>(false);
    const { showPrompt, confirmNavigation, cancelNavigation } = useUnsavedChanges(showUnsavedChangesModal);
    const [dashboards, setDashboards] = useState<string[]>([]);
    const [audiences, setAudiences] = useState<string[]>([]);
    const [initialFieldValues, setInitialFieldValues] = useState<InitialFieldValues>();
    const [showN, setShowN] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | null>('');

    const { isLoading: isLoadingProviders, error: providersError } = useQuery<
        AccountDataProviderViewModelPagedList,
        Error
    >({
        queryKey: ['AccountDataProviders', clientId, accountId],
        queryFn: () =>
            accountDataProviderFetchList(accountId, {
                pageSize: '0' as unknown as number,
            }),
    });

    const audiencesHasChanged = useMemo(
        () =>
            audiences.filter((a) => !initialFieldValues?.audienceInitialValues.includes(a)).length > 0 ||
            (initialFieldValues !== undefined && audiences.length !== initialFieldValues?.audienceInitialValues.length),
        [audiences, initialFieldValues]
    );

    const dashboardsHasChanged = useMemo(
        () =>
            dashboards.filter((d) => !initialFieldValues?.dashboardInitialValues.includes(d)).length > 0 ||
            (initialFieldValues !== undefined &&
                dashboards.length !== initialFieldValues?.dashboardInitialValues.length),
        [dashboards, initialFieldValues]
    );

    const setInitialShowChartN = () => {
        const exec = async () => {
            const show = await fetchShowChartN(clientId!, accountId!);
            setShowN(show);
        };
        exec();
    };

    useEffect(() => {
        setInitialShowChartN();
    }, []);

    useEffect(() => {
        setShowUnsavedChangesModal(audiencesHasChanged || dashboardsHasChanged);
    }, [audiencesHasChanged, dashboardsHasChanged]);

    const changeShowChartNToggle = () => toggleShowChartN(clientId, accountId);

    const { mutate: changeShowNMutate } = useMutation<boolean, PError>(changeShowChartNToggle, {
        onError: () => {
            setErrorMessage('An error occurred while saving the Show Chart N value. Please try again.');
        },
        onSuccess: (showChartN) => setShowN(showChartN),
    });

    return (
        <>
            {errorMessage && (
                <ErrorDialog onClose={() => setErrorMessage(null)}>
                    <>{errorMessage}</>
                </ErrorDialog>
            )}
            <UnsavedChangesModal
                showDialog={showPrompt}
                confirmNavigation={confirmNavigation}
                cancelNavigation={cancelNavigation}
            />
            <WithAccountPermission permission={audiencesCanView} permissionDeniedElem={<PermissionDenied />}>
                <ErrorDisplay error={providersError} errorMessageClassName="text-center italic mx-16">
                    <Loading isLoading={isLoadingProviders} />
                    {!isLoadingProviders && (
                        <div className="mx-16 mb-8 flex min-w-[700px] flex-col gap-1">
                            <section className="rounded-sm bg-white p-6">
                                <div className="flex max-w-4xl flex-col">
                                    <div className="mb-6 flex items-start justify-between border-b border-primary-50 pb-3">
                                        {dataProvider && <h2 className="text-xl">{dataProvider.name} Composition</h2>}
                                        {!dataProvider && (
                                            <div className="w-72">
                                                <SkeletonText height="h-8" />
                                            </div>
                                        )}
                                        <CommunitySelect
                                            clientId={clientId}
                                            accountId={accountId}
                                            onSelected={setDataProvider}
                                        />
                                    </div>
                                    {dataProvider && (
                                        <CommunityConfigurationProfileFields
                                            dataProvider={dataProvider}
                                            dashboards={dashboards}
                                            setDashboards={setDashboards}
                                            audiences={audiences}
                                            setAudiences={setAudiences}
                                            initialFieldValues={initialFieldValues}
                                            setInitialFieldValues={setInitialFieldValues}
                                            hasChanged={audiencesHasChanged || dashboardsHasChanged}
                                        />
                                    )}
                                </div>
                            </section>
                            {dataProvider && (
                                <section className="rounded-sm bg-white p-6">
                                    <div className="mx-16 mb-8 mt-[72px] rounded-sm  border border-primary-50 bg-white">
                                        <CommunityConfigurationPreview
                                            dp={dataProvider}
                                            audiencesProfileFieldIds={audiences}
                                            dashboardsProfileIds={dashboards}
                                            hasChanged={audiencesHasChanged || dashboardsHasChanged}
                                            clientId={clientId}
                                            accountId={accountId}
                                            showN={showN}
                                            onToggle={() => changeShowNMutate()}
                                        />
                                    </div>
                                </section>
                            )}
                        </div>
                    )}
                </ErrorDisplay>
            </WithAccountPermission>
        </>
    );
}
