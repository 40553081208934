import React, { useMemo } from 'react';
import { ChartDisplayType } from '../../../../common/clientApi/ClientApiTypes';
import KpiChart, { KpiChartStyle } from '../../../../common/components/KpiChart';
import ProjectBudgetWidgetLegend from './ProjectBudgetWidgetLegend';
import { FinancialsWidgetCreateModel, FinancialsWidgetViewModel } from '../../../../common/clientApi/ClientApiTypes';
import { roundMoney } from '../../../../common/utils';
import { CHART_ITEMS_ORDINAL } from '../../../../common/consts/enums';
import { calculateRemainingBudget, getBudgetChartItems } from './ProjectBudgetWidgetUtils';
import { formatDateToMMMDDYYYY } from '../../../../common/dateUtils';

type Props = {
    widgetData: FinancialsWidgetCreateModel | FinancialsWidgetViewModel;
};

export default function ProjectBudgetWidgetPreview({ widgetData }: Props) {
    const widgetChartItems = widgetData?.chartItems ?? [];
    const chartData = useMemo(() => getBudgetChartItems(widgetData), [widgetChartItems]);
    const filteredChartData = chartData.filter((field) => field.ordinal !== CHART_ITEMS_ORDINAL.TOTAL);

    const totalBudget = widgetChartItems.find((item) => item.ordinal === CHART_ITEMS_ORDINAL.TOTAL)?.value ?? 0;
    const remainingBudget = useMemo(
        () => roundMoney(calculateRemainingBudget(totalBudget, widgetChartItems)),
        [totalBudget, widgetChartItems]
    );

    return (
        <>
            <div className="flex items-center justify-between">
                <h3>{widgetData.title}</h3>
                {widgetData.startDate && widgetData.stopDate && (
                    <span className="text-sm">{`${new Date(widgetData.startDate).toLocaleDateString()} - ${new Date(
                        widgetData.stopDate
                    ).toLocaleDateString()}`}</span>
                )}
            </div>
            <div className="mb-4 mt-2.5 w-full border-t border-gray-100" />
            {(widgetData as FinancialsWidgetViewModel).updatedDate && (
                <div className="text-xs leading-[18px]">
                    Updated {formatDateToMMMDDYYYY((widgetData as FinancialsWidgetViewModel).updatedDate)}
                </div>
            )}
            <div className="flex flex-col xl:flex-row xl:items-center xl:justify-between">
                <ProjectBudgetWidgetLegend currency={widgetData.currency as string} data={chartData} />
                <div className={`self-center transition-opacity ${remainingBudget > 0 ? 'opacity-100' : 'opacity-0'}`}>
                    <KpiChart
                        canExport={false}
                        showLegend={false}
                        chartType={KpiChartStyle.pie as ChartDisplayType}
                        outsideLabels={true}
                        width={450}
                        height={400}
                        data={filteredChartData}
                    />
                </div>
            </div>
        </>
    );
}
