import {
    AccountDataProviderViewModel,
    AudienceChartViewModelPagedList,
    AudienceChartViewModel,
    CatProfileField,
    ChartDisplayType,
    CatProfileFieldMetrics,
    ChartDataInputModel,
} from '../../../common/clientApi/ClientApiTypes';
import { CHART_COLORS, KpiChartData, KpiChartParams } from '../../../common/components/KpiChart';
import { SelectionEvent, SelectionEventType } from './CommunityConfigurationTypes';

export function filterProfileFieldByOptions(list: CatProfileField[] | null | undefined): CatProfileField[] {
    if (!list) {
        return [];
    }
    // removes any profile fields with no options, or more than 11 options
    return list.filter((x) => x.optionValues && x.optionValues.length > 0 && x.optionValues.length < 11);
}

export function extractProfileFieldId(audChart: AudienceChartViewModel): string {
    const pList = audChart.chart.properties ?? [];
    const pfPropList = pList.filter((y) => y.name.toLocaleLowerCase() === 'profilefieldid');
    if (pfPropList.length > 0) {
        const pfProp = pfPropList[0];
        return pfProp.valueId ?? '';
    }

    return '';
}

export function extractPropId(obj: AccountDataProviderViewModel, propName: string) {
    const pList = obj.properties!.filter((x) => x.name.toLocaleLowerCase() === propName.toLocaleLowerCase());
    if (pList.length > 0) {
        return pList[0].valueId;
    }
    return null;
}

export function extractAllProfileFieldIds(list: AudienceChartViewModel[]): string[] {
    const uniqueAudienceChartList = list.filter((x) => x.audienceChartId);
    const profileFieldIdList = uniqueAudienceChartList.map((x) => extractProfileFieldId(x)).filter((x) => !!x);

    return profileFieldIdList;
}
export function findAllDashboard(list: AudienceChartViewModelPagedList) {
    return list.list?.filter((x) => x.audienceChartType === 'Dashboard') ?? [];
}

export function findAllAudience(list: AudienceChartViewModelPagedList) {
    return list.list?.filter((x) => x.audienceChartType === 'Audience') ?? [];
}

export function isInList(list: string[], value: string): boolean {
    return list.some((a) => a == value);
}

export function doesChartExist(currentCharts: AudienceChartViewModel[], profileFieldId: string): boolean {
    if (!currentCharts || currentCharts.length === 0) {
        return false;
    }

    for (let i = 0; i < currentCharts.length; i++) {
        const chart = currentCharts[i];
        const pfId = extractProfileFieldId(chart);
        if (pfId === profileFieldId) {
            return true;
        }
    }

    return false;
}

export function mergeEvent(events: SelectionEvent[], newEvent: SelectionEvent): SelectionEvent[] {
    const eList = events.filter((x) => x.profileField != newEvent.profileField);
    return [...eList, newEvent];
}

export function findChartToRemove(currentCharts: AudienceChartViewModel[], eventList: SelectionEvent[]): string[] {
    const pfIds = findEventProfileIds(SelectionEventType.remove, eventList);
    const list: string[] = [];

    if (currentCharts && currentCharts.length > 0) {
        currentCharts.forEach((chart) => {
            const pfId = extractProfileFieldId(chart);
            if (isInList(pfIds, pfId)) {
                list.push(chart.audienceChartId);
            }
        });
    }

    return list;
}

export function findChartsToAdd(currentCharts: AudienceChartViewModel[], eventList: SelectionEvent[]): string[] {
    const pfIds = findEventProfileIds(SelectionEventType.add, eventList);
    const list: string[] = [];

    pfIds.forEach((profileFieldId) => {
        if (!doesChartExist(currentCharts, profileFieldId)) {
            list.push(profileFieldId);
        }
    });

    return list;
}

export function findEventProfileIds(eventType: SelectionEventType, eventList: SelectionEvent[]): string[] {
    return eventList.filter((x) => x.eventType === eventType).map((x) => x.profileField.profileFieldId);
}

export function findProfileField(list: CatProfileField[], profileFieldId: string): CatProfileField | null {
    const l = list.find((x) => x.profileFieldId === profileFieldId);
    return l ?? null;
}

export function chartDisplayTypeFromProvileField(pf: CatProfileField): ChartDisplayType {
    if (pf.profileFieldControlToShow!.toLocaleLowerCase() === 'checkbox') {
        return 'Bar';
    }

    return 'Pie';
}

export function profileFieldMetricsToChartData(metrics: CatProfileFieldMetrics) {
    const chartData: ChartDataInputModel[] = metrics.data!.map((d) => {
        return {
            dataId: d.profileFieldOptionId,
            name: d.profileFieldOptionLabel ?? 'Unknown',
            value: d.value,
        };
    });

    return chartData;
}

export function profileFieldMetricsToKpiData(metrics: CatProfileFieldMetrics, chartType: ChartDisplayType): KpiChartData[] {
    const dataList = metrics.data ?? [];
    const result = dataList.map((d, index) => {
        return {
            label: d.profileFieldOptionLabel,
            value: d.value,
            color: chartType == "Bar" ? CHART_COLORS[0] : CHART_COLORS[index]
        };
    });
    return result;
}

export function profileFieldToChartPreview(
    profileField: CatProfileField,
    metrics: CatProfileFieldMetrics
): KpiChartParams {
    const chartType = chartDisplayTypeFromProvileField(profileField);
    const data = profileFieldMetricsToKpiData(metrics, chartType);
    return {
        chartType,
        title: profileField.profileLabel,
        count: metrics.total,
        data: data,
        height: 350,
        width: chartType === 'Pie' ? 400 : 580,
        outsideLabels: true,
    };
}
