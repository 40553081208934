import React, { useState, useEffect } from 'react';
import {
    AccountDataProviderViewModel,
    AudienceChartFetchListParams,
    AudienceChartViewModelPagedList,
    CatProfileField,
    CatProfileFieldFetchListParams,
    CatProfileFieldPagedList,
} from '../../../common/clientApi/ClientApiTypes';
import { clientId, accountId } from '../../Tenant';
import { catProfileFieldFetchList as profileFieldFetchList } from '../../../common/clientApi/CatalystProfileFieldApi';
import { audienceChartFetchList as audChartFetchList } from '../../../common/clientApi/AudienceChartApi';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
    extractAllProfileFieldIds,
    filterProfileFieldByOptions,
    findAllAudience,
    findAllDashboard,
    isInList,
} from './CommunityConfigurationSelectors';
import Button from '../../../common/ButtonNew';
import { SelectionEventType } from './CommunityConfigurationTypes';
import * as EventSource from './CommunityConfigurationEventSource';
import CommunityConfigurationCheckbox from './CommunityConfigurationCheckbox';
import SkeletonRow from '../../../common/components/skeleton/SkeletonRow';
import { ErrorLabel } from '../../../common/components/form/Form';
import { useAccountPermission } from '../../../common/userPermission';
import { audienceChartsCanManage } from '../../../common/clientApi/AccountPermissions';
import { InitialFieldValues } from './CommunityConfigurationTypes';
import { successToast } from '../../../common/components/toastNotification';
import ErrorDisplay from '../../../common/components/ErrorDisplay';
import ErrorDialog from '../../../common/ErrorDialog';

type Props = {
    dataProvider: AccountDataProviderViewModel;
    dashboards: string[];
    setDashboards: (v: string[]) => void;
    audiences: string[];
    setAudiences: (v: string[]) => void;
    initialFieldValues: InitialFieldValues | undefined;
    setInitialFieldValues: (v: InitialFieldValues | undefined) => void;
    hasChanged: boolean;
};

export default function CommunityConfigurationProfileFields({
    dataProvider,
    dashboards,
    setDashboards,
    audiences,
    setAudiences,
    initialFieldValues,
    setInitialFieldValues,
    hasChanged,
}: Props) {
    const queryClient = useQueryClient();
    const [preferred, setPreffered] = useState<CatProfileField[]>([]);
    const [additional, setAdditional] = useState<CatProfileField[]>([]);
    const [isPublishing, setIsPublishing] = useState<boolean>(false);
    const [apiError, setApiError] = useState<Error | null>(null);
    const [showErrorDialog, setShowErrorDialog] = useState(false);

    const isReadOnly = !useAccountPermission(audienceChartsCanManage);

    function updateState(response: CatProfileFieldPagedList) {
        if (response && response.list) {
            const l = filterProfileFieldByOptions(response.list);
            const plist = l.filter((x) => x.isReportPreferred);
            setPreffered(plist);

            const alist = l.filter((x) => !x.isReportPreferred);
            setAdditional(alist);
            EventSource.setProfileFields(response.list);
        }
    }

    function updateSelected(response: AudienceChartViewModelPagedList) {
        if (response && response.list) {
            const dashList = findAllDashboard(response);
            const dashIdList = extractAllProfileFieldIds(dashList);
            setDashboards(dashIdList);
            EventSource.setDashboardCharts(dashList);

            const audList = findAllAudience(response);
            const audIdList = extractAllProfileFieldIds(audList);
            setAudiences(audIdList);

            setInitialFieldValues({
                ...initialFieldValues,
                audienceInitialValues: audIdList,
                dashboardInitialValues: dashIdList,
            });

            EventSource.setAudienceCharts(audList);
        }
    }

    useEffect(() => {
        EventSource.clear();
        EventSource.init(clientId, accountId, dataProvider);
    }, [dataProvider]);

    const filters: CatProfileFieldFetchListParams = {
        pageSize: '0' as unknown as number,
        profileFieldDataType: ['ClosedEndedField'],
        orderBy: 'ProfileLabelAsc',
    };

    const execFetchProfileFields = async () => {
        const result = await profileFieldFetchList(clientId, accountId, dataProvider!.accountDataProviderId, filters);
        updateState(result);
        return result;
    };

    const { isLoading: isLoadingCatProfileFields, error: profileFieldsError } = useQuery<
        CatProfileFieldPagedList,
        Error
    >({
        queryKey: ['CatProfileFields', dataProvider!.accountDataProviderId],
        queryFn: () => execFetchProfileFields(),
        enabled: !!dataProvider && !!dataProvider.accountDataProviderId,
    });

    const audChartFilters: AudienceChartFetchListParams = {
        accountDataProviderId: dataProvider.accountDataProviderId,
        pageSize: '0' as unknown as number,
    };

    const execFetchAudienceCharts = async () => {
        const result = await audChartFetchList(clientId, accountId, audChartFilters);
        updateSelected(result);
        return result;
    };

    const {
        isLoading: isLoadingAudChart,
        error: audienceChartError,
        refetch: refetchAudienceCharts,
    } = useQuery<AudienceChartViewModelPagedList, Error>({
        queryKey: ['AudChart', dataProvider!.accountDataProviderId],
        queryFn: () => execFetchAudienceCharts(),
        enabled: !!dataProvider && !!dataProvider.accountDataProviderId,
    });

    useEffect(() => {
        setApiError(profileFieldsError || audienceChartError);
    }, [setApiError, audienceChartError, profileFieldsError]);

    const isAudienceFull = audiences.length >= 2;

    function audienceSelectedHandler(pf: CatProfileField) {
        if (!isAudienceFull) {
            const l = [...audiences, pf.profileFieldId];
            setAudiences(l);

            EventSource.addAudienceEvent(SelectionEventType.add, pf);
        }
    }

    function audienceUnselectedHandler(pf: CatProfileField) {
        const l = audiences.filter((x) => x !== pf.profileFieldId);
        setAudiences(l);
        EventSource.addAudienceEvent(SelectionEventType.remove, pf);
    }

    function dashboardSelectedHandler(pf: CatProfileField) {
        const l = [...dashboards, pf.profileFieldId];
        setDashboards(l);
        EventSource.addDashboardEvent(SelectionEventType.add, pf);
    }

    function dashboardUnselectedHandler(pf: CatProfileField) {
        const l = dashboards.filter((x) => x !== pf.profileFieldId);
        setDashboards(l);
        EventSource.addDashboardEvent(SelectionEventType.remove, pf);
    }

    function isSelectedAudience(pf: CatProfileField) {
        return isInList(audiences, pf.profileFieldId);
    }

    function isSelectedDashboard(pf: CatProfileField) {
        return isInList(dashboards, pf.profileFieldId);
    }

    function shouldDisableCheckbox(pf: CatProfileField) {
        if (isReadOnly) {
            return true;
        }
        const isSelected = isSelectedAudience(pf);
        if (isSelected) {
            return false;
        }
        return isAudienceFull;
    }

    const publishClickHandler = async () => {
        setIsPublishing(true);
        try {
            await EventSource.publish();
        } catch (err) {
            setShowErrorDialog(true);
            setIsPublishing(false);
            refetchAudienceCharts();
            return err;
        }

        // from CommunityCompositionPanel.tsx
        queryClient.removeQueries({ queryKey: ['AudienceCharts', clientId, accountId] });
        // from CommunityComposition.tsx
        queryClient.removeQueries({ queryKey: ['AudienceDashboardCharts', clientId, accountId] });

        setInitialFieldValues({
            ...initialFieldValues,
            audienceInitialValues: audiences,
            dashboardInitialValues: dashboards,
        });

        successToast(`${dataProvider?.name ?? 'Community Composition'} chart has been published.`, 'RIGHT');
        setIsPublishing(false);
    };

    if (!dataProvider) {
        return null;
    }

    const canPublish = audiences.length === 2 && !isPublishing;
    const isLoading = isLoadingAudChart || isLoadingCatProfileFields;
    const showReportPreferred = !isLoadingCatProfileFields && preferred && preferred.length > 0;

    const HEADER_CELL_CLASSES = 'border-b border-primary-100 px-2 pb-2 pt-4';

    return (
        <div className="my-4 w-full">
            {showErrorDialog && (
                <ErrorDialog onClose={() => setShowErrorDialog(false)}>
                    <>An error occurred while publishing the content. Please try again.</>
                </ErrorDialog>
            )}
            <ErrorDisplay error={apiError} errorMessageClassName="text-center italic mx-16">
                <section className="max-h-[40em] overflow-y-scroll">
                    <table className="w-full">
                        <thead>
                            <tr className="bg-background p-8 font-bold">
                                <th className="p-4 text-start text-xs uppercase">Profile Fields</th>
                                <th className="px-4 text-xs uppercase">Display in Dashboard</th>
                                <th className="px-4 text-xs uppercase">Display on Audience Page</th>
                            </tr>
                        </thead>
                        <tbody>
                            {isLoading && (
                                <>
                                    <SkeletonRow columns={3} />
                                    <SkeletonRow columns={3} />
                                    <SkeletonRow columns={3} />
                                </>
                            )}
                            {showReportPreferred && (
                                <tr>
                                    <td
                                        className={`${HEADER_CELL_CLASSES} font-haas font-medium uppercase`}
                                        colSpan={2}
                                    >
                                        Report Preferred
                                    </td>
                                    <td className={`${HEADER_CELL_CLASSES} text-center text-xs`}>
                                        (Selection of 2 required)
                                    </td>
                                </tr>
                            )}
                            {!isLoadingCatProfileFields &&
                                preferred?.map((x) => (
                                    <tr key={x.profileFieldId} className="border-b border-primary-50">
                                        <td className="line-clamp-2 max-h-14 p-2 pl-4">{x.profileLabel}</td>
                                        <CommunityConfigurationCheckbox
                                            pf={x}
                                            isChecked={isSelectedDashboard(x)}
                                            isDisabled={isReadOnly}
                                            onSelected={dashboardSelectedHandler}
                                            onUnselected={dashboardUnselectedHandler}
                                        />
                                        <CommunityConfigurationCheckbox
                                            pf={x}
                                            isChecked={isSelectedAudience(x)}
                                            isDisabled={shouldDisableCheckbox(x)}
                                            onSelected={audienceSelectedHandler}
                                            onUnselected={audienceUnselectedHandler}
                                        />
                                    </tr>
                                ))}
                            {!isLoadingCatProfileFields && (
                                <tr>
                                    {showReportPreferred ? (
                                        <td
                                            className={`${HEADER_CELL_CLASSES} font-haas font-medium uppercase`}
                                            colSpan={3}
                                        >
                                            Additional Profile Fields
                                        </td>
                                    ) : (
                                        <>
                                            <td
                                                className={`${HEADER_CELL_CLASSES} font-haas font-medium uppercase`}
                                                colSpan={2}
                                            >
                                                Profile Fields
                                            </td>
                                            <td className={`${HEADER_CELL_CLASSES} text-center text-xs`}>
                                                (Selection of 2 required)
                                            </td>
                                        </>
                                    )}
                                </tr>
                            )}
                            {!isLoadingCatProfileFields &&
                                additional?.map((x) => (
                                    <tr key={x.profileFieldId} className="border-b border-primary-50  ">
                                        <td className="line-clamp-2 max-h-14 p-2 pl-4">{x.profileLabel}</td>
                                        <CommunityConfigurationCheckbox
                                            pf={x}
                                            isChecked={isSelectedDashboard(x)}
                                            isDisabled={isReadOnly}
                                            onSelected={dashboardSelectedHandler}
                                            onUnselected={dashboardUnselectedHandler}
                                        />
                                        <CommunityConfigurationCheckbox
                                            pf={x}
                                            isChecked={isSelectedAudience(x)}
                                            isDisabled={shouldDisableCheckbox(x)}
                                            onSelected={audienceSelectedHandler}
                                            onUnselected={audienceUnselectedHandler}
                                        />
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </section>
                {!isReadOnly && (
                    <section className="flex flex-row-reverse content-between items-center gap-2 border-t border-primary-50 pt-4">
                        <Button
                            buttonStyle="primary"
                            buttonSize="sm"
                            isDisabled={!canPublish || !hasChanged}
                            onClick={publishClickHandler}
                        >
                            Publish
                        </Button>
                        {audiences.length !== 2 && (
                            <ErrorLabel errors={'Two items must be selected to show on the Audience Page'} />
                        )}
                    </section>
                )}
            </ErrorDisplay>
        </div>
    );
}
