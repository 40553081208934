import { FinancialsWidgetCreateModel, FinancialsWidgetViewModel } from '../../../../common/clientApi/ClientApiTypes';
import { ValidationConfiguration } from '../../../../common/hooks/validation/useValidation';
import { MinLength, Required } from '../../../../common/hooks/validation/validationRules';

export const VALIDATION_CONSTS = {
    MIN_LENGTH: 3,
    TITLE_MAX_LENGTH: 36,
};

export const TextWidgetValidationConfiguration: ValidationConfiguration<FinancialsWidgetCreateModel | FinancialsWidgetViewModel> = {
    title: [
        MinLength(VALIDATION_CONSTS.MIN_LENGTH, `Minimum ${VALIDATION_CONSTS.MIN_LENGTH} characters`),
        Required('Title'),
    ],
};
