import { NavLink } from 'react-router-dom';
import { accountName } from '../Tenant';
import { classNames } from '../../common/utils';
import useDocumentTitle from '../../common/hooks/useDocumentTitle';

export default function SettingsNavigation() {
    useDocumentTitle('C Space Partner Portal - Settings');

    const tabs = [
        { title: 'Financials', url: '../financials' },
        { title: 'Content', url: '../marketingContent' },
        { title: 'Team Members', url: '../teamMembers' },
        { title: 'Community Composition', url: '../communityComposition' },
    ];

    return (
        <div className="flex w-full items-center rounded-sm bg-white py-4 font-bold uppercase">
            <div className="mr-6 border-r border-primary-50 px-6 py-2 text-sm">{accountName}</div>
            <div className="flex space-x-10 text-xs transition-colors">
                {tabs.map((x) => {
                    return (
                        <NavLink
                            key={x.url}
                            to={x.url}
                            relative="path"
                            end
                            className={({ isActive }) =>
                                classNames(
                                    isActive
                                        ? 'border-secondary-500 text-black'
                                        : 'border-transparent text-primary-500 hover:border-gray-300 hover:text-gray-700',
                                    'border-b-2 pb-2 pt-[10px]'
                                )
                            }
                        >
                            {x.title}
                        </NavLink>
                    );
                })}
            </div>
        </div>
    );
}
