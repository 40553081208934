import React, { useMemo } from 'react';
import {
    FinancialsWidgetCreateModel,
    FinancialsWidgetViewModel,
    ChartDisplayType,
} from '../../../../common/clientApi/ClientApiTypes';
import KpiChart, { KpiChartStyle } from '../../../../common/components/KpiChart';
import { formatDateToMMMDDYYYY } from '../../../../common/dateUtils';
import GraphChartWidgetLegend from './GraphChartWidgetLegend';
import { getGraphChartItems, isExceeding } from './GraphChartWidgetUtils';

type Props = {
    widgetData: FinancialsWidgetCreateModel | FinancialsWidgetViewModel;
};

export default function GraphChartWidgetPreview({ widgetData }: Props) {
    const chartData = useMemo(() => getGraphChartItems(widgetData), [widgetData]);

    return (
        <>
            <h3>{widgetData.title}</h3>
            <div className="mb-4 mt-2.5 w-full border-t border-gray-100" />
            {(widgetData as FinancialsWidgetViewModel).updatedDate && (
                <div className="text-xs leading-[18px]">
                    Updated {formatDateToMMMDDYYYY((widgetData as FinancialsWidgetViewModel).updatedDate)}
                </div>
            )}
            {widgetData.type === 'PieChart' ? (
                <div className="flex flex-col min-[1450px]:flex-row min-[1450px]:items-center min-[1450px]:justify-between">
                    <GraphChartWidgetLegend currency={widgetData.currency as string} data={chartData} />
                    <div
                        className={`self-center transition-opacity ${
                            isExceeding(widgetData) ? 'opacity-0' : 'opacity-100'
                        }`}
                    >
                        <KpiChart
                            canExport={false}
                            showLegend={false}
                            outsideLabels={true}
                            chartType={KpiChartStyle.pie as ChartDisplayType}
                            width={450}
                            height={400}
                            data={chartData}
                        />
                    </div>
                </div>
            ) : (
                <div className="flex items-center justify-start">
                    <KpiChart
                        canExport={false}
                        showTooltip={false}
                        showLegend={false}
                        outsideLabels={true}
                        chartType={KpiChartStyle.bar as ChartDisplayType}
                        width={600}
                        height={300}
                        data={chartData.map((chartElem) => ({
                            label: chartElem.label,
                            value: chartElem.value,
                        }))}
                        customData={{ labelPrefix: widgetData?.currency ?? '' }}
                        showValueInTooltip={true}
                    />
                </div>
            )}
        </>
    );
}
