import { CHART_ITEMS_ORDINAL } from '../../../../common/consts/enums';
import { compareByOrdinal } from '../../../../common/utils';
import {
    FinancialsWidgetChartItemCreateModel,
    FinancialsWidgetCreateModel,
    FinancialsWidgetViewModel,
} from '../../../../common/clientApi/ClientApiTypes';
import { areChartItemsEqual } from '../../../../common/utils';
import { CHART_COLORS } from '../../../../common/components/KpiChart';

type ChartItemType = { label: string; value: number; ordinal: number };
export type BudgetChartItemType = ChartItemType & { color: string };

const TOTAL_LABEL_COLOR = '#001028';

export const mapChartItems = (widgetChartItems: FinancialsWidgetChartItemCreateModel[]): ChartItemType[] => {
    return [
        ...widgetChartItems.map((field) => ({
            label: field.name,
            value: Number.isNaN(field.value) ? 0 : field.value,
            ordinal: field.ordinal,
        })),
    ];
};

const addBudgetChartColors = (chartItems: ChartItemType[]): BudgetChartItemType[] => {
    const [remainingItemColor, ...otherChartColors] = CHART_COLORS;
    let otherColorIndex = 0;

    return chartItems.map((item) => {
        if (item.ordinal === CHART_ITEMS_ORDINAL.REMAINING) {
            return { ...item, color: remainingItemColor };
        }
        if (item.ordinal === CHART_ITEMS_ORDINAL.TOTAL) {
            return { ...item, color: TOTAL_LABEL_COLOR };
        }

        const color = otherChartColors[otherColorIndex++ % otherChartColors.length];
        return { ...item, color };
    });
};

export const getBudgetChartItems = (widgetData: FinancialsWidgetCreateModel | FinancialsWidgetViewModel) => {
    const chartItems = mapChartItems(widgetData.chartItems ?? []);

    return addBudgetChartColors(chartItems).sort(compareByOrdinal);
};

export function getCurrencySymbol(currency: string) {
    switch (currency) {
        case 'EUR':
            return '€';
        case 'USD':
            return '$';
        default:
            return '£';
    }
}

export const calculateRemainingBudget = (totalBudget: number, chartItems: FinancialsWidgetChartItemCreateModel[]) => {
    const budget = Number.isNaN(totalBudget) ? 0 : totalBudget;
    const totalSpent = chartItems
        .filter((item) => ![CHART_ITEMS_ORDINAL.REMAINING, CHART_ITEMS_ORDINAL.TOTAL].includes(item.ordinal))
        .reduce((acc, curr) => acc + (curr.value > 0 ? curr.value : 0), 0);
    return budget - totalSpent;
};

export const areBudgetWidgetsEqual = (initData?: FinancialsWidgetViewModel, newData?: FinancialsWidgetViewModel) => {
    if (!newData || !initData) {
        return false;
    }

    return (
        initData.title === newData.title &&
        initData.startDate?.substring(0, 10) === newData.startDate?.substring(0, 10) &&
        initData.stopDate?.substring(0, 10) === newData.stopDate?.substring(0, 10) &&
        initData.currency === newData.currency &&
        areChartItemsEqual(initData.chartItems, newData.chartItems)
    );
};
