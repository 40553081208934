import { FinancialsWidgetCreateModel, FinancialsWidgetViewModel } from '../../../../common/clientApi/ClientApiTypes';
import { ValidationConfiguration } from '../../../../common/hooks/validation/useValidation';
import { MinLength, Required } from '../../../../common/hooks/validation/validationRules';
import { CHART_ITEMS_ORDINAL, FIELD_TOKENS } from '../../../../common/consts/enums';

export const VALIDATION_CONSTS = {
    MIN_LENGTH: 3,
    TITLE_MAX_LENGTH: 36,
    FIELD_NAME_MAX_LENGTH: 20,
    FIELD_VALUE_MAX_LENGTH: 8,
};

export const ProjectBudgetValidationConfiguration: ValidationConfiguration<FinancialsWidgetCreateModel | FinancialsWidgetViewModel> = {
    title: [
        MinLength(VALIDATION_CONSTS.MIN_LENGTH, `Minimum ${VALIDATION_CONSTS.MIN_LENGTH} characters`),
        Required('Title'),
    ],
    startDate: [Required('Start date')],
    stopDate: [Required('Stop date')],
};

export const getChartItemsValidationConfiguration = (model: Record<string, string | number>) => {
    const resultingConfig = {} as ValidationConfiguration<Record<string, string | number>>;
    for (const [k] of Object.entries(model)) {
        if (k.endsWith(FIELD_TOKENS.NAME)) {
            resultingConfig[k] = [
                MinLength(VALIDATION_CONSTS.MIN_LENGTH, `Minimum ${VALIDATION_CONSTS.MIN_LENGTH} characters`),
                Required('Name'),
            ];
        } else if (k.endsWith(FIELD_TOKENS.VALUE)) {
            const tokens = k.split('_');
            if (+tokens[1] === CHART_ITEMS_ORDINAL.TOTAL) {
                resultingConfig[k] = [
                    {
                        message: 'Required field',
                        isInvalid: (v) => Number.isNaN(v) || (v as number) <= 0,
                    },
                ];
            } else {
                resultingConfig[k] = [
                    {
                        message: 'Required field',
                        isInvalid: (v) => Number.isNaN(v),
                    },
                ];
            }
        }
    }
    return resultingConfig;
};
