/*-----------------------------------------------
    Generated by SwaggerToTS
    Do not customize this file, update the generator
    Copyright C Space 2024
-----------------------------------------------*/
/* eslint-disable @typescript-eslint/no-unused-vars */
export type AccountDataProviderPropViewModel = {
    name: string;
    propType: PropType;
    valueStr?: string | null;
    valueInt?: number | null; // int64
    valueDec?: number | null; // double
    valueId?: string | null; // uuid
};

export type AccountDataProviderViewModel = {
    accountDataProviderId: string; // uuid
    dataProviderId: string; // uuid
    name: string;
    memberCount?: number | null; // int32
    providerUrl?: string | null;
    properties?: AccountDataProviderPropViewModel[] | null;
};

export type AccountDataProviderViewModelPagedList = {
    list?: AccountDataProviderViewModel[] | null;
    count: number; // int32
    pageSize: number; // int32
    page: number; // int32
    orderBy?: string | null;
};

export type AccountDetailModel = {
    clientId: string; // uuid
    accountId: string; // uuid
    shortName: string;
    displayName: string;
    projectPrefix: string;
    clientName: string;
    clientShortName: string;
    createdDate: string; // date-time
    teamEmail: string;
};

export type AccountStatus = 'Active' | 'Pending' | 'Expired' | 'Deleted';
export const AccountStatusList: AccountStatus[] = ['Active', 'Pending', 'Expired', 'Deleted'];

export type AccountTeamEmailUpdateModel = {
    teamEmail: string;
};

export type AccountType = 'PartnerPortal';
export const AccountTypeList: AccountType[] = ['PartnerPortal'];

export type AccountUserCreateModel = {
    userId: string; // uuid
    roleName: string;
};

export type AccountUserListOrder =
    | 'OrdinalAsc'
    | 'OrdinalDesc'
    | 'PreferredNameAsc'
    | 'PreferredNameDesc'
    | 'RoleNameAsc'
    | 'RoleNameDesc';
export const AccountUserListOrderList: AccountUserListOrder[] = [
    'OrdinalAsc',
    'OrdinalDesc',
    'PreferredNameAsc',
    'PreferredNameDesc',
    'RoleNameAsc',
    'RoleNameDesc',
];

export type AccountUserReorderModel = {
    accountUserIds?: string[] | null; // uuid
};

export type AccountUserUpdateModel = {
    roleName: string;
};

export type AccountUserViewModel = {
    accountUserId: string; // uuid
    userId: string; // uuid
    preferredName: string;
    email: string;
    roleName: string;
};

export type AccountUserViewModelPagedList = {
    list?: AccountUserViewModel[] | null;
    count: number; // int32
    pageSize: number; // int32
    page: number; // int32
    orderBy?: string | null;
};

export type AccountViewModel = {
    clientId: string; // uuid
    accountId: string; // uuid
    shortName: string;
    displayName: string;
    projectPrefix: string;
    clientName: string;
    clientShortName: string;
    createdDate: string; // date-time
};

export type AccountViewModelPagedList = {
    list?: AccountViewModel[] | null;
    count: number; // int32
    pageSize: number; // int32
    page: number; // int32
    orderBy?: string | null;
};

export type ActivityCreateModel = {
    name?: string | null;
    description?: string | null;
    activityStatus: ActivityStatus;
    activityType: ActivityType;
    startDate: string; // date-time
    stopDate: string; // date-time
    activityDisplayMode: ActivityDisplayMode;
    activityUrl?: string | null;
    accountDataProviderId?: string | null; // uuid
    properties?: ActivityPropInputModel[] | null;
    tags?: string[] | null;
};

export type ActivityDetailModel = {
    activityId: string; // uuid
    name?: string | null;
    description?: string | null;
    startDate: string; // date-time
    stopDate: string; // date-time
    updatedBy: string; // uuid
    updatedDate: string; // date-time
    activityStatus: ActivityStatus;
    activityType: ActivityType;
    accountDataProviderId?: string | null; // uuid
    accountDataProviderName?: string | null;
    properties?: ActivityPropInputModel[] | null;
    tags?: string[] | null;
};

export type ActivityDisplayMode = 'Visible' | 'ClientHidden';
export const ActivityDisplayModeList: ActivityDisplayMode[] = ['Visible', 'ClientHidden'];

export type ActivityPageOrder =
    | 'NameAsc'
    | 'NameDesc'
    | 'StartDateAsc'
    | 'StartDateDesc'
    | 'StopDateAsc'
    | 'StopDateDesc'
    | 'CreateDateAsc'
    | 'CreateDateDesc'
    | 'OrdinalAsc'
    | 'OrdinalDesc'
    | 'ProjectOrdinalAsc'
    | 'ProjectOrdinalDesc';
export const ActivityPageOrderList: ActivityPageOrder[] = [
    'NameAsc',
    'NameDesc',
    'StartDateAsc',
    'StartDateDesc',
    'StopDateAsc',
    'StopDateDesc',
    'CreateDateAsc',
    'CreateDateDesc',
    'OrdinalAsc',
    'OrdinalDesc',
    'ProjectOrdinalAsc',
    'ProjectOrdinalDesc',
];

export type ActivityPropInputModel = {
    name?: string | null;
    propType: PropType;
    valueStr?: string | null;
    valueInt?: number | null; // int64
    valueDec?: number | null; // double
    valueId?: string | null; // uuid
};

export type ActivityStatus = 'Active' | 'Deleted' | 'Pending' | 'Archived';
export const ActivityStatusList: ActivityStatus[] = ['Active', 'Deleted', 'Pending', 'Archived'];

export type ActivityType =
    | 'Discussion'
    | 'Survey'
    | 'Journey'
    | 'QuickPoll'
    | 'Announcement'
    | 'Qualtrics'
    | 'Idi'
    | 'VideoFocusGroup'
    | 'Other'
    | 'ConfirmIt';
export const ActivityTypeList: ActivityType[] = [
    'Discussion',
    'Survey',
    'Journey',
    'QuickPoll',
    'Announcement',
    'Qualtrics',
    'Idi',
    'VideoFocusGroup',
    'Other',
    'ConfirmIt',
];

export type ActivityUpdateModel = {
    name?: string | null;
    description?: string | null;
    activityStatus: ActivityStatus;
    startDate: string; // date-time
    stopDate: string; // date-time
    tags?: string[] | null;
    properties?: ActivityPropInputModel[] | null;
};

export type ActivityViewModel = {
    activityId: string; // uuid
    objectId?: string | null; // uuid
    name?: string | null;
    activityStatus: ActivityStatus;
    activityType: ActivityType;
    activityDisplayMode: ActivityDisplayMode;
    activityUrl?: string | null;
    startDate: string; // date-time
    stopDate: string; // date-time
    accountDataProviderName?: string | null;
};

export type ActivityViewModelPagedList = {
    list?: ActivityViewModel[] | null;
    count: number; // int32
    pageSize: number; // int32
    page: number; // int32
    orderBy?: string | null;
};

export type ApiResultError = {
    hasError: boolean;
    title?: string | null;
    errors?: { [key: string]: ApiResultFieldError };
};

export type ApiResultFieldError = {
    fieldName?: string | null;
    fieldValue?: string | null;
    errors?: string[] | null;
};

export type AudienceChartInputModel = {
    accountDataProviderId?: string | null; // uuid
    chartId: string; // uuid
    audienceChartType: AudienceChartType;
    ordinal: number; // int32
};

export type AudienceChartListOrder =
    | 'NameAsc'
    | 'NameDesc'
    | 'OrdinalAsc'
    | 'OrdinalDesc'
    | 'CreatedDateAsc'
    | 'CreatedDateDesc'
    | 'UpdatedDateAsc'
    | 'UpdatedDateDesc';
export const AudienceChartListOrderList: AudienceChartListOrder[] = [
    'NameAsc',
    'NameDesc',
    'OrdinalAsc',
    'OrdinalDesc',
    'CreatedDateAsc',
    'CreatedDateDesc',
    'UpdatedDateAsc',
    'UpdatedDateDesc',
];

export type AudienceChartStatus = 'Active' | 'Inactive' | 'Deleted';
export const AudienceChartStatusList: AudienceChartStatus[] = ['Active', 'Inactive', 'Deleted'];

export type AudienceChartType = 'Audience' | 'Dashboard';
export const AudienceChartTypeList: AudienceChartType[] = ['Audience', 'Dashboard'];

export type AudienceChartViewModel = {
    audienceChartId: string; // uuid
    chartId: string; // uuid
    accountDataProviderId?: string | null; // uuid
    audienceChartStatus: AudienceChartStatus;
    audienceChartType: AudienceChartType;
    ordinal: number; // int32
    chart: ChartDataDetailModel;
};

export type AudienceChartViewModelPagedList = {
    list?: AudienceChartViewModel[] | null;
    count: number; // int32
    pageSize: number; // int32
    page: number; // int32
    orderBy?: string | null;
};

export type AudienceMarketingContentTileViewModel = {
    tileId: string; // uuid
    title: string;
    description: string;
    linkTitle: string;
    linkUrl: string;
    tileMediaId: string; // uuid
};

export type AudienceRequestInputModel = {
    details: string;
    knowledge: string;
    engagement: string;
};

export type AudienceRequestViewModel = {
    details: string;
    knowledge: string;
    engagement: string;
};

export type CatActivity = {
    activityId: string; // uuid
    title: string;
    description: string;
    activityTypeName: string;
    createdDate: string; // date-time
    startDate: string; // date-time
    endDate: string; // date-time
    isPublished: boolean;
    isDeleted: boolean;
    avgRating?: number | null; // double
    contributionCount: number; // int32
    respondentCount: number; // int32
    lastContributionDate?: string | null; // date-time
};

export type CatActivityOrder =
    | 'TitleAsc'
    | 'TitleDesc'
    | 'CreatedOnAsc'
    | 'CreatedOnDesc'
    | 'StartDateAsc'
    | 'StartDateDesc'
    | 'EndDateAsc'
    | 'EndDateDesc';
export const CatActivityOrderList: CatActivityOrder[] = [
    'TitleAsc',
    'TitleDesc',
    'CreatedOnAsc',
    'CreatedOnDesc',
    'StartDateAsc',
    'StartDateDesc',
    'EndDateAsc',
    'EndDateDesc',
];

export type CatActivityPagedList = {
    list?: CatActivity[] | null;
    count: number; // int32
    pageSize: number; // int32
    page: number; // int32
    orderBy?: string | null;
};

export type CatProfileField = {
    profileFieldId: string; // uuid
    profileFieldGlobalId: string; // uuid
    profileGroupId: string; // uuid
    communityId: string; // uuid
    name: string;
    profileLabel: string;
    profileFieldDataType: string;
    profileFieldControlToShow: string;
    answerExpectedType?: string | null;
    length: number; // int32
    displayOrder: number; // int32
    isPrivate: boolean;
    isRemoved: boolean;
    isReportPreferred: boolean;
    optionValues?: CatProfileFieldOptionValueViewModel[] | null;
};

export type CatProfileFieldMetricDataViewModel = {
    profileFieldOptionId: string; // uuid
    profileFieldOptionLabel: string;
    value: number; // int32
};

export type CatProfileFieldMetrics = {
    profileFieldId: string; // uuid
    enterpriseId: string; // uuid
    communityId: string; // uuid
    profileFieldLabel?: string | null;
    total: number; // int32
    data?: CatProfileFieldMetricDataViewModel[] | null;
};

export type CatProfileFieldOptionValueViewModel = {
    profileFieldOptionValueId: string; // uuid
    profileFieldId: string; // uuid
    optionText: string;
    optionValue: number; // int32
    isRemoved: boolean;
    displayOrder: number; // int32
};

export type CatProfileFieldOrder =
    | 'NameAsc'
    | 'NameDesc'
    | 'ProfileLabelAsc'
    | 'ProfileLabelDesc'
    | 'IsReportPreferredAsc'
    | 'IsReportPreferredDesc'
    | 'IsMemberFieldAsc'
    | 'IsMemberFieldDesc'
    | 'IsRequiredAsc'
    | 'IsRequiredDesc'
    | 'ProfileFieldDataTypeAsc'
    | 'ProfileFieldDataTypeDesc'
    | 'IsExportableAsc'
    | 'IsExportableDesc';
export const CatProfileFieldOrderList: CatProfileFieldOrder[] = [
    'NameAsc',
    'NameDesc',
    'ProfileLabelAsc',
    'ProfileLabelDesc',
    'IsReportPreferredAsc',
    'IsReportPreferredDesc',
    'IsMemberFieldAsc',
    'IsMemberFieldDesc',
    'IsRequiredAsc',
    'IsRequiredDesc',
    'ProfileFieldDataTypeAsc',
    'ProfileFieldDataTypeDesc',
    'IsExportableAsc',
    'IsExportableDesc',
];

export type CatProfileFieldPagedList = {
    list?: CatProfileField[] | null;
    count: number; // int32
    pageSize: number; // int32
    page: number; // int32
    orderBy?: string | null;
};

export type CatalystMemberMetrics = {
    memberCount: number; // int32
};

export type ChartDataDetailModel = {
    chartId: string; // uuid
    name: string;
    chartDisplayType: ChartDisplayType;
    status: ChartStatus;
    total?: number | null; // double
    properties?: ChartPropViewModel[] | null;
    data?: ChartDataViewModel[] | null;
};

export type ChartDataInputModel = {
    dataId: string; // uuid
    name: string;
    value: number; // double
};

export type ChartDataType = 'ProfileField' | 'SurveyQuestion';
export const ChartDataTypeList: ChartDataType[] = ['ProfileField', 'SurveyQuestion'];

export type ChartDataViewModel = {
    dataId: string; // uuid
    name: string;
    value: number; // double
    chartDataId: string; // uuid
};

export type ChartDisplayType = 'Pie' | 'Bar' | 'Line';
export const ChartDisplayTypeList: ChartDisplayType[] = ['Pie', 'Bar', 'Line'];

export type ChartInputModel = {
    name: string;
    chartDisplayType: ChartDisplayType;
    chartDataType: ChartDataType;
    total?: number | null; // double
    properties?: ChartPropInputModel[] | null;
    data?: ChartDataInputModel[] | null;
};

export type ChartKpiData = {
    label?: string | null;
    value: number; // double
    color?: string | null;
};

export type ChartPropInputModel = {
    name: string;
    valueType: PropType;
    valueId?: string | null; // uuid
    valueStr?: string | null;
    valueInt?: number | null; // int64
    valueDec?: number | null; // double
};

export type ChartPropViewModel = {
    name: string;
    valueType: PropType;
    valueId?: string | null; // uuid
    valueStr?: string | null;
    valueInt?: number | null; // int64
    valueDec?: number | null; // double
};

export type ChartStatus = 'Active' | 'Inactive' | 'Loading' | 'Deleted';
export const ChartStatusList: ChartStatus[] = ['Active', 'Inactive', 'Loading', 'Deleted'];

export type ChartUpdateModel = {
    name: string;
    chartDisplayType: ChartDisplayType;
    chartDataType: ChartDataType;
    total?: number | null; // double
    properties?: ChartPropInputModel[] | null;
    data?: ChartDataInputModel[] | null;
    status: ChartStatus;
};

export type ClientDetailModel = {
    clientId: string; // uuid
    name?: string | null;
    description?: string | null;
    clientStatus: ClientStatus;
    clientType: ClientType;
    shortName?: string | null;
    globalCultureId: string; // uuid
    identityUserFlowId: string; // uuid
};

export type ClientPageOrder =
    | 'NameAsc'
    | 'NameDesc'
    | 'ShortNameAsc'
    | 'ShortNameDesc'
    | 'InternalNameAsc'
    | 'InternalNameDesc'
    | 'ClientNameAsc'
    | 'ClientNameDesc'
    | 'CreatedDateAsc'
    | 'CreatedDateDesc'
    | 'UpdatedDateAsc'
    | 'UpdatedDateDesc';
export const ClientPageOrderList: ClientPageOrder[] = [
    'NameAsc',
    'NameDesc',
    'ShortNameAsc',
    'ShortNameDesc',
    'InternalNameAsc',
    'InternalNameDesc',
    'ClientNameAsc',
    'ClientNameDesc',
    'CreatedDateAsc',
    'CreatedDateDesc',
    'UpdatedDateAsc',
    'UpdatedDateDesc',
];

export type ClientProjectBriefOrder = 'NameAsc' | 'NameDesc';
export const ClientProjectBriefOrderList: ClientProjectBriefOrder[] = ['NameAsc', 'NameDesc'];

export type ClientProjectCreateModel = {
    name: string;
    description?: string | null;
    startDate?: string | null; // date-time
    stopDate?: string | null; // date-time
    phaseId?: string | null; // uuid
    subPhaseId?: string | null; // uuid
    clientProjectType: ClientProjectType;
    clientProjectStatus: ClientProjectStatus;
    researchObjective: string;
    businessObjective?: string | null;
    backgroundInformation?: string | null;
    targetAudience?: string | null;
    sampleSize?: number | null; // int32
    dataCuts?: string | null;
    targetDeliveryDate?: string | null; // date-time
    targetDeliveryReason?: string | null;
    drivingTiming?: string | null;
    deliveryPreferences?: string | null;
    additionalContext?: string | null;
    clientProjectScope: ClientProjectScope;
    tags?: string[] | null;
    users?: ClientProjectUserInputModel[] | null;
    clientMediaIds?: string[] | null; // uuid
    primaryClientStakeholder?: string | null;
    projectBriefId?: string | null;
};

export type ClientProjectDetailModel = {
    clientProjectId: string; // uuid
    name: string;
    displayId: string;
    description?: string | null;
    stopDate?: string | null; // date-time
    targetDeliveryDate?: string | null; // date-time
    clientProjectType: ClientProjectType;
    clientProjectStatus: ClientProjectStatus;
    projectBriefStatus: ProjectBriefStatus;
    createdDate: string; // date-time
    createdBy: UserDetailModel;
    phaseId: string; // uuid
    phaseName?: string | null;
    phaseColor?: string | null;
    subPhaseId?: string | null; // uuid
    subPhaseName?: string | null;
    subPhaseColor?: string | null;
    primaryClientStakeholder?: string | null;
    researchObjective: string;
    updatedDate: string; // date-time
    businessObjective?: string | null;
    backgroundInformation?: string | null;
    targetAudience?: string | null;
    sampleSize?: number | null; // int32
    dataCuts?: string | null;
    targetDeliveryReason?: string | null;
    drivingTiming?: string | null;
    deliveryPreferences?: string | null;
    projectBriefId?: string | null;
    additionalContext?: string | null;
    clientProjectScope: ClientProjectScope;
    clientProjectMedia?: ClientProjectMediaDetailModel[] | null;
    tags?: string[] | null;
    users?: ClientProjectUserDetailModel[] | null;
    media?: MediaDetailModel[] | null;
};

export type ClientProjectMediaDetailModel = {
    clientProjectMediaId: string; // uuid
    mediaId: string; // uuid
    clientProjectId: string; // uuid
    ordinal: number; // int32
    clientProjectMediaType: ClientProjectMediaType;
};

export type ClientProjectMediaType = 'ProjectBrief';
export const ClientProjectMediaTypeList: ClientProjectMediaType[] = ['ProjectBrief'];

export type ClientProjectOrder =
    | 'NameAsc'
    | 'NameDesc'
    | 'PhaseNameAsc'
    | 'PhaseNameDesc'
    | 'SubPhaseNameAsc'
    | 'SubPhaseNameDesc'
    | 'CreateDateAsc'
    | 'CreateDateDesc'
    | 'StopDateAsc'
    | 'StopDateDesc'
    | 'DisplayIdAsc'
    | 'DisplayIdDesc';
export const ClientProjectOrderList: ClientProjectOrder[] = [
    'NameAsc',
    'NameDesc',
    'PhaseNameAsc',
    'PhaseNameDesc',
    'SubPhaseNameAsc',
    'SubPhaseNameDesc',
    'CreateDateAsc',
    'CreateDateDesc',
    'StopDateAsc',
    'StopDateDesc',
    'DisplayIdAsc',
    'DisplayIdDesc',
];

export type ClientProjectPhaseCreateModel = {
    name: string;
    ordinal: number; // int32
    isDefault: boolean;
    subPhases?: ClientProjectSubPhaseCreateModel[] | null;
};

export type ClientProjectPhaseDetailModel = {
    clientProjectPhaseId: string; // uuid
    name: string;
    ordinal: number; // int32
    isDefault: boolean;
    subPhases?: ClientProjectSubPhaseDetailModel[] | null;
};

export type ClientProjectPhaseOrder = 'NameAsc' | 'NameDesc' | 'OrdinalAsc' | 'OrdinalDesc';
export const ClientProjectPhaseOrderList: ClientProjectPhaseOrder[] = [
    'NameAsc',
    'NameDesc',
    'OrdinalAsc',
    'OrdinalDesc',
];

export type ClientProjectPhaseStatus = 'Active' | 'Deleted';
export const ClientProjectPhaseStatusList: ClientProjectPhaseStatus[] = ['Active', 'Deleted'];

export type ClientProjectPhaseUpdateModel = {
    name: string;
    ordinal: number; // int32
    isDefault: boolean;
};

export type ClientProjectPhaseViewModel = {
    clientProjectPhaseId: string; // uuid
    name: string;
    ordinal: number; // int32
    isDefault: boolean;
};

export type ClientProjectPhaseViewModelPagedList = {
    list?: ClientProjectPhaseViewModel[] | null;
    count: number; // int32
    pageSize: number; // int32
    page: number; // int32
    orderBy?: string | null;
};

export type ClientProjectScope = 'InScope' | 'AdHoc';
export const ClientProjectScopeList: ClientProjectScope[] = ['InScope', 'AdHoc'];

export type ClientProjectStatus = 'Pending' | 'Active' | 'Inactive' | 'Deleted';
export const ClientProjectStatusList: ClientProjectStatus[] = ['Pending', 'Active', 'Inactive', 'Deleted'];

export type ClientProjectSubPhaseCreateModel = {
    name: string;
    ordinal: number; // int32
    isDefault: boolean;
};

export type ClientProjectSubPhaseDetailModel = {
    clientProjectSubPhaseId: string; // uuid
    name: string;
    ordinal: number; // int32
    isDefault: boolean;
};

export type ClientProjectSubPhaseUpdateModel = {
    name: string;
    ordinal: number; // int32
    isDefault: boolean;
};

export type ClientProjectType = 'Standard';
export const ClientProjectTypeList: ClientProjectType[] = ['Standard'];

export type ClientProjectUpdateMinModel = {
    name: string;
    researchObjective: string;
    targetDeliveryDate?: string | null; // date-time
    phaseId?: string | null; // uuid
    subPhaseId?: string | null; // uuid
    primaryClientStakeholder?: string | null;
    displayId?: string | null;
};

export type ClientProjectUpdateModel = {
    name: string;
    description?: string | null;
    startDate?: string | null; // date-time
    stopDate?: string | null; // date-time
    phaseId?: string | null; // uuid
    subPhaseId?: string | null; // uuid
    researchObjective: string;
    businessObjective?: string | null;
    backgroundInformation?: string | null;
    targetAudience?: string | null;
    sampleSize?: number | null; // int32
    dataCuts?: string | null;
    targetDeliveryDate?: string | null; // date-time
    targetDeliveryReason?: string | null;
    drivingTiming?: string | null;
    deliveryPreferences?: string | null;
    additionalContext?: string | null;
    clientProjectScope: ClientProjectScope;
    tags?: string[] | null;
    clientProjectType: ClientProjectType;
    clientProjectStatus: ClientProjectStatus;
    projectBriefStatus: ProjectBriefStatus;
    users?: ClientProjectUserInputModel[] | null;
    clientMediaIds?: string[] | null; // uuid
    primaryClientStakeholder?: string | null;
    projectBriefId?: string | null;
};

export type ClientProjectUserDetailModel = {
    userId: string; // uuid
    displayName?: string | null;
    projectRoleName?: string | null;
};

export type ClientProjectUserInputModel = {
    userId: string; // uuid
    projectRoleName: string;
};

export type ClientProjectViewModel = {
    clientProjectId: string; // uuid
    name: string;
    displayId: string;
    description?: string | null;
    stopDate?: string | null; // date-time
    targetDeliveryDate?: string | null; // date-time
    clientProjectType: ClientProjectType;
    clientProjectStatus: ClientProjectStatus;
    projectBriefStatus: ProjectBriefStatus;
    createdDate: string; // date-time
    createdBy: UserDetailModel;
    phaseId: string; // uuid
    phaseName?: string | null;
    phaseColor?: string | null;
    subPhaseId?: string | null; // uuid
    subPhaseName?: string | null;
    subPhaseColor?: string | null;
    primaryClientStakeholder?: string | null;
    researchObjective: string;
};

export type ClientProjectViewModelPagedList = {
    list?: ClientProjectViewModel[] | null;
    count: number; // int32
    pageSize: number; // int32
    page: number; // int32
    orderBy?: string | null;
};

export type ClientStatus = 'Active' | 'Deleted' | 'Pending';
export const ClientStatusList: ClientStatus[] = ['Active', 'Deleted', 'Pending'];

export type ClientType = 'Standard' | 'Shared';
export const ClientTypeList: ClientType[] = ['Standard', 'Shared'];

export type ClientViewModel = {
    clientId: string; // uuid
    name?: string | null;
    clientStatus: ClientStatus;
    clientType: ClientType;
    shortName?: string | null;
    globalCultureId: string; // uuid
    identityUserFlowId: string; // uuid
};

export type ClientViewModelPagedList = {
    list?: ClientViewModel[] | null;
    count: number; // int32
    pageSize: number; // int32
    page: number; // int32
    orderBy?: string | null;
};

export type CommunityType = 'All' | 'InCommunity' | 'OutOfCommunity';
export const CommunityTypeList: CommunityType[] = ['All', 'InCommunity', 'OutOfCommunity'];

export type CurrentUserViewModel = {
    userId: string; // uuid
    displayName?: string | null;
    givenName?: string | null;
    email?: string | null;
    userLoginType: GlobalUserLoginType;
    userType: GlobalUserType;
    displayStyle: RoleDisplayStyle;
    permissions?: string[] | null;
    hasMultipleAccounts: boolean;
};

export type DataProviderDetailModel = {
    dataProviderId: string; // uuid
    name?: string | null;
    dataProviderStatus: DataProviderStatus;
    properties?: DataProviderProperty[] | null;
};

export type DataProviderOrder = 'NameAsc' | 'NameDesc';
export const DataProviderOrderList: DataProviderOrder[] = ['NameAsc', 'NameDesc'];

export type DataProviderProperty = {
    dataProviderPropId: string; // uuid
    name?: string | null;
    propType: PropType;
    valueStr?: string | null;
    valueInt?: number | null; // int32
    valueDecimal?: number | null; // double
    valueId?: string | null; // uuid
};

export type DataProviderStatus = 'Active' | 'Deleted';
export const DataProviderStatusList: DataProviderStatus[] = ['Active', 'Deleted'];

export type DataProviderViewModel = {
    dataProviderId: string; // uuid
    name?: string | null;
    dataProviderStatus: DataProviderStatus;
};

export type DataProviderViewModelPagedList = {
    list?: DataProviderViewModel[] | null;
    count: number; // int32
    pageSize: number; // int32
    page: number; // int32
    orderBy?: string | null;
};

export type ExportPowerPointCreateModel = {
    slideList?: ExportPowerPointSlideModel[] | null;
};

export type ExportPowerPointSlideModel = {
    slideType: PowerPointSlideType;
    title: string;
    description?: string | null;
    date?: string | null;
    valuePrefix?: string | null;
    chartType: ChartDisplayType;
    data?: ChartKpiData[] | null;
};

export type FileActionType = 'FileAdded' | 'FileUpdated';
export const FileActionTypeList: FileActionType[] = ['FileAdded', 'FileUpdated'];

export type FileNotificationModel = {
    fileName: string;
    fileUrl: string;
    actionType: FileActionType;
};

export type FinancialsWidgetChartItemCreateModel = {
    financialsWidgetChartItemId?: string | null; // uuid
    name: string;
    value: number; // int32
    ordinal: number; // int32
};

export type FinancialsWidgetChartItemViewModel = {
    financialsWidgetChartItemId: string; // uuid
    name: string;
    value: number; // int32
    ordinal: number; // int32
};

export type FinancialsWidgetCreateModel = {
    status: FinancialsWidgetStatus;
    type: FinancialsWidgetType;
    title: string;
    startDate?: string | null; // date-time
    stopDate?: string | null; // date-time
    currency: FinancialsWidgetCurrency;
    ordinal: number; // int32
    chartItems?: FinancialsWidgetChartItemCreateModel[] | null;
    textItemValue?: string | null;
};

export type FinancialsWidgetCurrency = 'USD' | 'EUR' | 'GBP' | 'Credits' | 'Percentages';
export const FinancialsWidgetCurrencyList: FinancialsWidgetCurrency[] = ['USD', 'EUR', 'GBP', 'Credits', 'Percentages'];

export type FinancialsWidgetStatus = 'Active' | 'Inactive' | 'Deleted';
export const FinancialsWidgetStatusList: FinancialsWidgetStatus[] = ['Active', 'Inactive', 'Deleted'];

export type FinancialsWidgetType = 'Text' | 'ProjectBudgetChart' | 'PieChart' | 'BarChart';
export const FinancialsWidgetTypeList: FinancialsWidgetType[] = ['Text', 'ProjectBudgetChart', 'PieChart', 'BarChart'];

export type FinancialsWidgetViewModel = {
    financialsWidgetId: string; // uuid
    status: FinancialsWidgetStatus;
    type: FinancialsWidgetType;
    title: string;
    startDate?: string | null; // date-time
    stopDate?: string | null; // date-time
    currency: FinancialsWidgetCurrency;
    ordinal: number; // int32
    chartItems?: FinancialsWidgetChartItemViewModel[] | null;
    textItemValue?: string | null;
    updatedDate: string; // date-time
};

export type FolderMediaInputModel = {
    mediaId: string; // uuid
};

export type GlobalAccountOrder =
    | 'DisplayNameAsc'
    | 'DisplayNameDesc'
    | 'ShortNameAsc'
    | 'ShortNameDesc'
    | 'CreatedDateAsc'
    | 'CreatedDateDesc'
    | 'ProjectPrefixAsc'
    | 'ProjectPrefixDesc'
    | 'ClientNameAsc'
    | 'ClientNameDesc';
export const GlobalAccountOrderList: GlobalAccountOrder[] = [
    'DisplayNameAsc',
    'DisplayNameDesc',
    'ShortNameAsc',
    'ShortNameDesc',
    'CreatedDateAsc',
    'CreatedDateDesc',
    'ProjectPrefixAsc',
    'ProjectPrefixDesc',
    'ClientNameAsc',
    'ClientNameDesc',
];

export type GlobalUserLoginType = 'System' | 'AzureB2C' | 'NoLogin' | 'Anonymous';
export const GlobalUserLoginTypeList: GlobalUserLoginType[] = ['System', 'AzureB2C', 'NoLogin', 'Anonymous'];

export type GlobalUserOrderByFilter =
    | 'DisplayNameAsc'
    | 'DisplayNameDesc'
    | 'GivenNameAsc'
    | 'GivenNameDesc'
    | 'EmailAsc'
    | 'EmailDesc';
export const GlobalUserOrderByFilterList: GlobalUserOrderByFilter[] = [
    'DisplayNameAsc',
    'DisplayNameDesc',
    'GivenNameAsc',
    'GivenNameDesc',
    'EmailAsc',
    'EmailDesc',
];

export type GlobalUserStatus = 'Active' | 'Deleted' | 'Pending';
export const GlobalUserStatusList: GlobalUserStatus[] = ['Active', 'Deleted', 'Pending'];

export type GlobalUserType = 'Internal' | 'External';
export const GlobalUserTypeList: GlobalUserType[] = ['Internal', 'External'];

export type MarketingContentSettingContentCreateModel = {
    contentId: string; // uuid
    ordinal: number; // int32
};

export type MarketingContentSettingCreateModel = {
    type: MarketingContentSettingType;
    content: MarketingContentSettingContentCreateModel[];
};

export type MarketingContentSettingType =
    | 'ProjectsPage'
    | 'AudiencesPage'
    | 'PartnershipPageHero'
    | 'PartnershipPageEvent';
export const MarketingContentSettingTypeList: MarketingContentSettingType[] = [
    'ProjectsPage',
    'AudiencesPage',
    'PartnershipPageHero',
    'PartnershipPageEvent',
];

export type MarketingContentSettingViewModel = {
    contentId: string; // uuid
    ordinal: number; // int32
};

export type MediaByFolderOrder = 'MediaNameAsc' | 'MediaNameDesc';
export const MediaByFolderOrderList: MediaByFolderOrder[] = ['MediaNameAsc', 'MediaNameDesc'];

export type MediaDetailModel = {
    mediaId: string; // uuid
    mediaType: MediaType;
    mediaStatus: MediaStatus;
    mediaPurpose: MediaPurpose;
    caption: string;
    extension: string;
    originalFileName: string;
    publicUri?: string | null;
    updatedDate: string; // date-time
    duration: number; // int32
    longitude?: number | null; // double
    latitude?: number | null; // double
};

export type MediaFolderCreateModel = {
    parentFolderId?: string | null; // uuid
    name: string;
};

export type MediaFolderUpdateModel = {
    name: string;
};

export type MediaFolderViewModel = {
    mediaFolderId: string; // uuid
    name: string;
    folders?: MediaFolderViewModel[] | null;
};

export type MediaFolderViewModelIPagedList = {
    list?: MediaFolderViewModel[] | null;
    count: number; // int32
    pageSize: number; // int32
    page: number; // int32
    orderBy?: string | null;
};

export type MediaPurpose = 'Contribution' | 'TileImage' | 'Content' | 'HeroImage' | 'Masthead' | 'Logo';
export const MediaPurposeList: MediaPurpose[] = [
    'Contribution',
    'TileImage',
    'Content',
    'HeroImage',
    'Masthead',
    'Logo',
];

export type MediaStatus = 'Active' | 'Processing' | 'Deleted';
export const MediaStatusList: MediaStatus[] = ['Active', 'Processing', 'Deleted'];

export type MediaType = 'Image' | 'Video' | 'Audio' | 'File' | 'Url' | 'Other';
export const MediaTypeList: MediaType[] = ['Image', 'Video', 'Audio', 'File', 'Url', 'Other'];

export type MediaViewModel = {
    mediaId: string; // uuid
    mediaType: MediaType;
    createdDate: string; // date-time
    name: string;
};

export type MediaViewModelIPagedList = {
    list?: MediaViewModel[] | null;
    count: number; // int32
    pageSize: number; // int32
    page: number; // int32
    orderBy?: string | null;
};

export type NotificationEntityType =
    | 'Project'
    | 'ProjectBrief'
    | 'PartnershipFinancialChart'
    | 'Account'
    | 'AccountFile'
    | 'ProjectFile';
export const NotificationEntityTypeList: NotificationEntityType[] = [
    'Project',
    'ProjectBrief',
    'PartnershipFinancialChart',
    'Account',
    'AccountFile',
    'ProjectFile',
];

export type NotificationEventType =
    | 'Unknown'
    | 'ProjectCreated'
    | 'ProjectBriefModified'
    | 'ProjectBriefSubmitted'
    | 'ProjectBriefRemoved'
    | 'ProjectPhaseChanged'
    | 'ProjectActivityAdded'
    | 'FinancialChartAdded'
    | 'FinancialChartUpdated'
    | 'NewAccountAssigned'
    | 'AccountTeamUpdated'
    | 'AccountInternalFileAdded'
    | 'AccountInternalFileUpdated'
    | 'AccountSharedFileAdded'
    | 'AccountSharedFileUpdated'
    | 'ProjectInternalFileAdded'
    | 'ProjectInternalFileUpdated'
    | 'ProjectSharedFileAdded'
    | 'ProjectSharedFileUpdated'
    | 'NewCatalystActivity';
export const NotificationEventTypeList: NotificationEventType[] = [
    'Unknown',
    'ProjectCreated',
    'ProjectBriefModified',
    'ProjectBriefSubmitted',
    'ProjectBriefRemoved',
    'ProjectPhaseChanged',
    'ProjectActivityAdded',
    'FinancialChartAdded',
    'FinancialChartUpdated',
    'NewAccountAssigned',
    'AccountTeamUpdated',
    'AccountInternalFileAdded',
    'AccountInternalFileUpdated',
    'AccountSharedFileAdded',
    'AccountSharedFileUpdated',
    'ProjectInternalFileAdded',
    'ProjectInternalFileUpdated',
    'ProjectSharedFileAdded',
    'ProjectSharedFileUpdated',
    'NewCatalystActivity',
];

export type NotificationEventTypeViewModel = {
    eventType: NotificationEventType;
    eventName?: string | null;
    enabled: boolean;
    permissionId: string; // uuid
};

export type NotificationGroupViewModel = {
    groupName?: string | null;
    notificationEventTypes?: NotificationEventTypeViewModel[] | null;
};

export type NotificationSettingToggleModel = {
    notificationEventType: NotificationEventType;
    clientId: string; // uuid
    accountId?: string | null; // uuid
};

export type PartnershipEventTileViewModel = {
    tileId: string; // uuid
    title: string;
    description: string;
    linkTitle: string;
    linkUrl: string;
    tileMediaId: string; // uuid
};

export type PartnershipHeroTileViewModel = {
    tileId: string; // uuid
    title: string;
    description: string;
    linkTitle: string;
    linkUrl: string;
    tileMediaId: string; // uuid
};

export type PartnershipHeroViewModel = {
    tileId: string; // uuid
    title?: string | null;
    description?: string | null;
    linkTitle?: string | null;
    linkUrl?: string | null;
    backgroundMediaId: string; // uuid
    ordinal: number; // int32
};

export type PowerPointSlideType = 'Title' | 'Chart';
export const PowerPointSlideTypeList: PowerPointSlideType[] = ['Title', 'Chart'];

export type ProblemDetails = {
    type?: string | null;
    title?: string | null;
    status?: number | null; // int32
    detail?: string | null;
    instance?: string | null;
};

export type ProjectActivityCreateModel = {
    activityId: string; // uuid
};

export type ProjectBriefStatus = 'Missing' | 'Added' | 'Draft';
export const ProjectBriefStatusList: ProjectBriefStatus[] = ['Missing', 'Added', 'Draft'];

export type ProjectsPageMarketingTileViewModel = {
    tileId: string; // uuid
    title: string;
    description: string;
    linkTitle: string;
    linkUrl: string;
    tileMediaId: string; // uuid
};

export type PropType = 'String' | 'Number' | 'Decimal' | 'Guid';
export const PropTypeList: PropType[] = ['String', 'Number', 'Decimal', 'Guid'];

export type RoleDisplayStyle = 'NoAccess' | 'Standard' | 'ClientInternal' | 'ClientExternal';
export const RoleDisplayStyleList: RoleDisplayStyle[] = ['NoAccess', 'Standard', 'ClientInternal', 'ClientExternal'];

export type SelectedAudiencePageContentTileViewModel = {
    tileId: string; // uuid
    title: string;
    description: string;
    linkTitle: string;
    linkUrl: string;
    tileMediaId: string; // uuid
    ordinal: number; // int32
};

export type SelectedPartnershipEventMarketingTileViewModel = {
    tileId: string; // uuid
    title?: string | null;
    description?: string | null;
    linkTitle?: string | null;
    linkUrl?: string | null;
    tileMediaId: string; // uuid
    ordinal: number; // int32
};

export type SelectedProjectsPageMarketingTileViewModel = {
    tileId: string; // uuid
    title: string;
    description: string;
    linkTitle: string;
    linkUrl: string;
    tileMediaId: string; // uuid
    ordinal: number; // int32
};

export type TeamMemberCreateModel = {
    title?: string | null;
    mediaId?: string | null; // uuid
    ordinal: number; // int32
    userId: string; // uuid
};

export type TeamMemberFilterOrder =
    | 'TitleAsc'
    | 'TitleDesc'
    | 'OrdinalAsc'
    | 'OrdinalDesc'
    | 'DisplayNameAsc'
    | 'DisplayNameDesc';
export const TeamMemberFilterOrderList: TeamMemberFilterOrder[] = [
    'TitleAsc',
    'TitleDesc',
    'OrdinalAsc',
    'OrdinalDesc',
    'DisplayNameAsc',
    'DisplayNameDesc',
];

export type TeamMemberStatus = 'Active' | 'Deleted';
export const TeamMemberStatusList: TeamMemberStatus[] = ['Active', 'Deleted'];

export type TeamMemberViewModel = {
    teamMemberId: string; // uuid
    teamMemberStatus: TeamMemberStatus;
    title?: string | null;
    mediaId?: string | null; // uuid
    ordinal: number; // int32
    userId?: string | null; // uuid
    displayName?: string | null;
};

export type TeamMemberViewModelPagedList = {
    list?: TeamMemberViewModel[] | null;
    count: number; // int32
    pageSize: number; // int32
    page: number; // int32
    orderBy?: string | null;
};

export type UserDetailModel = {
    userId: string; // uuid
    displayName?: string | null;
    email?: string | null;
};

export type UserLoginType = 'System' | 'Standard' | 'SingleUse';
export const UserLoginTypeList: UserLoginType[] = ['System', 'Standard', 'SingleUse'];

export type UserNotificationDetailModel = {
    userNotificationId: string; // uuid
    notificationId: string; // uuid
    clientId: string; // uuid
    clientShortName?: string | null;
    accountId?: string | null; // uuid
    accountShortName?: string | null;
    accountName?: string | null;
    notificationType: NotificationEventType;
    notificationStatus: UserNotificationStatus;
    redirectUrl?: string | null;
    title: string;
    message: string;
    createdDate: string; // date-time
    createdBy: string; // uuid
    entityId?: string | null; // uuid
    entityType: NotificationEntityType;
};

export type UserNotificationMarkAllAsReadInputModel = {
    minDate?: string | null; // date-time
};

export type UserNotificationOrder = 'CreatedDateAsc' | 'CreatedDateDesc';
export const UserNotificationOrderList: UserNotificationOrder[] = ['CreatedDateAsc', 'CreatedDateDesc'];

export type UserNotificationStatus = 'Unreviewed' | 'Reviewed' | 'Read' | 'Deleted';
export const UserNotificationStatusList: UserNotificationStatus[] = ['Unreviewed', 'Reviewed', 'Read', 'Deleted'];

export type UserNotificationViewModel = {
    userNotificationId: string; // uuid
    notificationId: string; // uuid
    clientId: string; // uuid
    clientShortName?: string | null;
    accountId?: string | null; // uuid
    accountShortName?: string | null;
    accountName?: string | null;
    notificationType: NotificationEventType;
    notificationStatus: UserNotificationStatus;
    redirectUrl?: string | null;
    title: string;
    message: string;
    createdDate: string; // date-time
    createdBy: string; // uuid
    entityId?: string | null; // uuid
    entityType: NotificationEntityType;
};

export type UserNotificationViewModelPagedList = {
    list?: UserNotificationViewModel[] | null;
    count: number; // int32
    pageSize: number; // int32
    page: number; // int32
    orderBy?: string | null;
};

export type UserPageOrder =
    | 'DisplayNameAsc'
    | 'DisplayNameDesc'
    | 'GivenNameAsc'
    | 'GivenNameDesc'
    | 'EmailAsc'
    | 'EmailDesc';
export const UserPageOrderList: UserPageOrder[] = [
    'DisplayNameAsc',
    'DisplayNameDesc',
    'GivenNameAsc',
    'GivenNameDesc',
    'EmailAsc',
    'EmailDesc',
];

export type UserStatus = 'Active' | 'Deleted' | 'Pending';
export const UserStatusList: UserStatus[] = ['Active', 'Deleted', 'Pending'];

export type UserType = 'Internal' | 'External';
export const UserTypeList: UserType[] = ['Internal', 'External'];

export type UserViewModel = {
    userId: string; // uuid
    displayName?: string | null;
    givenName?: string | null;
    email?: string | null;
    userLoginType: UserLoginType;
    userType: UserType;
};

export type UserViewModelPagedList = {
    list?: UserViewModel[] | null;
    count: number; // int32
    pageSize: number; // int32
    page: number; // int32
    orderBy?: string | null;
};

export type AccountFetchListParams = {
    clientId?: string;
    accountId?: string;
    idList?: string[];
    accountStatus?: AccountStatus;
    accountType?: AccountType;
    filter?: string;
    forUserId?: string;
    page?: number;
    pageSize?: number;
    orderBy?: GlobalAccountOrder;
};

export type AccountFetchByNameParams = {
    client?: string;
    account?: string;
};

export type AccountFetchTeamEmailParams = {
    clientId?: string;
    accountId?: string;
};

export type FetchShowChartNParams = {
    clientId?: string;
    accountId?: string;
};

export type ToggleShowChartNParams = {
    clientId?: string;
    accountId?: string;
};

export type AccountDataProviderFetchListParams = {
    accountId?: string;
    filter?: string;
    page?: number;
    pageSize?: number;
    orderBy?: string;
};

export type AccountDataProviderFetchForAudienceChartsParams = {
    clientId?: string;
    accountId?: string;
    chartType?: AudienceChartType;
};

export type AccountSendInternalFileNotificationParams = {
    clientId?: string;
    accountId?: string;
};

export type AccountSendExternalFileNotificationParams = {
    clientId?: string;
    accountId?: string;
};

export type AccountFolderAddParams = {
    clientId?: string;
    accountId?: string;
};

export type AccountFolderFetchListParams = {
    clientId?: string;
    accountId?: string;
    folderId?: string;
};

export type AccountFolderSaveParams = {
    clientId?: string;
    accountId?: string;
    folderId?: string;
};

export type AccountFolderDeleteByIdParams = {
    clientId?: string;
    accountId?: string;
    folderId?: string;
};

export type AccountMediaAddParams = {
    clientId?: string;
    accountId?: string;
    folderId?: string;
};

export type AccountMediaFetchListParams = {
    clientId?: string;
    accountId?: string;
    folderId?: string;
    page?: number;
    pageSize?: number;
    orderBy?: MediaByFolderOrder;
};

export type AccountFolderFetchByIdParams = {
    clientId?: string;
    accountId?: string;
    folderId?: string;
    mediaId?: string;
};

export type AccountUserFetchListParams = {
    clientId?: string;
    accountId?: string;
    page?: number;
    pageSize?: number;
    orderBy?: AccountUserListOrder;
};

export type AccountUserAddParams = {
    clientId?: string;
    accountId?: string;
};

export type AccountUserSaveParams = {
    clientId?: string;
    accountId?: string;
    accountUserId?: string;
};

export type AccountUserDeleteByIdParams = {
    clientId?: string;
    accountId?: string;
    userId?: string;
};

export type AccountUserReorderParams = {
    clientId?: string;
    accountId?: string;
};

export type ActivityFetchListParams = {
    clientId?: string;
    accountId?: string;
    filter?: string;
    clientProjectId?: string;
    objectId?: string;
    activityType?: ActivityType;
    activityStatus?: ActivityStatus;
    communityType?: CommunityType;
    page?: number;
    pageSize?: number;
    orderBy?: ActivityPageOrder;
};

export type ActivityCreateParams = {
    clientId?: string;
    accountId?: string;
};

export type ActivityFetchByIdParams = {
    clientId?: string;
    accountId?: string;
    id?: string;
};

export type ActivitySaveParams = {
    clientId?: string;
    accountId?: string;
    id?: string;
};

export type AudienceChartFetchListParams = {
    clientId?: string;
    accountId?: string;
    status?: AudienceChartStatus;
    type?: AudienceChartType;
    accountDataProviderId?: string;
    page?: number;
    pageSize?: number;
    orderBy?: AudienceChartListOrder;
};

export type AudienceChartCreateParams = {
    clientId?: string;
    accountId?: string;
};

export type AudienceChartFetchByIdParams = {
    clientId?: string;
    accountId?: string;
    audienceChartId?: string;
};

export type AudienceChartDeleteByIdParams = {
    clientId?: string;
    accountId?: string;
    audienceChartId?: string;
};

export type FetchAudiencePageMarketingTileParams = {
    clientId?: string;
    accountId?: string;
};

export type AudienceRequestCreateParams = {
    clientId?: string;
    accountId?: string;
};

export type CatActivityFetchListParams = {
    clientId?: string;
    accountId?: string;
    accountDataProviderId?: string;
    filter?: string;
    isPublished?: boolean;
    isDeleted?: boolean;
    activityType?: string[];
    page?: number;
    pageSize?: number;
    orderBy?: CatActivityOrder;
};

export type CatActivityFetchByIdParams = {
    clientId?: string;
    accountId?: string;
    accountDataProviderId?: string;
    activityId?: string;
};

export type CatActivityImportParams = {
    clientId?: string;
    accountId?: string;
    accountDataProviderId?: string;
    activityId?: string;
};

export type FetchMemberCountParams = {
    clientId?: string;
    accountId?: string;
    accountDataProviderId?: string;
};

export type CatProfileFieldFetchListParams = {
    clientId?: string;
    accountId?: string;
    accountDataProviderId?: string;
    filter?: string;
    isPublished?: boolean;
    isRemoved?: boolean;
    isReportPreferred?: boolean;
    isMemberField?: boolean;
    isRequired?: boolean;
    isExportable?: boolean;
    profileFieldDataType?: string[];
    page?: number;
    pageSize?: number;
    orderBy?: CatProfileFieldOrder;
};

export type CatProfileFieldFetchByIdParams = {
    clientId?: string;
    accountId?: string;
    accountDataProviderId?: string;
    profileFieldId?: string;
};

export type CatProfileFieldFetchMetricsByIdParams = {
    clientId?: string;
    accountId?: string;
    accountDataProviderId?: string;
    profileFieldId?: string;
};

export type ChartFetchByIdParams = {
    clientId?: string;
    accountId?: string;
    chartId?: string;
};

export type ChartSaveParams = {
    clientId?: string;
    accountId?: string;
    chartId?: string;
};

export type ChartDeleteByIdParams = {
    clientId?: string;
    accountId?: string;
    chartId?: string;
};

export type ChartCreateParams = {
    clientId?: string;
    accountId?: string;
};

export type ChartRefreshDataParams = {
    clientId?: string;
    accountId?: string;
    chartId?: string;
};

export type ClientFetchListParams = {
    filter?: string;
    clientId?: string;
    status?: ClientStatus;
    type?: ClientType;
    createdBefore?: string;
    createdAfter?: string;
    idList?: string[];
    forUserId?: string;
    page?: number;
    pageSize?: number;
    orderBy?: ClientPageOrder;
};

export type ClientProjectFetchListParams = {
    clientId?: string;
    accountId?: string;
    status?: ClientProjectStatus;
    filter?: string;
    tags?: string[];
    phaseId?: string;
    subPhaseId?: string;
    page?: number;
    pageSize?: number;
    orderBy?: ClientProjectOrder;
};

export type ClientProjectCreateParams = {
    clientId?: string;
    accountId?: string;
};

export type ClientProjectFetchByIdParams = {
    clientId?: string;
    accountId?: string;
    id?: string;
};

export type ClientProjectSaveParams = {
    clientId?: string;
    accountId?: string;
    id?: string;
};

export type ClientProjectDeleteByIdParams = {
    clientId?: string;
    accountId?: string;
    id?: string;
};

export type ClientProjectSaveMinParams = {
    clientId?: string;
    accountId?: string;
    id?: string;
};

export type ClientProjectBriefFetchListParams = {
    clientId?: string;
    accountId?: string;
    filter?: string;
    page?: number;
    pageSize?: number;
    orderBy?: ClientProjectBriefOrder;
};

export type ClientProjectActivityFetchListParams = {
    clientId?: string;
    accountId?: string;
    clientProjectId?: string;
    filter?: string;
    objectId?: string;
    activityType?: ActivityType;
    activityStatus?: ActivityStatus;
    communityType?: CommunityType;
    page?: number;
    pageSize?: number;
    orderBy?: ActivityPageOrder;
};

export type ClientProjectActivityCreateParams = {
    clientId?: string;
    accountId?: string;
    clientProjectId?: string;
};

export type ClientProjectActivityDeleteByIdParams = {
    clientId?: string;
    accountId?: string;
    clientProjectId?: string;
    activityId?: string;
};

export type ProjectSendInternalFileNotificationParams = {
    clientId?: string;
    accountId?: string;
    projectId?: string;
};

export type ProjectSendExternalFileNotificationParams = {
    clientId?: string;
    accountId?: string;
    projectId?: string;
};

export type ClientProjectFolderCreateParams = {
    clientId?: string;
    accountId?: string;
    projectId?: string;
};

export type ClientProjectFolderFetchListParams = {
    clientId?: string;
    accountId?: string;
    projectId?: string;
    folderId?: string;
};

export type ClientProjectFolderSaveParams = {
    clientId?: string;
    accountId?: string;
    folderId?: string;
    projectId?: string;
};

export type ClientProjectFolderDeleteByIdParams = {
    clientId?: string;
    accountId?: string;
    folderId?: string;
    projectId?: string;
};

export type ClientProjectMediaAddParams = {
    clientId?: string;
    accountId?: string;
    folderId?: string;
    projectId?: string;
};

export type ClientProjectMediaFetchListParams = {
    clientId?: string;
    accountId?: string;
    folderId?: string;
    page?: number;
    pageSize?: number;
    orderBy?: MediaByFolderOrder;
    projectId?: string;
};

export type ClientProjectMediaDeleteByIdParams = {
    clientId?: string;
    accountId?: string;
    folderId?: string;
    mediaId?: string;
    projectId?: string;
};

export type ClientProjectPhaseFetchListParams = {
    clientId?: string;
    accountId?: string;
    status?: ClientProjectPhaseStatus;
    filter?: string;
    page?: number;
    pageSize?: number;
    orderBy?: ClientProjectPhaseOrder;
};

export type ClientProjectPhaseCreateParams = {
    clientId?: string;
    accountId?: string;
};

export type ClientProjectPhaseFetchByIdParams = {
    clientId?: string;
    accountId?: string;
    id?: string;
};

export type ClientProjectPhaseSaveParams = {
    clientId?: string;
    accountId?: string;
    id?: string;
};

export type ClientProjectPhaseDeleteByIdParams = {
    clientId?: string;
    accountId?: string;
    id?: string;
};

export type ClientProjectSubPhaseCreateParams = {
    clientId?: string;
    accountId?: string;
    phaseId?: string;
};

export type ClientProjectSubPhaseSaveParams = {
    clientId?: string;
    accountId?: string;
    phaseId?: string;
    id?: string;
};

export type ClientProjectSubPhaseDeleteByIdParams = {
    clientId?: string;
    accountId?: string;
    phaseId?: string;
    id?: string;
};

export type DataProviderFetchListParams = {
    clientId?: string;
    accountId?: string;
    status?: DataProviderStatus;
    filter?: string;
    page?: number;
    pageSize?: number;
    orderBy?: DataProviderOrder;
};

export type DataProviderFetchByIdParams = {
    clientId?: string;
    accountId?: string;
    id?: string;
};

export type FinancialsWidgetFetchListParams = {
    clientId?: string;
    accountId?: string;
};

export type FinancialsWidgetCreateParams = {
    clientId?: string;
    accountId?: string;
};

export type FinancialsWidgetDeleteByIdParams = {
    clientId?: string;
    accountId?: string;
    widgetId?: string;
};

export type FinancialsWidgetSaveParams = {
    clientId?: string;
    accountId?: string;
    widgetId?: string;
};

export type MarketingContentSettingsFetchListParams = {
    clientId?: string;
    accountId?: string;
    type?: MarketingContentSettingType;
};

export type MarketingContentSettingsSaveParams = {
    clientId?: string;
    accountId?: string;
};

export type MarketingContentSettingsDeleteParams = {
    clientId?: string;
    accountId?: string;
    contentType?: MarketingContentSettingType;
};

export type MarketingContentHeroFetchListParams = {
    clientId?: string;
    accountId?: string;
};

export type MarketingContentEventFetchListParams = {
    clientId?: string;
    accountId?: string;
};

export type MarketingContentProjectFetchListParams = {
    clientId?: string;
    accountId?: string;
};

export type AudienceMarketingContentFetchListParams = {
    clientId?: string;
    accountId?: string;
};

export type PartnershipHeroFetchActiveParams = {
    clientId?: string;
    accountId?: string;
};

export type PartnershipEventFetchActiveParams = {
    clientId?: string;
    accountId?: string;
};

export type ProjectsPageMarketingTileFetchListParams = {
    clientId?: string;
    accountId?: string;
};

export type TeamMemberFetchListParams = {
    clientId?: string;
    accountId?: string;
    status?: TeamMemberStatus;
    filter?: string;
    page?: number;
    pageSize?: number;
    orderBy?: TeamMemberFilterOrder;
};

export type TeamMemberCreateParams = {
    clientId?: string;
    accountId?: string;
};

export type TeamMemberFetchByIdParams = {
    clientId?: string;
    accountId?: string;
    teamMemberId?: string;
};

export type TeamMemberSaveParams = {
    clientId?: string;
    accountId?: string;
    teamMemberId?: string;
};

export type TeamMemberDeleteByIdParams = {
    clientId?: string;
    accountId?: string;
    teamMemberId?: string;
};

export type AccountCurrentUserParams = {
    clientId?: string;
    accountId?: string;
};

export type ClientCurrentUserParams = {
    clientId?: string;
};

export type UserFetchListParams = {
    clientId?: string;
    accountId?: string;
    filter?: string;
    userStatus?: UserStatus;
    userLoginType?: UserLoginType;
    userType?: UserType;
    tags?: string[];
    roleId?: string;
    userGroupId?: string;
    profileFields?: string[];
    page?: number;
    pageSize?: number;
    orderBy?: UserPageOrder;
};

export type GlobalUserFetchListParams = {
    accountId?: string;
    filter?: string;
    clientId?: string;
    roleId?: string;
    idList?: string[];
    tags?: string[];
    status?: GlobalUserStatus[];
    page?: number;
    pageSize?: number;
    orderBy?: GlobalUserOrderByFilter;
};

export type UserNotificationFetchListParams = {
    clientId?: string;
    accountId?: string;
    notificationType?: NotificationEventType;
    entityType?: NotificationEntityType;
    notificationStatus?: UserNotificationStatus[];
    page?: number;
    pageSize?: number;
    orderBy?: UserNotificationOrder;
};

export type UserNotificationRecoveryParams = {
    id?: string;
    notificationStatus?: UserNotificationStatus;
};

