import { PencilIcon } from '@heroicons/react/24/solid';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Button from '../../../common/ButtonNew';
import {
    accountFetchByName as getAccountInfo,
    accountFetchTeamEmail as saveTeamEmail,
} from '../../../common/clientApi/AccountApi';
import { accountFetchTeamEmailCanEdit, accountTeamMemberCanView } from '../../../common/clientApi/AccountPermissions';
import { AccountDetailModel, AccountTeamEmailUpdateModel } from '../../../common/clientApi/ClientApiTypes';
import ButtonTextWithSpinner from '../../../common/components/ButtonTextWithSpinner';
import ErrorDisplay from '../../../common/components/ErrorDisplay';
import { ErrorLabel, TextInput } from '../../../common/components/form/Form';
import { ValidationConfiguration, useValidation } from '../../../common/hooks/validation/useValidation';
import { Required } from '../../../common/hooks/validation/validationRules';
import { useAccountPermission } from '../../../common/userPermission';
import { isValidEmail } from '../../../common/utils';
import ErrorDialog from '../../../common/ErrorDialog';

type AccountInfoParams = {
    client: string;
    account: string;
};

const TeamMemberEmailValidationConfiguration: ValidationConfiguration<AccountTeamEmailUpdateModel> = {
    teamEmail: [
        Required('Email'),
        {
            message: 'Not a valid email address.',
            isInvalid: (value: string) => !isValidEmail(value),
        },
        {
            message: 'Email must end with @cspace.com',
            isInvalid: (value: string) => !value.endsWith('@cspace.com'),
        },
    ],
};

export default function TeamEmailAddress() {
    const queryClient = useQueryClient();
    const { client, account } = useParams<AccountInfoParams>();

    const [formValues, setFormValues] = useState({
        teamEmail: '',
    });
    const [editedFields, setEditedFields] = useState({});
    const [editMode, setEditMode] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const canUpdateTeamAddress = useAccountPermission(accountFetchTeamEmailCanEdit);
    const canViewTeamMember = useAccountPermission(accountTeamMemberCanView);
    const { validationMessages, isModelInvalid } = useValidation({
        model: formValues,
        configuration: TeamMemberEmailValidationConfiguration,
        edited: editedFields,
    });

    const changeHandler = (name: string, value: string) => {
        setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
        setEditedFields((prevValues) => ({ ...prevValues, [name]: true }));
    };

    const toggleEditMode = (to: boolean) => {
        changeHandler('teamEmail', accountDetails?.teamEmail ?? '');
        setEditMode(to);
    };

    const { data: accountDetails, error: accountDetailsError } = useQuery<AccountDetailModel, Error>({
        queryKey: ['accountInfo', client!, account!],
        queryFn: () => getAccountInfo(client!, account!),
        staleTime: 10000,
        enabled: !!client && !!account,
    });

    const { mutate: saveMutateEmail, isLoading: isSavingEmail } = useMutation({
        mutationFn: (data: AccountTeamEmailUpdateModel) =>
            accountDetails
                ? saveTeamEmail(accountDetails.clientId, accountDetails.accountId, data)
                : Promise.resolve(undefined),
        onSuccess: () => {
            if (accountDetails) {
                queryClient.invalidateQueries({
                    queryKey: ['accountInfo', client!, account!],
                });
                toggleEditMode(false);
            }
        },
        onError: () => setErrorMessage('An error occurred while saving the team email. Please try again.'),
    });

    return (
        <>
            {errorMessage && (
                <ErrorDialog onClose={() => setErrorMessage(null)}>
                    <>{errorMessage}</>
                </ErrorDialog>
            )}
            <div className="flex w-full flex-1 flex-col bg-white px-6 py-8">
                <h3 className="mb-3 border-b border-primary-50 pb-3">Team Email Address</h3>
                <ErrorDisplay
                    config={{
                        default:
                            'The server encountered an internal error or misconfiguration and was unable to load team email address.',
                    }}
                    error={accountDetailsError}
                >
                    {!canViewTeamMember || !accountDetails?.teamEmail || editMode ? (
                        <>
                            <label htmlFor="teamEmail">Enter email address for client contact</label>
                            <div className="flex items-center justify-between gap-2.5">
                                <TextInput
                                    className="grow"
                                    name="teamEmail"
                                    value={formValues.teamEmail}
                                    onChange={changeHandler}
                                    hasError={!!validationMessages.teamEmail}
                                    disabled={!(canUpdateTeamAddress && canViewTeamMember)}
                                />
                                {canUpdateTeamAddress && (
                                    <Button
                                        onClick={() => saveMutateEmail({ teamEmail: formValues.teamEmail })}
                                        isDisabled={isModelInvalid || isSavingEmail}
                                    >
                                        <ButtonTextWithSpinner
                                            isLoading={isSavingEmail}
                                            loadingText=""
                                            submitText="Save"
                                        />
                                    </Button>
                                )}
                            </div>
                            {!!validationMessages.teamEmail && (
                                <div className="mt-1">
                                    <ErrorLabel errors={validationMessages.teamEmail} />
                                </div>
                            )}
                            <div className="mt-3 border-b border-primary-50" />
                        </>
                    ) : (
                        <>
                            <div className="mr-4 flex items-center justify-between gap-2.5">
                                <h4 className="grow truncate">{accountDetails.teamEmail}</h4>
                                {canUpdateTeamAddress && (
                                    <PencilIcon
                                        onClick={() => toggleEditMode(true)}
                                        className="h-4 w-4 cursor-pointer text-primary-500 transition-transform hover:scale-125"
                                    />
                                )}
                            </div>
                            <div className="mt-3 border-b border-primary-50" />
                        </>
                    )}
                </ErrorDisplay>
            </div>
        </>
    );
}
