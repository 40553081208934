/*-----------------------------------------------
    Generated by SwaggerToTS
    Do not customize this file, update the generator
    Copyright C Space 2024
-----------------------------------------------*/
/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from 'axios';
import { appId } from '../azure/auth';
import {
    getAuthToken,
    clientApiScope,
    clientApiUri
} from '../azure/auth';
import {
    TeamMemberViewModelPagedList,
    TeamMemberStatus,
    TeamMemberFilterOrder,
    TeamMemberFetchListParams,
    TeamMemberViewModel,
    TeamMemberCreateModel,
} from './ClientApiTypes';

/**
 * Returns team members.
*/
export async function teamMemberFetchList(clientId: string, accountId: string, input: TeamMemberFetchListParams): Promise<TeamMemberViewModelPagedList> { 
    const token = await getAuthToken([clientApiScope]);
    const q: string[] = [];
    if (input.status) { q.push(`status=${encodeURIComponent(input.status)}`); }
    if (input.filter) { q.push(`filter=${encodeURIComponent(input.filter)}`); }
    if (input.page) { q.push(`page=${encodeURIComponent(input.page)}`); }
    if (input.pageSize) { q.push(`pageSize=${encodeURIComponent(input.pageSize)}`); }
    if (input.orderBy) { q.push(`orderBy=${encodeURIComponent(input.orderBy)}`); }
    const p = q.length > 0 ? `?${q.join('&')}` : '';
    const resp = await axios.get<TeamMemberViewModelPagedList>(
        `${clientApiUri}/TeamMember/${clientId}/${accountId}${p}`,
        { headers: { 
            'authorization': `bearer ${token}`,
			'cspace-appId': `${appId}` 
        } });
    return resp.data;
}

/**
 * Creates a new team member.
*/
export async function teamMemberCreate(clientId: string, accountId: string, input: TeamMemberCreateModel): Promise<TeamMemberViewModel> { 
    const token = await getAuthToken([clientApiScope]);
    const resp = await axios.post<TeamMemberViewModel>(
        `${clientApiUri}/TeamMember/${clientId}/${accountId}`,
            input,
        { headers: { 
            'authorization': `bearer ${token}`,
			'cspace-appId': `${appId}`  
        } });
    return resp.data;
}

/**
 * Get a team member
*/
export async function teamMemberFetchById(clientId: string, accountId: string, teamMemberId: string): Promise<TeamMemberViewModel> { 
    const token = await getAuthToken([clientApiScope]);
    
    const resp = await axios.get<TeamMemberViewModel>(
        `${clientApiUri}/TeamMember/${clientId}/${accountId}/${teamMemberId}`,
        { headers: { 
            'authorization': `bearer ${token}`,
			'cspace-appId': `${appId}` 
        } });
    return resp.data;
}

/**
 * Updates a team member
*/
export async function teamMemberSave(clientId: string, accountId: string, teamMemberId: string, input: TeamMemberCreateModel): Promise<TeamMemberViewModel> { 
    const token = await getAuthToken([clientApiScope]);
    const resp = await axios.put<TeamMemberViewModel>(
        `${clientApiUri}/TeamMember/${clientId}/${accountId}/${teamMemberId}`,
            input,
        { headers: {
            'authorization': `bearer ${token}`,
			'cspace-appId': `${appId}`
        } });
    return resp.data;
}

/**
 * Remove a team member
*/
export async function teamMemberDeleteById(clientId: string, accountId: string, teamMemberId: string): Promise<void> { 
    const token = await getAuthToken([clientApiScope]);
    const resp = await axios.delete(
        `${clientApiUri}/TeamMember/${clientId}/${accountId}/${teamMemberId}`,
        { headers: { 
            'authorization': `bearer ${token}`,
			'cspace-appId': `${appId}`  
        } });
    return resp.data;
}

