import React, { useState, useCallback, useRef } from 'react';
import ReactQuill from 'react-quill';
import { FinancialsWidgetCreateModel, FinancialsWidgetViewModel } from '../../../../common/clientApi/ClientApiTypes';
import { TextInput } from '../../../../common/components/form/Form';
import Button from '../../../../common/ButtonNew';
import RichTextEditor from '../../../../common/components/form/RichTextEditor';
import { CharactersRemaining } from '../../../../common/components/form/Form';
import TextWidgetPreview from './TextWidgetPreview';
import HeaderIndicator from '../../../../common/components/HeaderIndicator';
import { HeaderIndicatorType } from '../../../../common/consts/enums';
import { TEXT_WIDGET_CHARACTER_LIMIT } from '../../../../common/consts/settings';
import { VALIDATION_CONSTS, TextWidgetValidationConfiguration } from './TextWidgetValidation';
import { useValidation } from '../../../../common/hooks/validation/useValidation';
import { ErrorLabel } from '../../../../common/components/form/Form';

type Props = {
    widgetData: FinancialsWidgetCreateModel | FinancialsWidgetViewModel;
    hasChanged: boolean;
    isReadOnly?: boolean;
    onChange: (data: FinancialsWidgetCreateModel | FinancialsWidgetViewModel) => void;
    onPublish: () => void;
    onRemove: () => void;
    onReset: () => void;
};

export default function TextWidgetContainer({
    widgetData,
    hasChanged,
    isReadOnly,
    onChange,
    onPublish,
    onRemove,
    onReset,
}: Props) {
    const [editedFields, setEditedFields] = useState<Record<string, boolean>>({});
    const quillRef = useRef<ReactQuill>(null);

    const [focusedField, setFocusedField] = useState<string | undefined>(undefined);
    const focusHandler = (fieldName: string) => setFocusedField(fieldName);
    const blurHandler = () => setFocusedField(undefined);

    const { validationMessages, isModelInvalid } = useValidation({
        model: widgetData,
        configuration: TextWidgetValidationConfiguration,
        focused: focusedField,
        edited: editedFields,
    });

    const nameValueChangeHandler = useCallback(
        (name: string, value: string) => {
            if (
                (quillRef?.current?.getEditor()?.getLength() ?? 1) > TEXT_WIDGET_CHARACTER_LIMIT &&
                value.length > (widgetData.textItemValue?.length ?? 1)
            ) {
                onChange({ ...widgetData });
            } else {
                setEditedFields({ ...editedFields, [name]: true });
                onChange({
                    ...widgetData,
                    [name]: value,
                });
            }
        },
        [onChange, widgetData]
    );

    const rteRequiredError = (quillRef?.current?.getEditor()?.getLength() ?? 1) <= 1;
    const rteMinLengthError = (quillRef?.current?.getEditor()?.getLength() ?? 1) < 4;
    const showValidationError = editedFields?.textItemValue && focusedField !== 'textItemValue' && rteMinLengthError;

    return (
        <div className="mb-8 flex w-full gap-1">
            <div className="flex w-[420px] flex-none flex-col rounded-l-sm bg-white px-6 py-8">
                <div className="mb-5 flex flex-col bg-white">
                    <TextInput
                        className="px-3.5 py-2.5 text-xl font-semibold leading-6"
                        name="title"
                        hasError={!!validationMessages.title}
                        maxLength={VALIDATION_CONSTS.TITLE_MAX_LENGTH}
                        readOnly={isReadOnly}
                        value={widgetData.title}
                        onChange={nameValueChangeHandler}
                        onFocus={focusHandler}
                        onBlur={blurHandler}
                    />
                    {validationMessages.title && (
                        <ErrorLabel className="mt-1 text-sm text-error-700" errors={validationMessages.title} />
                    )}
                    {widgetData.title.length >= VALIDATION_CONSTS.TITLE_MAX_LENGTH && (
                        <div className="mt-1 text-sm">{`Maximum ${VALIDATION_CONSTS.TITLE_MAX_LENGTH} characters`}</div>
                    )}
                </div>
                <RichTextEditor
                    hasError={showValidationError}
                    ref={quillRef}
                    value={widgetData.textItemValue}
                    name="textItemValue"
                    readOnly={isReadOnly}
                    onChange={nameValueChangeHandler}
                    className="max-h-[300px] overflow-auto"
                    onFocus={focusHandler}
                    onBlur={blurHandler}
                />
                <div className="mt-4 text-xs">
                    {showValidationError ? (
                        <ErrorLabel
                            className="text-sm text-error-700"
                            errors={rteRequiredError ? 'Required field' : 'Minimum 3 characters '}
                        />
                    ) : (
                        <CharactersRemaining
                            className="text-sm"
                            max={TEXT_WIDGET_CHARACTER_LIMIT}
                            count={quillRef?.current?.getEditor()?.getLength() ?? 1}
                        />
                    )}
                </div>
                {!isReadOnly && (
                    <>
                        <div className="mb-4 mt-8 w-full border-t border-gray-100" />
                        <div className="flex items-center justify-start gap-4">
                            <Button
                                buttonSize="sm"
                                onMouseDown={onPublish}
                                isDisabled={isModelInvalid || rteMinLengthError}
                            >
                                Publish
                            </Button>
                            <Button
                                buttonSize="sm"
                                buttonStyle="secondary"
                                onMouseDown={() => {
                                    setEditedFields({});
                                    onReset();
                                }}
                            >
                                Reset
                            </Button>
                            <Button usePadding={false} buttonSize="sm" buttonStyle="text" onMouseDown={onRemove}>
                                Delete
                            </Button>
                        </div>
                    </>
                )}
            </div>
            <div className="flex flex-1 flex-col justify-start rounded-r-sm bg-white px-6 py-9">
                <div className="mb-3">
                    <HeaderIndicator type={hasChanged ? HeaderIndicatorType.Draft : HeaderIndicatorType.Published} />
                </div>
                <div className="w-full border-t border-primary-50" />
                <div className="mt-6 flex flex-col rounded-sm border border-primary-50 px-6 py-8">
                    <TextWidgetPreview widgetData={widgetData} />
                </div>
            </div>
        </div>
    );
}
