import { SingleValue } from 'react-select';
import ButtonNew from '../../../../common/ButtonNew';
import Dropdown, { DropdownOption } from '../../../../common/Dropdown';
import { SyntheticEvent } from 'react';
import { HeaderIndicatorType } from '../../../../common/consts/enums';
import Button from '../../../../common/ButtonNew';

export type ContentBody = {
    selectedContent: DropdownOption;
    onContentChanged: (value: SingleValue<DropdownOption>) => void;
    content: DropdownOption[];
    sectionToDisplay: string;
    isError: boolean;
};

type Props = {
    contentBodyList: ContentBody[];
    isPublishDisabled: boolean;
    isUnpublishedDisabled?: boolean;
    isResetDisabled?: boolean;
    isPublishHidden: boolean;
    setShowUnsavedChangesModal: (isShow: boolean) => void;
    setHeaderIndicatorType: (type: HeaderIndicatorType) => void;
    onPublish: (evt?: SyntheticEvent) => void;
    onReset: (evt?: SyntheticEvent) => void;
    onUnpublish: (evt?: SyntheticEvent) => void;
};

export default function ContentDetails({
    contentBodyList,
    isPublishDisabled,
    isResetDisabled = false,
    isUnpublishedDisabled = false,
    isPublishHidden,
    setShowUnsavedChangesModal,
    setHeaderIndicatorType,
    onPublish,
    onUnpublish,
    onReset,
}: Props) {
    const handleOnPublishClick = (evt?: SyntheticEvent) => {
        setShowUnsavedChangesModal(false);
        onPublish(evt);
    };

    const handleOnUnpublishClick = (evt?: SyntheticEvent) => {
        setShowUnsavedChangesModal(false);
        onUnpublish(evt);
    };

    const handleOnResetClick = (evt?: SyntheticEvent) => {
        setShowUnsavedChangesModal(false);
        onReset(evt);
    };

    return (
        <>
            <table className="mb-10 w-[640px]">
                <thead className="bg-background">
                    <tr>
                        <th
                            key="content"
                            scope="col"
                            className="w-1/2 px-4 py-3 text-left text-xs font-bold uppercase text-black"
                        >
                            Content
                        </th>
                        <th
                            key="sectionToDisplay"
                            scope="col"
                            className="w-1/2 px-2 py-3 text-left text-xs font-bold uppercase text-black"
                        >
                            Section to display
                        </th>
                    </tr>
                </thead>
                <tbody className="bg-white pt-3">
                    {contentBodyList.map((contentBody, index) => {
                        return (
                            <tr key={index.toString()}>
                                <td className="max-w-[320px] px-4 pt-6">
                                    <Dropdown
                                        isError={contentBody.isError}
                                        isClearable={true}
                                        isDisabled={isPublishHidden}
                                        options={contentBody.content}
                                        value={contentBody.selectedContent}
                                        onChange={(option: SingleValue<DropdownOption>) => {
                                            setShowUnsavedChangesModal(true);
                                            if (option?.value !== contentBody.selectedContent.value) {
                                                setHeaderIndicatorType(HeaderIndicatorType.Draft);
                                            }
                                            contentBody.onContentChanged(option);
                                        }}
                                    />
                                    {contentBody.isError && (
                                        <div className="pt-2 text-sm font-normal text-red-600">
                                            Please select content to continue
                                        </div>
                                    )}
                                </td>
                                <td className="pt-6 align-top">
                                    <div
                                        className={`px-2 pt-2.5 text-sm font-normal ${
                                            contentBody.isError ? 'text-red-600' : 'text-black'
                                        }`}
                                    >
                                        {contentBody.sectionToDisplay}
                                    </div>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <div className="flex w-[656px] flex-row-reverse border-t border-cs-grayDivider">
                {!isPublishHidden && (
                    <div className="mt-4 flex gap-4">
                        <Button
                            usePadding={false}
                            buttonStyle="text"
                            buttonSize="xs"
                            onClick={handleOnUnpublishClick}
                            isDisabled={isUnpublishedDisabled}
                        >
                            <span>Unpublish</span>
                        </Button>
                        <ButtonNew
                            buttonSize="xs"
                            buttonStyle="secondary"
                            onClick={handleOnResetClick}
                            isDisabled={isResetDisabled}
                        >
                            Reset
                        </ButtonNew>
                        <ButtonNew buttonSize="xs" onClick={handleOnPublishClick} isDisabled={isPublishDisabled}>
                            Publish
                        </ButtonNew>
                    </div>
                )}
            </div>
        </>
    );
}
