/*-----------------------------------------------
    Generated by SwaggerToTS
    Do not customize this file, update the generator
    Copyright C Space 2024
-----------------------------------------------*/
/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from 'axios';
import { appId } from '../azure/auth';
import {
    getAuthToken,
    clientApiScope,
    clientApiUri
} from '../azure/auth';
import {
    CurrentUserViewModel,
    UserViewModel,
    UserViewModelPagedList,
    UserStatus,
    UserLoginType,
    UserType,
    UserPageOrder,
    UserFetchListParams,
    GlobalUserStatus,
    GlobalUserOrderByFilter,
    GlobalUserFetchListParams,
} from './ClientApiTypes';

/**
 * Returns relevant information about the current user.
 * Should also include permissions.
*/
export async function accountCurrentUser(clientId: string, accountId: string): Promise<CurrentUserViewModel> { 
    const token = await getAuthToken([clientApiScope]);
    
    const resp = await axios.get<CurrentUserViewModel>(
        `${clientApiUri}/User/CurrentUserInfo/${clientId}/${accountId}`,
        { headers: { 
            'authorization': `bearer ${token}`,
			'cspace-appId': `${appId}` 
        } });
    return resp.data;
}

/**
 * Returns relevant information about the current user.
 * Should also include permissions.
*/
export async function clientCurrentUser(clientId: string): Promise<CurrentUserViewModel> { 
    const token = await getAuthToken([clientApiScope]);
    
    const resp = await axios.get<CurrentUserViewModel>(
        `${clientApiUri}/User/CurrentUserInfo/${clientId}`,
        { headers: { 
            'authorization': `bearer ${token}`,
			'cspace-appId': `${appId}` 
        } });
    return resp.data;
}

/**
 * Returns relevant information about the current user.
 * Should also include permissions.
*/
export async function systemCurrentUser(): Promise<CurrentUserViewModel> { 
    const token = await getAuthToken([clientApiScope]);
    
    const resp = await axios.get<CurrentUserViewModel>(
        `${clientApiUri}/User/CurrentUserInfo`,
        { headers: { 
            'authorization': `bearer ${token}`,
			'cspace-appId': `${appId}` 
        } });
    return resp.data;
}

/**
 * Updates the current user
*/
export async function currentUserSave(input: CurrentUserViewModel): Promise<UserViewModel> { 
    const token = await getAuthToken([clientApiScope]);
    const resp = await axios.put<UserViewModel>(
        `${clientApiUri}/User/CurrentUserInfo`,
            input,
        { headers: {
            'authorization': `bearer ${token}`,
			'cspace-appId': `${appId}`
        } });
    return resp.data;
}

/**
 * Returns a paged list of users in account
*/
export async function userFetchList(clientId: string, accountId: string, input: UserFetchListParams): Promise<UserViewModelPagedList> { 
    const token = await getAuthToken([clientApiScope]);
    const q: string[] = [];
    if (input.filter) { q.push(`filter=${encodeURIComponent(input.filter)}`); }
    if (input.accountId) { q.push(`accountId=${encodeURIComponent(input.accountId)}`); }
    if (input.userStatus) { q.push(`userStatus=${encodeURIComponent(input.userStatus)}`); }
    if (input.userLoginType) { q.push(`userLoginType=${encodeURIComponent(input.userLoginType)}`); }
    if (input.userType) { q.push(`userType=${encodeURIComponent(input.userType)}`); }
    if (input.tags) { q.push(...input.tags.map(x => `tags=${encodeURIComponent(x)}`)); }
    if (input.roleId) { q.push(`roleId=${encodeURIComponent(input.roleId)}`); }
    if (input.userGroupId) { q.push(`userGroupId=${encodeURIComponent(input.userGroupId)}`); }
    if (input.profileFields) { q.push(...input.profileFields.map(x => `profileFields=${encodeURIComponent(x)}`)); }
    if (input.page) { q.push(`page=${encodeURIComponent(input.page)}`); }
    if (input.pageSize) { q.push(`pageSize=${encodeURIComponent(input.pageSize)}`); }
    if (input.orderBy) { q.push(`orderBy=${encodeURIComponent(input.orderBy)}`); }
    const p = q.length > 0 ? `?${q.join('&')}` : '';
    const resp = await axios.get<UserViewModelPagedList>(
        `${clientApiUri}/User/${clientId}/${accountId}${p}`,
        { headers: { 
            'authorization': `bearer ${token}`,
			'cspace-appId': `${appId}` 
        } });
    return resp.data;
}

/**
 * Returns a paged list of global users in account
*/
export async function globalUserFetchList(accountId: string, input: GlobalUserFetchListParams): Promise<UserViewModelPagedList> { 
    const token = await getAuthToken([clientApiScope]);
    const q: string[] = [];
    if (input.filter) { q.push(`filter=${encodeURIComponent(input.filter)}`); }
    if (input.clientId) { q.push(`clientId=${encodeURIComponent(input.clientId)}`); }
    if (input.accountId) { q.push(`accountId=${encodeURIComponent(input.accountId)}`); }
    if (input.roleId) { q.push(`roleId=${encodeURIComponent(input.roleId)}`); }
    if (input.idList) { q.push(...input.idList.map(x => `idList=${encodeURIComponent(x)}`)); }
    if (input.tags) { q.push(...input.tags.map(x => `tags=${encodeURIComponent(x)}`)); }
    if (input.status) { q.push(...input.status.map(x => `status=${encodeURIComponent(x)}`)); }
    if (input.page) { q.push(`page=${encodeURIComponent(input.page)}`); }
    if (input.pageSize) { q.push(`pageSize=${encodeURIComponent(input.pageSize)}`); }
    if (input.orderBy) { q.push(`orderBy=${encodeURIComponent(input.orderBy)}`); }
    const p = q.length > 0 ? `?${q.join('&')}` : '';
    const resp = await axios.get<UserViewModelPagedList>(
        `${clientApiUri}/User/GlobalUsers/${accountId}${p}`,
        { headers: { 
            'authorization': `bearer ${token}`,
			'cspace-appId': `${appId}` 
        } });
    return resp.data;
}

