import { useState, useEffect } from 'react';

/**
 * A utility function to get the current window's width and height.
 * @returns An object containing the width and height of the window.
 */
function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}

/**
 * A React hook that provides the dimensions of the window and re-renders the component upon window resize.
 * @returns An object containing the window's width, height, and boolean values indicating various screen size breakpoints.
 */
export function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return {
        ...windowDimensions,
        isSm: windowDimensions.width >= 640,
        isMd: windowDimensions.width >= 768,
        isLg: windowDimensions.width >= 1024,
        isXl: windowDimensions.width >= 1280,
        is2Xl: windowDimensions.width >= 1536,
    };
}
