import Spinner from '../Spinner';

export type Props = {
    isLoading: boolean;
    submitText: string;
    loadingText?: string;
};

export default function ButtonTextWithSpinner({ isLoading, loadingText, submitText }: Props) {
    if (isLoading) {
        return (
            <div className="flex items-center gap-2">
                <Spinner />
                {loadingText && <span>{loadingText}</span>}
            </div>
        );
    }

    return <span>{submitText}</span>;
}
