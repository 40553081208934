import {
    AccountDataProviderViewModel,
    AudienceChartInputModel,
    AudienceChartType,
    AudienceChartViewModel,
    CatProfileField,
    ChartDataInputModel,
    ChartInputModel,
    ChartPropViewModel,
} from '../../../common/clientApi/ClientApiTypes';
import {
    chartDisplayTypeFromProvileField,
    extractPropId,
    findChartToRemove,
    findChartsToAdd,
    findProfileField,
    mergeEvent,
    profileFieldMetricsToChartData,
} from './CommunityConfigurationSelectors';
import { SelectionEvent, SelectionEventType } from './CommunityConfigurationTypes';
import {
    audienceChartCreate as createAudChart,
    audienceChartDeleteById as deleteById,
} from '../../../common/clientApi/AudienceChartApi';
import { chartCreate as createChart } from '../../../common/clientApi/ChartApi';
import { catProfileFieldFetchMetricsById as fetchMetricsById } from '../../../common/clientApi/CatalystProfileFieldApi';

let clientId: string;
let accountId: string;
let accountDataProvider: AccountDataProviderViewModel;
let profileFields: CatProfileField[];

// list of current audience charts
let audienceCharts: AudienceChartViewModel[];
let dashboardCharts: AudienceChartViewModel[];

let audienceEvents: SelectionEvent[] = [];
let dashboardEvents: SelectionEvent[] = [];

export function init(_clientId: string, _accountId: string, dp: AccountDataProviderViewModel) {
    clientId = _clientId;
    accountId = _accountId;
    accountDataProvider = dp;
}

export function clear() {
    audienceCharts = [];
    audienceEvents = [];
    dashboardCharts = [];
    dashboardEvents = [];
    profileFields = [];
}

export function isDirty() {
    return dashboardEvents.length > 0 || audienceEvents.length > 0;
}

export function setProfileFields(list: CatProfileField[]) {
    profileFields = list;
}

export function setAudienceCharts(list: AudienceChartViewModel[]) {
    audienceCharts = list;
}

export function setDashboardCharts(list: AudienceChartViewModel[]) {
    dashboardCharts = list;
}

export function addAudienceEvent(eventType: SelectionEventType, profileField: CatProfileField) {
    audienceEvents = mergeEvent(audienceEvents, { eventType, profileField });
}

export function addDashboardEvent(eventType: SelectionEventType, profileField: CatProfileField) {
    dashboardEvents = mergeEvent(dashboardEvents, { eventType, profileField });
}

export async function publish() {
    await removeUnselectedCharts();
    await addSelectedCharts();

    audienceEvents = [];
    dashboardEvents = [];
}

async function removeUnselectedCharts() {
    const removableAudienceCharts = findChartToRemove(audienceCharts, audienceEvents);
    const removableDashboardCharts = findChartToRemove(dashboardCharts, dashboardEvents);
    const removeList = [...removableAudienceCharts, ...removableDashboardCharts];
    await removeList.forEach(async (audChartId) => {
        await deleteById(clientId, accountId, audChartId);
    });
}

async function addSelectedCharts() {
    const enterpiseId = extractPropId(accountDataProvider, 'enterpriseId');
    const communityId = extractPropId(accountDataProvider, 'communityId');

    const addableAudienceCharts = findChartsToAdd(audienceCharts, audienceEvents);
    for (const idx in addableAudienceCharts) {
        const profileFieldId = addableAudienceCharts[idx];
        const chart = await createChartFromProfileField(enterpiseId!, communityId!, profileFieldId);
        if (chart) {
            await createAudienceChart('Audience', chart?.chartId, 1);
        }
    }

    const addableDashboardCharts = findChartsToAdd(dashboardCharts, dashboardEvents);
    for (const idx in addableDashboardCharts) {
        const profileFieldId = addableDashboardCharts[idx];
        const chart = await createChartFromProfileField(enterpiseId!, communityId!, profileFieldId);
        if (chart) {
            await createAudienceChart('Dashboard', chart?.chartId, 1);
        }
    }
}

async function createAudienceChart(chartType: AudienceChartType, chartId: string, ordinal: number) {
    const audChartInput: AudienceChartInputModel = {
        accountDataProviderId: accountDataProvider.accountDataProviderId,
        audienceChartType: chartType,
        chartId: chartId,
        ordinal: ordinal,
    };

    const audChartVM = await createAudChart(clientId, accountId, audChartInput);
    return audChartVM;
}

async function createChartFromProfileField(entId: string, commId: string, profileFieldId: string) {
    const adpId = accountDataProvider.accountDataProviderId;
    const profileField = findProfileField(profileFields, profileFieldId);
    if (profileField == null) {
        return null;
    }

    const metrics = await fetchMetricsById(clientId, accountId, adpId, profileFieldId);

    const chartData: ChartDataInputModel[] = profileFieldMetricsToChartData(metrics);

    const props: ChartPropViewModel[] = [
        {
            name: 'enterpriseId',
            valueId: entId,
            valueType: 'Guid',
        },
        {
            name: 'communityId',
            valueId: commId,
            valueType: 'Guid',
        },
        {
            name: 'profileFieldId',
            valueId: profileFieldId,
            valueType: 'Guid',
        },
    ];

    const chartInput: ChartInputModel = {
        name: profileField.profileLabel,
        chartDisplayType: chartDisplayTypeFromProvileField(profileField),
        chartDataType: 'ProfileField',
        total: metrics.total,
        properties: props,
        data: chartData!,
    };

    const chartVM = await createChart(clientId, accountId, chartInput);

    return chartVM;
}
