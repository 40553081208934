import { ValidationItem } from './useValidation';

export const Required = <TValue, TModel>(name: string, customMessage?: string): ValidationItem<TValue, TModel> => ({
    message: customMessage ?? `${name} field is required.`,
    isInvalid: (v) => !v,
});

export const MaxLength = <TValue, TModel>(max: number, message: string): ValidationItem<TValue, TModel> => ({
    message,
    isInvalid: (v) => !!v && (v as string).length > max,
});

export const MinLength = <TValue, TModel>(min: number, message: string): ValidationItem<TValue, TModel> => ({
    message,
    isInvalid: (v) => !v || (v as string).trim().length < min,
});

/**
 * Validation by regex pattern
 * @param regex Regex pattern
 * @param message Error message
 * @returns Invalid if matches pattern
 */
export const RegexNotMatch = <TValue, TModel>(regex: RegExp, message: string): ValidationItem<TValue, TModel> => ({
    message,
    isInvalid: (v) => regex.test(v as string),
});

export const RegexMatch = <TValue, TModel>(regex: RegExp, message: string): ValidationItem<TValue, TModel> => ({
    message,
    isInvalid: (v) => !regex.test(v as string),
});
