import { FinancialsWidgetCreateModel, FinancialsWidgetViewModel } from '../../../../common/clientApi/ClientApiTypes';
import { CHART_ITEMS_ORDINAL, CURRENCIES } from '../../../../common/consts/enums';
import { areChartItemsEqual, compareByOrdinal } from '../../../../common/utils';

export const isExceeding = (widgetData: FinancialsWidgetCreateModel | FinancialsWidgetViewModel) =>
    widgetData.currency === CURRENCIES.percentages &&
    (widgetData?.chartItems ?? []).reduce((acc, curr) => acc + curr.value, 0) > 100;

export const areGraphWidgetsEqual = (initData?: FinancialsWidgetViewModel, newData?: FinancialsWidgetViewModel) => {
    if (!newData || !initData) {
        return false;
    }
    
    return (
        initData.title === newData.title &&
        initData.type === newData.type &&
        initData.currency === newData.currency &&
        areChartItemsEqual(initData.chartItems, newData.chartItems)
    );
};

export const getGraphChartItems = (widgetData: FinancialsWidgetCreateModel | FinancialsWidgetViewModel) => {
    const widgetChartItems = widgetData?.chartItems ?? [];
    const chartItems = [
        ...widgetChartItems.sort(compareByOrdinal).map((field) => ({
            label: field.name,
            value: Number.isNaN(field.value) ? 0 : field.value,
            ordinal: field.ordinal,
        })),
    ];

    if (widgetData.currency === CURRENCIES.percentages) {
        const percentageSum = chartItems.reduce((acc, curr) => acc + curr.value, 0);
        if (percentageSum < 100) {
            chartItems.push({
                label: 'Unknown',
                value: 100 - percentageSum,
                ordinal: CHART_ITEMS_ORDINAL.UNKNOWN,
            });
        }
    }
    return chartItems;

}