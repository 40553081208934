import CommunityCompositionChartLegend from '../../Audiences/CommunityCompositionChartLegend';
import ErrorDisplay from '../../../common/components/ErrorDisplay';
import HeaderIndicator from '../../../common/components/HeaderIndicator';
import KpiChart from '../../../common/components/KpiChart';
import { AccountDataProviderViewModel, ChartDisplayType } from '../../../common/clientApi/ClientApiTypes';
import { accountId, clientId } from '../../Tenant';
import { audienceChartsCanManage } from '../../../common/clientApi/AccountPermissions';
import { HeaderIndicatorType } from '../../../common/consts/enums';
import { Loading, ToggleInput } from '../../../common/components/form/Form';
import { profileFieldToChartPreview } from './CommunityConfigurationSelectors';
import { useAccountPermission } from '../../../common/userPermission';
import { useQuery } from '@tanstack/react-query';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import { useMemo, useState } from 'react';
import {
    catProfileFieldFetchById as fetchById,
    catProfileFieldFetchMetricsById as fetchMetricsById,
} from '../../../common/clientApi/CatalystProfileFieldApi';
import ToggleSwitch from '../../../common/ToggleSwitch';
import { CommunityCompositionDashboard, CompositionChart } from '../../../common/components/CompositionChartWrappers';

type PreviewParams = {
    dp: AccountDataProviderViewModel;
    audiencesProfileFieldIds: string[];
    dashboardsProfileIds: string[];
    hasChanged: boolean;
    clientId: string;
    accountId: string;
    showN: boolean;
    onToggle: () => void;
};

const dashboardPreview = 'dashboard_preview';
const audiencesPreview = 'audiences_preview';

const previewTitleOptions = [
    { label: 'Audiences Page', value: audiencesPreview },
    { label: 'Dashboard', value: dashboardPreview },
];

export default function CommunityConfigurationPreview(params: PreviewParams) {
    const { dp, audiencesProfileFieldIds, dashboardsProfileIds, showN, onToggle } = params;
    const isReadOnly = !useAccountPermission(audienceChartsCanManage);

    const [previewMode, setPreviewMode] = useState(audiencesPreview);

    return (
        <>
            <div className="mb-4 p-4">
                <div className="flex justify-between">
                    <div className="flex items-center gap-2">
                        <div className="pl-2 pt-2">
                            <ToggleSwitch
                                value={previewMode}
                                options={previewTitleOptions}
                                onChange={(value) => setPreviewMode(value)}
                            />
                        </div>
                        <HeaderIndicator
                            type={params.hasChanged ? HeaderIndicatorType.Draft : HeaderIndicatorType.Published}
                        />
                    </div>
                    <div className="flex gap-2">
                        <div>Display &apos;n &apos; on individual charts </div>
                        <ToggleInput
                            name={''}
                            checked={showN ?? false}
                            readOnly={isReadOnly}
                            onChange={() => onToggle()}
                        />
                    </div>
                </div>
            </div>
            <section className="rounded-sm border border-primary-50 p-4">
                <section>
                    <h4>{dp.name} Composition</h4>
                    <div className="caption">N={dp.memberCount ?? ''}</div>
                    <div className="mb-4 mt-4 w-[100%] border border-primary-50"></div>
                </section>
                <section className="flex flex-wrap justify-center gap-4">
                    {previewMode === audiencesPreview &&
                        audiencesProfileFieldIds.length > 0 &&
                        audiencesProfileFieldIds.map((id) => (
                            <PreviewChart
                                key={id}
                                profileFieldId={id}
                                dataProvider={dp}
                                previewMode={previewMode}
                                showN={showN ?? false}
                            />
                        ))}
                    {previewMode === dashboardPreview && dashboardsProfileIds.length > 0 && (
                        <section className="mt-4 flex grow flex-row flex-wrap justify-around gap-4 ">
                            {dashboardsProfileIds.map((id) => (
                                <PreviewChart
                                    previewMode={previewMode}
                                    dataProvider={dp}
                                    key={id}
                                    profileFieldId={id}
                                    showN={showN ?? false}
                                />
                            ))}
                        </section>
                    )}

                    <div className="my-4 text-center italic">
                        {previewMode === dashboardPreview &&
                            dashboardsProfileIds.length === 0 &&
                            'No chart has been selected for Dashboard Page preview yet'}
                        {previewMode === audiencesPreview &&
                            audiencesProfileFieldIds.length === 0 &&
                            'No chart has been selected for Audiences Page preview yet'}
                    </div>
                </section>
            </section>
        </>
    );
}

type PreviewChartParams = {
    dataProvider: AccountDataProviderViewModel;
    profileFieldId: string;
    previewMode: string;
    showN: boolean;
};

function PreviewChart(params: PreviewChartParams) {
    const { profileFieldId, dataProvider, previewMode, showN } = params;

    const {
        data: profileField,
        isLoading: isProfileFieldLoading,
        error: profileFieldError,
    } = useQuery({
        queryKey: ['ProfileField', dataProvider.accountDataProviderId, profileFieldId, previewMode],
        queryFn: () => fetchById(clientId, accountId, dataProvider.accountDataProviderId, profileFieldId),
    });

    const {
        data: metrics,
        isLoading: isMetricsLoading,
        error: metricsError,
    } = useQuery({
        queryKey: ['ProfileFieldMetrics', dataProvider.accountDataProviderId, profileFieldId, previewMode],
        queryFn: () => fetchMetricsById(clientId, accountId, dataProvider.accountDataProviderId, profileFieldId),
    });

    const { isLg } = useWindowDimensions();
    const chart = useMemo(() => {
        if (!!profileField && !!metrics) {
            return profileFieldToChartPreview(profileField, metrics);
        }
        return null;
    }, [profileField, metrics]);

    if (isProfileFieldLoading || isMetricsLoading) {
        return <Loading isLoading={true} />;
    }

    const getChartWidth = (chartType: ChartDisplayType) => {
        if (chartType === 'Pie') {
            return isLg ? 300 : 225;
        } else {
            return isLg ? 500 : 400;
        }
    };

    if (chart === null) {
        return null;
    }

    if (previewMode === audiencesPreview) {
        return (
            <ErrorDisplay error={profileFieldError || metricsError} errorMessageClassName="italic break-all">
                <CompositionChart>
                    <KpiChart
                        canExport={true}
                        showLegend={false}
                        {...(chart.chartType === 'Pie' && {
                            customLegend: <CommunityCompositionChartLegend data={chart.data ?? []} />,
                        })}
                        chartType={chart.chartType}
                        title={chart.title}
                        count={chart.count}
                        data={chart.data}
                        outsideLabels={true}
                        height={isLg ? 300 : 225}
                        width={getChartWidth(chart.chartType || 'Pie')}
                        showValueInTooltip={showN}
                    />
                </CompositionChart>
            </ErrorDisplay>
        );
    }

    if (previewMode === dashboardPreview) {
        return (
            <ErrorDisplay error={profileFieldError || metricsError} errorMessageClassName="italic break-all">
                <CommunityCompositionDashboard>
                    <KpiChart
                        showLegend={false}
                        {...(chart.chartType === 'Pie' && {
                            customLegend: <CommunityCompositionChartLegend data={chart.data ?? []} />,
                        })}
                        chartType={chart.chartType}
                        title={chart.title}
                        count={chart.count}
                        data={chart.data}
                        outsideLabels={true}
                        height={250}
                        width={chart.chartType === 'Pie' ? 280 : 490}
                        showValueInTooltip={showN}
                    />
                </CommunityCompositionDashboard>
            </ErrorDisplay>
        );
    }

    return null;
}
