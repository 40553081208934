import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import Button from '../../../common/ButtonNew';
import DialogNew from '../../../common/Dialog';
import { TeamMemberCreateModel, UserViewModel } from '../../../common/clientApi/ClientApiTypes';
import LabeledInput from '../../../common/components/form/LabeledInput';
import LabeledSelect from '../../../common/components/form/LabeledSelect';
import * as ErrorStyles from '../../../common/styles/ErrorStyles';
import { FormErrorPanel } from '../../../common/components/form/Form';
import ButtonTextWithSpinner from '../../../common/components/ButtonTextWithSpinner';

type Props = {
    isError?: boolean;
    isLoading: boolean;
    users: UserViewModel[];
    closeHandler: () => void;
    createMember: (user: TeamMemberCreateModel) => void;
};

export default function AddTeamMemberModal({
    closeHandler,
    createMember,
    users,
    isError: isApiError,
    isLoading,
}: Props) {
    const [selectedUser, setSelectedUser] = useState<UserViewModel | undefined>(undefined);
    const [title, setTitle] = useState<string>('');
    const [isError, setIsError] = useState<boolean>(false);

    const addUserHandler = () => {
        if (selectedUser) {
            const memberModel: TeamMemberCreateModel = {
                title: title,
                ordinal: 0,
                userId: selectedUser.userId,
            };
            createMember(memberModel);
        } else {
            setIsError(true);
        }
    };

    const changeHandler = (name: string, value: string) => {
        if (name === 'title') {
            setTitle(value);
        } else {
            const user = users.find((u) => u.userId === value);
            if (user) {
                setSelectedUser(user);
                setTitle('');
            }
        }
    };

    return (
        <div className="flex flex-col justify-between bg-white">
            <DialogNew dialogSize="sm" title="Select user to be added to the team" resetDialog={() => closeHandler()}>
                <div className="bg-white">
                    {isError && (
                        <div className={ErrorStyles.PrimaryErrorDiv}>
                            <ExclamationCircleIcon className="mr-1 w-5" />
                            <span>You need to select a user to add as a team member</span>
                        </div>
                    )}
                    <LabeledSelect name={'user'} label={''} value={selectedUser?.userId} onChange={changeHandler}>
                        {users &&
                            users.map((u, idx) => (
                                <option key={idx} value={u.userId} hidden={idx === 0}>
                                    {u.displayName}
                                </option>
                            ))}
                    </LabeledSelect>
                    {selectedUser && (
                        <LabeledInput name={'title'} label={'Title'} value={title} onChange={changeHandler} />
                    )}
                    {isApiError && (
                        <FormErrorPanel useMargins={false}>
                            An error occurred while creating the team member. Please try again.
                        </FormErrorPanel>
                    )}
                    <div className="mt-5 flex justify-end">
                        <Button buttonStyle="primary" isDisabled={isLoading} onClick={addUserHandler}>
                            <ButtonTextWithSpinner
                                isLoading={isLoading}
                                submitText={'Add User'}
                                loadingText={'Saving...'}
                            />
                        </Button>
                    </div>
                </div>
            </DialogNew>
        </div>
    );
}
