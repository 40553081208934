import { FinancialsWidgetCreateModel, FinancialsWidgetViewModel } from '../../../../common/clientApi/ClientApiTypes';
import { ValidationConfiguration } from '../../../../common/hooks/validation/useValidation';
import { MinLength, Required } from '../../../../common/hooks/validation/validationRules';
import { FIELD_TOKENS } from '../../../../common/consts/enums';

export const VALIDATION_CONSTS = {
    MIN_LENGTH: 3,
    TITLE_MAX_LENGTH: 36,
    FIELD_NAME_MAX_LENGTH: 20,
    FIELD_VALUE_MAX_LENGTH: 8,
};

export const GraphChartValidationConfiguration: ValidationConfiguration<FinancialsWidgetCreateModel | FinancialsWidgetViewModel> = {
    title: [
        MinLength(VALIDATION_CONSTS.MIN_LENGTH, `Minimum ${VALIDATION_CONSTS.MIN_LENGTH} characters`),
        Required('Title'),
    ],
};

export const getGraphChartValidationConfiguration = (model: Record<string, string | number>) => {
    const resultingConfig = {} as ValidationConfiguration<Record<string, string | number>>;
    for (const [k] of Object.entries(model)) {
        if (k.endsWith(FIELD_TOKENS.NAME)) {
            resultingConfig[k] = [
                MinLength(VALIDATION_CONSTS.MIN_LENGTH, `Minimum ${VALIDATION_CONSTS.MIN_LENGTH} characters`),
                Required('Name'),
            ];
        } else if (k.endsWith(FIELD_TOKENS.VALUE)) {
            resultingConfig[k] = [
                {
                    message: 'Required field',
                    isInvalid: (v) => Number.isNaN(v),
                },
            ];
        }
    }
    return resultingConfig;
};
