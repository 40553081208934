import { DropdownOption, EmptySelectValue } from '../../../../../common/Dropdown';
import { marketingContentHeroFetchList } from '../../../../../common/clientApi/MarketingContentSettingApi';
import {
    AudienceMarketingContentTileViewModel,
    MarketingContentSettingType,
} from '../../../../../common/clientApi/ClientApiTypes';
import PartnershipHeroMarketingTile from '../../../../../common/components/PartnershipHeroMarketingTile';
import { MarketingContentSettingViewModelType, Panel } from '../Panel';
import { clientId, accountId } from '../../../../Tenant';

const panelTitle = 'Partnership Page';
const successMessageTitle = 'Hero';
const panelDescription = 'Please select below the marketing content to be displayed on the Partnership page.';
const contentTitle = 'Hero';
const name = 'header';
const settingsType: MarketingContentSettingType = 'PartnershipPageHero';
const contentCount = 1;

type Props = {
    onError: (widgetTitle: string, action: 'publishing' | 'unpublishing') => void;
    setShowUnsavedChangesModal: (isShow: boolean) => void;
};

export default function PartnershipHeroPanel({ onError, setShowUnsavedChangesModal }: Props) {
    const sectionToDisplay = () => `Page Hero`;
    const isShowPreview = (_: AudienceMarketingContentTileViewModel[], selectedItems: DropdownOption[]) =>
        selectedItems.some((x) => x.value !== EmptySelectValue.value);

    const mapItems = (
        data: MarketingContentSettingViewModelType[],
        selectedItems: DropdownOption[]
    ): MarketingContentSettingViewModelType[] => {
        const result: MarketingContentSettingViewModelType[] = [];
        selectedItems.forEach((selectedItem) => {
            const item = data.find((dataItem) => selectedItem.value === dataItem.tileId);

            if (item) {
                result.push(item);
            }
        });

        return result;
    };

    const validateContentItems = (items: DropdownOption[]) => {
        const isPublishDisabled = items.every((x) => x.value === EmptySelectValue.value);
        const errorList = Array.from(Array(contentCount), () => isPublishDisabled);

        return { isPublishDisabled, errorList };
    };

    const execFetchAll = () => {
        return marketingContentHeroFetchList(clientId, accountId);
    };

    const previewContent = (items: AudienceMarketingContentTileViewModel[]) => (
        <div className="h-[550px] w-full">
            {items.map((x) => (
                <PartnershipHeroMarketingTile
                    key={x.tileId}
                    backgroundMediaId={x.tileMediaId}
                    description={x.description}
                    linkUrl={x.linkUrl}
                    linkTitle={x.linkTitle}
                    title={x.title}
                />
            ))}
        </div>
    );

    return (
        <Panel
            contentCount={contentCount}
            name={name}
            settingsType={settingsType}
            contentTitle={contentTitle}
            panelDescription={panelDescription}
            panelTitle={panelTitle}
            successMessageTitle={successMessageTitle}
            validateContentItems={validateContentItems}
            sectionToDisplay={sectionToDisplay}
            isShowPreview={isShowPreview}
            previewContent={previewContent}
            mapItems={mapItems}
            onError={onError}
            fetchAllList={execFetchAll}
            setShowUnsavedChangesModal={setShowUnsavedChangesModal}
        />
    );
}
