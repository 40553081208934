import React from 'react';

type Props = {
    maxCount: number;
};

function SettingsFinancialsMaxWidgets({ maxCount }: Props) {
    return (
        <div className="flex h-[300px] flex-col items-center justify-center rounded-sm bg-white">
            <h3 className="mb-4">{`You have reached the max number (${maxCount}) of widgets`}</h3>
            <p className="max-w-[20rem] text-center">
                To add a different widget, you must first remove one from the list above.
            </p>
        </div>
    );
}

export default SettingsFinancialsMaxWidgets;
