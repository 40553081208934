import { PencilIcon, TrashIcon } from '@heroicons/react/24/solid';
import { useMutation } from '@tanstack/react-query';
import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import Button from '../../../common/ButtonNew';
import ErrorDialog from '../../../common/ErrorDialog';
import { accountTeamMemberCanEdit } from '../../../common/clientApi/AccountPermissions';
import { TeamMemberCreateModel, TeamMemberViewModel } from '../../../common/clientApi/ClientApiTypes';
import { teamMemberDeleteById, teamMemberSave } from '../../../common/clientApi/TeamMemberApi';
import LoadingAnimation from '../../../common/components/LoadingAnimation';
import { MediaImage } from '../../../common/components/MediaImage';
import LabeledInput from '../../../common/components/form/LabeledInput';
import { EMPTY_ID } from '../../../common/consts/defaults';
import * as MediaUrls from '../../../common/mediaApi/MediaUrlBuilder';
import { deleteMemberHeroImage, uploadMedia } from '../../../common/mediaApi/UploadApi';
import { useAccountPermission } from '../../../common/userPermission';
import { TeamMemberTileViewModel } from './types';

type Props = {
    member: TeamMemberTileViewModel;
    clientId: string;
    accountId: string;
    refetchTeamMembersHandler: () => void;
};

export default function TeamMemberTile({ member, refetchTeamMembersHandler, clientId, accountId }: Props) {
    const maxFileSize = 10 * 1024 * 1024;
    const acceptedMediaTypes = { 'image/png': [], 'image/jpeg': [] };
    const [editMode, setEditMode] = useState<boolean>(false);
    const [title, setTitle] = useState<string>(member.title ?? '');
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const saveTeamMember = async (member: TeamMemberViewModel) => {
        if (member && member.userId) {
            const input: TeamMemberCreateModel = {
                title: member.title,
                mediaId: member.mediaId,
                ordinal: member.ordinal,
                userId: member.userId as string,
            };
            await teamMemberSave(clientId, accountId, member.teamMemberId, input);
        }
    };

    const deleteTeamMember = async (memberId: string) => {
        if (memberId) {
            await teamMemberDeleteById(clientId, accountId, memberId);
        }
    };

    const { mutate: deleteTeamMemberMutate, isLoading: isTeamMemberDeleting } = useMutation({
        mutationFn: (memberId: string) => deleteTeamMember(memberId),
        onSuccess: () => refetchTeamMembersHandler(),
        onError: () => setErrorMessage('An error occurred while deleting the team member. Please try again.'),
    });

    const { mutate: updateTeamMemberMutate, isLoading: isTeamMemberUpdating } = useMutation({
        mutationFn: (member: TeamMemberViewModel) => saveTeamMember(member),
        onSuccess: () => {
            refetchTeamMembersHandler();
            setEditMode(false);
        },
        onError: () => setErrorMessage('An error occurred while saving changes to the team member. Please try again.'),
    });

    const updateMemberPhoto = async (file: File) => {
        const uploadUrl = MediaUrls.teamMemberHeroUpload(clientId, accountId);
        const formData = new FormData();
        formData.append('file', file);
        return await uploadMedia(uploadUrl, formData);
    };

    const { mutate: memberPhotoUpdate, isLoading: isPhotoUpdating } = useMutation(updateMemberPhoto, {
        onSuccess: (data) => {
            updateTeamMemberMutate({ ...member, mediaId: data.mediaId });
        },
        onError: () => setErrorMessage('An error occurred while saving changes to the team member. Please try again.'),
    });

    const deleteMemberPhoto = async () => {
        if (member.mediaId && clientId && accountId) {
            await deleteMemberHeroImage(clientId, accountId, member.mediaId);
        }
    };

    const { mutate: memberPhotoDelete, isLoading: isPhotoDeleting } = useMutation(deleteMemberPhoto, {
        onSuccess: () => {
            updateTeamMemberMutate({ ...member, mediaId: null });
        },
        onError: () => setErrorMessage('An error occurred while saving changes to the team member. Please try again.'),
    });

    const editHandler = () => {
        setEditMode(true);
    };

    const saveHandler = async () => {
        const input = { ...member, title: title };
        updateTeamMemberMutate(input);
    };

    const deleteHandler = () => {
        if (member.teamMemberId) {
            deleteTeamMemberMutate(member.teamMemberId);
        }
    };

    const mediaId = member?.mediaId ?? EMPTY_ID;
    const getProfilePicture = () => {
        return MediaUrls.teamMemberHeroCacheBuster(clientId, accountId, mediaId);
    };

    const changeHandler = (_: string, value: string) => {
        setTitle(value);
    };

    const cancelHandler = () => {
        setTitle(member.title ?? title);
        setEditMode(false);
    };

    const onDrop = useCallback(async (acceptedFiles: Array<File>) => {
        const file = acceptedFiles[0];
        if (file) {
            memberPhotoUpdate(file);
        }
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        ...(acceptedMediaTypes && { accept: acceptedMediaTypes }),
        maxSize: maxFileSize,
        maxFiles: 1,
        onDrop: onDrop,
    });

    const canEditTeamMember = useAccountPermission(accountTeamMemberCanEdit);

    const isUpdating = isPhotoUpdating || isPhotoDeleting || isTeamMemberUpdating || isTeamMemberDeleting;

    if (isUpdating) {
        return (
            <div className="flex items-center justify-center border-b-[1px] bg-white px-10 py-5">
                <LoadingAnimation />
            </div>
        );
    } else {
        return (
            <>
                {errorMessage && (
                    <ErrorDialog onClose={() => setErrorMessage(null)}>
                        <>{errorMessage}</>
                    </ErrorDialog>
                )}
                <div className="flex border-b-[1px] bg-white p-2 px-5">
                    {!editMode && (
                        <>
                            <div className="px-5 py-2">
                                <MediaImage
                                    key={mediaId}
                                    className="h-10 w-10 rounded-full"
                                    alt="Profile Image"
                                    urlFunc={getProfilePicture}
                                />
                            </div>
                            <div className="flex-grow">
                                <h4>{member.displayName}</h4>
                                <span>{member.title}</span>
                            </div>
                            <div className="mr-5 flex p-2">
                                {canEditTeamMember && (
                                    <div className="flex flex-row">
                                        <PencilIcon
                                            onClick={editHandler}
                                            className="flex-right mr-5 w-5 cursor-pointer text-blue-500 hover:text-blue-900"
                                        />
                                        <TrashIcon
                                            onClick={deleteHandler}
                                            className="flex-right w-5 cursor-pointer text-blue-500 hover:text-blue-900"
                                        />
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                    {editMode && (
                        <div className="flex flex-grow py-5">
                            <div className="mr-10 flex w-2/5 flex-col items-center justify-evenly">
                                <div
                                    {...getRootProps({
                                        className: `dropzone flex flex-col justify-evenly items-center`,
                                    })}
                                >
                                    <MediaImage
                                        key={mediaId}
                                        className="h-[10rem] w-[10rem] rounded-full"
                                        alt="Profile Image"
                                        urlFunc={getProfilePicture}
                                    />
                                    <input {...getInputProps()} />
                                    <Button className="mt-5" onClick={() => {}}>
                                        Upload Photo
                                    </Button>
                                </div>
                                <Button className="mt-5" buttonStyle="text" onClick={() => memberPhotoDelete()}>
                                    Remove Photo
                                </Button>
                            </div>
                            <div className="mr-10 w-3/5">
                                <h4>Edit Profile</h4>
                                <LabeledInput
                                    label="Display Name"
                                    name="name"
                                    readonly={true}
                                    value={member.displayName ?? ''}
                                />
                                <LabeledInput label="Title" name="title" value={title} onChange={changeHandler} />
                                <div className="my-5">
                                    <Button onClick={saveHandler}>Save</Button>
                                    <Button buttonStyle="text" onClick={cancelHandler}>
                                        Cancel
                                    </Button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </>
        );
    }
}
