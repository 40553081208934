import { CatProfileField } from '../../../common/clientApi/ClientApiTypes';

export enum SelectionEventType {
    add,
    remove,
}

export type SelectionEvent = {
    eventType: SelectionEventType;
    profileField: CatProfileField;
};

export type InitialFieldValues = {
    audienceInitialValues: string[];
    dashboardInitialValues: string[];
};
