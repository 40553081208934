import React, { useCallback } from 'react';
import { CHART_COLORS } from '../../../../common/components/KpiChart';
import { commaSeparatedNumber } from '../../../../common/utils';

type Props = {
    currency: string;
    data: Array<{ label: string; value: number; ordinal: number }>;
};

export default function GraphChartWidgetLegend({ currency, data }: Props) {
    const appendUnit = useCallback((label: string, currency: string) => {
        switch (currency) {
            case 'USD':
                return `$${label}`;
            case 'EUR':
                return `€${label}`;
            case 'GBP':
                return `£${label}`;
            case 'Percentages':
                return `${label}%`;
            default:
                return label;
        }
    }, []);
    const getBorderClasses = useCallback((index: number, length: number) => {
        const resultingClasses = [];
        if (!(index % 2) && length > 1) {
            resultingClasses.push('border-r');
        }
        if ((!(index % 2) && length - index > 2) || (index % 2 && length - index > 1)) {
            resultingClasses.push('border-b');
        }
        return resultingClasses.join(' ');
    }, []);

    return (
        <div className="order-last mr-16 grid flex-1 grid-cols-2 min-[1450px]:order-first min-[1450px]:max-w-xl">
            {data.map(({ label, value }, index) => (
                <div
                    key={index}
                    className={`flex flex-col justify-between gap-3 px-5 py-4 ${
                        index % 2 ? 'items-end' : 'items-start'
                    } ${getBorderClasses(index, data.length)}`}
                >
                    <div className="line-clamp-1 break-all font-haas text-base font-medium">{label}</div>
                    <div style={{ color: CHART_COLORS[index] }} className="font-haas text-lg font-medium">
                        {appendUnit(commaSeparatedNumber(value), currency)}
                    </div>
                </div>
            ))}
        </div>
    );
}
