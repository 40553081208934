import { AccountDataProviderViewModel, AudienceChartViewModel } from "../../common/clientApi/ClientApiTypes";


export type DataProviderWithChartCount = AccountDataProviderViewModel & {
    chartCount: number;
}


export const getDataProviderWithChartCounts = (dataProviderData: AccountDataProviderViewModel[], chartData: AudienceChartViewModel[]) => {
    
    const chartDict = new Map<string, number>();

    chartData.forEach((chart) => {
        const dpId = chart.accountDataProviderId;
        if (dpId) {
            const count = chartDict.get(dpId) ?? 0;
            chartDict.set(dpId, count + 1);
        }
    });
    
    const dpsWithCharts = dataProviderData.map((d) => {
        return {
            ...d,
            chartCount: chartDict.get(d.accountDataProviderId) ?? 0
        };
    });

    return dpsWithCharts;
}