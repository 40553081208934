import ProjectBudgetWidgetLegendEntry from './ProjectBudgetWidgetLegendEntry';
import { BudgetChartItemType } from './ProjectBudgetWidgetUtils';

type Props = {
    currency: string;
    data: BudgetChartItemType[];
};

export default function ProjectBudgetWidgetLegend({ currency, data }: Props) {
    return (
        <div className="order-last mr-16 flex max-w-xl flex-1 flex-col xl:order-first">
            {data.map(({ label, value, ordinal, color }, index) => (
                <ProjectBudgetWidgetLegendEntry
                    key={index}
                    currency={currency}
                    label={label}
                    ordinal={ordinal}
                    value={value}
                    color={color}
                />
            ))}
        </div>
    );
}
