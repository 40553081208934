import { AudienceChartViewModel } from '../../common/clientApi/ClientApiTypes';
import { CHART_COLORS, KpiChartData } from '../../common/components/KpiChart';
import { ChartData } from './ChartModels';

export function toChartData(m: AudienceChartViewModel): ChartData {
    const data = m?.chart?.data ?? [];
    const dList = data.map((d, index) => {
        return {
            label: d.name,
            value: d.value,
            color: m?.chart?.chartDisplayType == 'Bar' ? CHART_COLORS[0] : CHART_COLORS[index],
        };
    });

    const chart: ChartData = {
        title: m.chart.name,
        count: m.chart.total ?? undefined,
        chartType: m.chart.chartDisplayType,
        data: dList,
    };

    return chart;
}

export function sortKpiChartData(data: KpiChartData[]): KpiChartData[] {
    const sortedData = data.sort((chart1, chart2) => {
        if (chart1.value > chart2.value) {
            return -1;
        }

        if (chart1.value < chart2.value) {
            return 1;
        }

        return 0;
    });

    return sortedData;
}
