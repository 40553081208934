import { audienceMarketingContentFetchList } from '../../../../../common/clientApi/MarketingContentSettingApi';
import Carousel, { CarouselItem } from '../../../../../common/components/Carousel';
import { MarketingContentSettingType } from '../../../../../common/clientApi/ClientApiTypes';
import { MarketingContentSettingViewModelType, Panel } from '../Panel';
import { DropdownOption, EmptySelectValue } from '../../../../../common/Dropdown';
import { clientId, accountId } from '../../../../Tenant';

const panelTitle = 'Audiences Page';
const successMessageTitle = 'Hero Carousel';
const panelDescription = 'Please select below the marketing content to be displayed on the Audiences page.';
const contentTitle = 'Hero Carousel';
const name = 'audiences';
const settingsType: MarketingContentSettingType = 'AudiencesPage';
const contentCount = 3;

type Props = {
    onError: (widgetTitle: string, action: 'publishing' | 'unpublishing') => void;
    setShowUnsavedChangesModal: (isShow: boolean) => void;
};

export default function AudiencesPanel({ onError, setShowUnsavedChangesModal }: Props) {
    const sectionToDisplay = (index: number) => `Case Study ${index + 1}`;
    const isShowPreview = (carouselItems: CarouselItem[]) => carouselItems.length > 0;

    const mapItems = (
        data: MarketingContentSettingViewModelType[],
        selectedItems: DropdownOption[]
    ): CarouselItem[] => {
        const result: CarouselItem[] = [];
        selectedItems.forEach((selectedItem) => {
            const item = data.find((dataItem) => selectedItem.value === dataItem.tileId);

            if (item) {
                result.push({
                    ...item,
                    backgroundMediaId: item.tileMediaId,
                });
            }
        });

        return result;
    };

    const validateContentItems = (items: DropdownOption[]) => {
        const isPublishDisabled = items.every((x) => x.value === EmptySelectValue.value);
        const errorList = Array.from(Array(contentCount), () => isPublishDisabled);

        return { isPublishDisabled, errorList };
    };

    const execFetchAll = () => {
        return audienceMarketingContentFetchList(clientId, accountId);
    }

    const previewContent = (carouselItems: CarouselItem[]) => (
        <div className="rounded-sm border border-primary-50 py-6 pl-6 pr-[14px]">
            <Carousel items={carouselItems} />
        </div>
    );

    return (
        <Panel
            contentCount={contentCount}
            name={name}
            settingsType={settingsType}
            contentTitle={contentTitle}
            panelDescription={panelDescription}
            panelTitle={panelTitle}
            successMessageTitle={successMessageTitle}
            validateContentItems={validateContentItems}
            sectionToDisplay={sectionToDisplay}
            isShowPreview={isShowPreview}
            previewContent={previewContent}
            mapItems={mapItems}
            onError={onError}
            fetchAllList={execFetchAll}
            setShowUnsavedChangesModal={setShowUnsavedChangesModal}
        />
    );
}
