import { DropdownOption, EmptySelectValue } from '../../../../../common/Dropdown';
import TileCard from '../../../../../common/components/TileCard';
import { marketingContentProjectFetchList } from '../../../../../common/clientApi/MarketingContentSettingApi';
import {
    AudienceMarketingContentTileViewModel,
    MarketingContentSettingType,
} from '../../../../../common/clientApi/ClientApiTypes';
import { MarketingContentSettingViewModelType, Panel } from '../Panel';
import { clientId, accountId } from '../../../../Tenant';

const panelTitle = 'Projects Page';
const settingsType: MarketingContentSettingType = 'ProjectsPage';
const successMessageTitle = 'Stay savvy with our latest thinking';
const name = 'project';
const panelDescription = 'Please select below the marketing content to be displayed on the Projects page.';
const contentTitle = 'Our Latest Thinking';
const contentCount = 2;

type Props = {
    onError: (widgetTitle: string, action: 'publishing' | 'unpublishing') => void;
    setShowUnsavedChangesModal: (isShow: boolean) => void;
};

export default function ProjectsPanel({ onError, setShowUnsavedChangesModal }: Props) {
    const sectionToDisplay = (index: number) => (index === 0 ? 'Left' : 'Right');

    const isShowPreview = (_: AudienceMarketingContentTileViewModel[], selectedItems: DropdownOption[]) =>
        selectedItems.some((x) => x.value !== EmptySelectValue.value);

    const mapItems = (
        data: MarketingContentSettingViewModelType[],
        selectedItems: DropdownOption[]
    ): MarketingContentSettingViewModelType[] => {
        const result: MarketingContentSettingViewModelType[] = [];
        selectedItems.forEach((selectedItem) => {
            const item = data.find((dataItem) => selectedItem.value === dataItem.tileId);

            if (item) {
                result.push(item);
            }
        });

        return result;
    };

    const validateContentItems = (items: DropdownOption[]) => {
        const isPublishDisabled = items.every((x) => x.value === EmptySelectValue.value);
        const errorList = Array.from(Array(contentCount), () => isPublishDisabled);

        return { isPublishDisabled, errorList };
    };

    const execFetchAll = () => {
        return marketingContentProjectFetchList(clientId, accountId);
    }    

    const previewContent = (items: AudienceMarketingContentTileViewModel[]) => (
        <div className="border-t border-primary-50 pt-6">
            <div className="h-full bg-background p-10">
                <h2>Stay savvy with our latest thinking</h2>
                <div className="flex gap-8 pt-8">
                    {items.map((x) =>
                        x ? (
                            <TileCard
                                key={x.tileId}
                                title={x.title}
                                description={x.description}
                                linkTitle={x.linkTitle}
                                linkUrl={x.linkUrl}
                                tileMediaId={x.tileMediaId}
                                asSingle={items.length === 1}
                            />
                        ) : (
                            <></>
                        )
                    )}
                </div>
            </div>
        </div>
    );

    return (
        <Panel
            contentCount={contentCount}
            name={name}
            settingsType={settingsType}
            contentTitle={contentTitle}
            panelDescription={panelDescription}
            panelTitle={panelTitle}
            successMessageTitle={successMessageTitle}
            sectionToDisplay={sectionToDisplay}
            validateContentItems={validateContentItems}
            isShowPreview={isShowPreview}
            previewContent={previewContent}
            mapItems={mapItems}
            onError={onError}
            fetchAllList={execFetchAll}
            setShowUnsavedChangesModal={setShowUnsavedChangesModal}
        />
    );
}
