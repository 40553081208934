type Props = {
    value: string;
    isReadOnly?: boolean;
    options: Array<{ label: string; value: string }>;
    onChange: (value: string) => void;
};

export default function ToggleSwitch({ value, options, isReadOnly, onChange }: Props) {
    return (
        <div
            className={`flex items-center divide-x overflow-hidden rounded-md border ${
                isReadOnly ? 'divide-inactive border-inactive' : 'divide-primary-100 border-primary-100'
            }`}
        >
            {options.map((option, index) => (
                <button
                    key={index}
                    disabled={isReadOnly}
                    className={`flex-1 px-4 py-2 text-sm transition-colors ${
                        value === option.value ? 'bg-white font-bold font-atkinson' : 'bg-background font-normal font-atkinson'
                    }`}
                    {...({ onClick: () => onChange(option.value) })}
                >
                    <span className="text-nowrap truncate">{option.label}</span>
                </button>
            ))}
        </div>
    );
}