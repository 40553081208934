import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useMemo, useState } from 'react';
import UnsavedChangesModal from '../../../common/UnsavedChangesModal';
import {
    accountFinancialsWidgetCanEdit,
    accountFinancialsWidgetCanView,
} from '../../../common/clientApi/AccountPermissions';
import {
    AccountDetailModel,
    FinancialsWidgetCreateModel,
    FinancialsWidgetCurrency,
    FinancialsWidgetStatus,
    FinancialsWidgetType,
    FinancialsWidgetViewModel,
} from '../../../common/clientApi/ClientApiTypes';
import {
    financialsWidgetCreate,
    financialsWidgetDeleteById,
    financialsWidgetFetchList,
    financialsWidgetSave,
} from '../../../common/clientApi/FinancialsWidgetApi';
import ErrorDisplay from '../../../common/components/ErrorDisplay';
import LoadingAnimation from '../../../common/components/LoadingAnimation';
import { successToast } from '../../../common/components/toastNotification';
import { MAX_WIDGET_NUM } from '../../../common/consts/settings';
import { CHART_ITEMS_ORDINAL } from '../../../common/consts/enums';
import { useUnsavedChanges } from '../../../common/hooks/useUnsavedChanges';
import { WithAccountPermission, WithAnyAccountPermissions, useAccountPermission } from '../../../common/userPermission';
import { compareByOrdinal, getNextOrdinalNumber } from '../../../common/utils';
import GraphChartWidget from './GraphChartWidget/GraphChartWidgetContainer';
import { areGraphWidgetsEqual } from './GraphChartWidget/GraphChartWidgetUtils';
import ProjectBudgetWidget from './ProjectBudgetWidget/ProjectBudgetWidgetContainer';
import { areBudgetWidgetsEqual } from './ProjectBudgetWidget/ProjectBudgetWidgetUtils';
import SettingsFinancialsAddWidget from './SettingsFinancialsAddWidget';
import SettingsFinancialsMaxWidgets from './SettingsFinancialsMaxWidgets';
import TextWidget from './TextWidget/TextWidgetContainer';
import { areTextWidgetsEqual } from './TextWidget/TextWidgetValidationUtils';
import ErrorDialog from '../../../common/ErrorDialog';

type ClientInfo = AccountDetailModel | undefined;

export default function SettingsFinancialsContent() {
    const [publishedWidgets, setPublishedWidgets] = useState<FinancialsWidgetViewModel[]>([]);
    const [newWidgets, setNewWidgets] = useState<FinancialsWidgetCreateModel[]>([]);
    const queryClient = useQueryClient();
    const [showUnsavedChangesModal, onShowUnsavedChangesModal] = useState<boolean>(false);
    const { showPrompt, confirmNavigation, cancelNavigation } = useUnsavedChanges(showUnsavedChangesModal);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const isReadOnly = !useAccountPermission(accountFinancialsWidgetCanEdit);

    const clientInfo: ClientInfo = queryClient.getQueryData(['clientInfo']);

    const {
        data: widgetsData,
        isSuccess: isLoadingSuccess,
        isLoading: isLoadingWidget,
        error: widgetError,
    } = useQuery<FinancialsWidgetViewModel[], Error>({
        queryKey: ['widgetsInfo', clientInfo!.clientId, clientInfo!.accountId],
        queryFn: () => financialsWidgetFetchList(clientInfo!.clientId, clientInfo!.accountId),
        staleTime: 10000,
        enabled: !!clientInfo,
    });

    useEffect(() => {
        if (isLoadingSuccess) {
            const publishedIds = new Set(widgetsData?.map((widget) => widget.ordinal) ?? []);
            setPublishedWidgets((prevWidgets) => [
                ...prevWidgets,
                ...widgetsData.filter(
                    (widget) => !prevWidgets.some((pw) => pw.financialsWidgetId === widget.financialsWidgetId)
                ),
            ]);
            setNewWidgets((prevWidgets) => prevWidgets.filter((newWidget) => !publishedIds.has(newWidget.ordinal)));
        }
    }, [widgetsData, isLoadingSuccess, setPublishedWidgets, setNewWidgets]);

    useEffect(() => {
        if (!isLoadingSuccess) {
            return;
        }

        if (newWidgets.length > 0) {
            return onShowUnsavedChangesModal(true);
        }

        for (const publishedWidget of publishedWidgets) {
            const initWidgetData = widgetsData.find(
                (item) => item.financialsWidgetId === publishedWidget.financialsWidgetId
            ) as FinancialsWidgetViewModel;

            if (!initWidgetData) {
                return;
            }

            if (publishedWidget.type === 'Text') {
                if (!areTextWidgetsEqual(initWidgetData, publishedWidget)) {
                    return onShowUnsavedChangesModal(true);
                }
            } else if (publishedWidget.type === 'PieChart' || publishedWidget.type === 'BarChart') {
                if (!areGraphWidgetsEqual(initWidgetData, publishedWidget)) {
                    return onShowUnsavedChangesModal(true);
                }
            } else if (publishedWidget.type === 'ProjectBudgetChart') {
                if (!areBudgetWidgetsEqual(initWidgetData, publishedWidget)) {
                    return onShowUnsavedChangesModal(true);
                }
            }
        }

        return onShowUnsavedChangesModal(false);
    }, [isLoadingSuccess, newWidgets, onShowUnsavedChangesModal, publishedWidgets, widgetsData]);

    const createWidget = useMutation({
        mutationFn: (data: FinancialsWidgetCreateModel) =>
            clientInfo
                ? financialsWidgetCreate(clientInfo.clientId, clientInfo.accountId, data)
                : Promise.resolve(undefined),
        onSuccess: (data: FinancialsWidgetViewModel | undefined) => {
            successToast(`${data?.title ?? 'Widget'} has been published`, 'RIGHT');
            queryClient.invalidateQueries({
                queryKey: ['widgetsInfo', clientInfo?.clientId, clientInfo?.accountId],
            });
        },
        onError: (_, data) => {
            setErrorMessage(`There was an error publishing the ${data.title} widget. Please try again.`);
        },
    });

    const updateProjectWidget = useMutation({
        mutationFn: (data: FinancialsWidgetViewModel) =>
            clientInfo
                ? financialsWidgetSave(clientInfo.clientId, clientInfo.accountId, data.financialsWidgetId, data)
                : Promise.resolve(undefined),
        onSuccess: (data: FinancialsWidgetViewModel | undefined) => {
            successToast(`${data?.title ?? 'Widget'} has been published`, 'RIGHT');
            queryClient.invalidateQueries({
                queryKey: ['widgetsInfo', clientInfo?.clientId, clientInfo?.accountId],
            });
        },
        onError: (_, data) => {
            setErrorMessage(`There was an error publishing the ${data.title} widget. Please try again.`);
        },
    });

    const deleteProjectWidget = useMutation({
        mutationFn: (widgetId: string) =>
            clientInfo
                ? financialsWidgetDeleteById(clientInfo.clientId, clientInfo.accountId, widgetId)
                : Promise.resolve(undefined),
        onSuccess: (_, widgetId) => {
            setPublishedWidgets((prevWidgets) =>
                prevWidgets.filter((prevWidget) => prevWidget.financialsWidgetId !== widgetId)
            );

            queryClient.invalidateQueries({
                queryKey: ['widgetsInfo', clientInfo?.clientId, clientInfo?.accountId],
            });
        },
        onError: (_, widgetId) => {
            const widget = widgetsData?.find((w) => w.financialsWidgetId === widgetId);
            const title = widget ? ` ${widget.title}` : '';
            setErrorMessage(`There was an error deleting the${title} widget.`);
        },
    });

    const projectBudgetInitialState = useMemo<FinancialsWidgetCreateModel>(
        () => ({
            status: 'Active' as FinancialsWidgetStatus,
            type: 'ProjectBudgetChart' as FinancialsWidgetType,
            title: 'Work/Project Budget Widget',
            startDate: null,
            stopDate: null,
            currency: 'USD' as FinancialsWidgetCurrency,
            ordinal: getNextOrdinalNumber([...(Array.isArray(widgetsData) ? widgetsData : []), ...newWidgets]),
            textItemValue: null,
            chartItems: [
                { name: 'Total', value: 0, ordinal: CHART_ITEMS_ORDINAL.TOTAL },
                { name: 'Remaining', value: 0, ordinal: CHART_ITEMS_ORDINAL.REMAINING },
                { name: 'Amount Planned', value: 0, ordinal: 1 },
                { name: 'Amount Used', value: 0, ordinal: 2 },
            ],
        }),
        [widgetsData, newWidgets]
    );

    const graphChartInitialState = useMemo<FinancialsWidgetCreateModel>(
        () => ({
            status: 'Active' as FinancialsWidgetStatus,
            type: 'PieChart' as FinancialsWidgetType,
            title: 'Graph or Chart',
            currency: 'USD' as FinancialsWidgetCurrency,
            textItemValue: null,
            ordinal: getNextOrdinalNumber([...(Array.isArray(widgetsData) ? widgetsData : []), ...newWidgets]),
            chartItems: [
                { name: '', value: 0, ordinal: 0 },
                { name: '', value: 0, ordinal: 1 },
                { name: '', value: 0, ordinal: 2 },
                { name: '', value: 0, ordinal: 3 },
            ],
        }),
        [widgetsData, newWidgets]
    );

    const textWidgetInitialState = useMemo<FinancialsWidgetCreateModel>(
        () => ({
            status: 'Active' as FinancialsWidgetStatus,
            type: 'Text' as FinancialsWidgetType,
            title: 'Text Title',
            ordinal: getNextOrdinalNumber([...(Array.isArray(widgetsData) ? widgetsData : []), ...newWidgets]),
            textItemValue: '',
            currency: 'USD'
        }),
        [widgetsData, newWidgets]
    );

    const addButtons = useMemo(
        () => [
            {
                title: 'Add Another Work/Project Budget',
                onClick: () => setNewWidgets((prevState) => [...prevState, projectBudgetInitialState]),
                label: 'Create Budget',
            },
            {
                title: 'Add Text',
                onClick: () => setNewWidgets((prevState) => [...prevState, textWidgetInitialState]),
                label: 'Create Content',
            },
            {
                title: 'Add Graph or Chart',
                onClick: () => setNewWidgets((prevState) => [...prevState, graphChartInitialState]),
                label: 'Create Content',
            },
        ],
        [projectBudgetInitialState, graphChartInitialState, setNewWidgets]
    );

    const isDataPresent = useMemo(
        () => (Array.isArray(widgetsData) && widgetsData.length > 0) || newWidgets.length > 0,
        [widgetsData, newWidgets]
    );

    const newWidgetChangeHandler = useCallback(
        (data: FinancialsWidgetCreateModel, index: number) =>
            setNewWidgets((prevState) => prevState.map((item, stateIndex) => (stateIndex === index ? data : item))),
        [setNewWidgets]
    );

    const newWidgetRemoveHandler = useCallback(
        (index: number) =>
            setNewWidgets((prevState) => {
                const newState = [...prevState];
                newState.splice(index, 1);
                return newState;
            }),
        [setNewWidgets]
    );

    const newWidgetResetHandler = useCallback(
        (index: number, ordinal: number, initState: FinancialsWidgetCreateModel) => {
            setNewWidgets((prevState) =>
                prevState.map((item, stateIndex) =>
                    stateIndex === index
                        ? {
                              ...initState,
                              ordinal: ordinal,
                          }
                        : item
                )
            );
        },
        [setNewWidgets]
    );

    const publishedWidgetChangeHandler = useCallback(
        (data: FinancialsWidgetViewModel, widgetId: string) => {
            setPublishedWidgets((prevState) =>
                prevState.map((item) => (item.financialsWidgetId === widgetId ? data : item))
            );
        },
        [setPublishedWidgets]
    );

    const publishedWidgetResetHandler = useCallback(
        (id: string) => {
            const initState = widgetsData!.find((item) => item.financialsWidgetId === id);
            setPublishedWidgets((prevState) =>
                prevState.map((item) =>
                    item.financialsWidgetId === initState?.financialsWidgetId
                        ? {
                              ...initState,
                          }
                        : item
                )
            );
        },
        [widgetsData, setPublishedWidgets]
    );

    const publishedWidgetDeleteHandler = useCallback(
        (id: string) => {
            deleteProjectWidget.mutate(id);
        },
        [deleteProjectWidget, setPublishedWidgets]
    );

    const addNewDisabled = newWidgets.length + publishedWidgets.length >= MAX_WIDGET_NUM;

    if (isLoadingWidget) {
        return <LoadingAnimation />;
    }

    return (
        <WithAnyAccountPermissions
            permissions={[accountFinancialsWidgetCanEdit, accountFinancialsWidgetCanView]}
            permissionDeniedElem={
                <span className="mx-16">You don&apos;t have permission to access this resource.</span>
            }
        >
            <ErrorDisplay error={widgetError} errorMessageClassName="mx-16">
                {errorMessage && (
                    <ErrorDialog onClose={() => setErrorMessage(null)}>
                        <>{errorMessage}</>
                    </ErrorDialog>
                )}
                <div className="mx-16 mb-8">
                    <div className="flex flex-col gap-8">
                        {publishedWidgets.sort(compareByOrdinal).map((widget) => {
                            if (widget.type === 'ProjectBudgetChart')
                                return (
                                    <ProjectBudgetWidget
                                        key={widget.financialsWidgetId}
                                        widgetData={widget}
                                        hasChanged={
                                            !areBudgetWidgetsEqual(
                                                widgetsData!.find(
                                                    (item) => item.financialsWidgetId === widget.financialsWidgetId
                                                ),
                                                widget
                                            )
                                        }
                                        isReadOnly={isReadOnly}
                                        onChange={(data) =>
                                            //@ts-ignore
                                            publishedWidgetChangeHandler(data, widget.financialsWidgetId)
                                        }
                                        onPublish={() => updateProjectWidget.mutate(widget)}
                                        onRemove={() => publishedWidgetDeleteHandler(widget.financialsWidgetId)}
                                        onReset={() => publishedWidgetResetHandler(widget.financialsWidgetId)}
                                    />
                                );
                            else if (widget.type === 'PieChart' || widget.type === 'BarChart')
                                return (
                                    <GraphChartWidget
                                        key={widget.financialsWidgetId}
                                        widgetData={widget}
                                        hasChanged={
                                            !areGraphWidgetsEqual(
                                                widgetsData!.find(
                                                    (item) => item.financialsWidgetId === widget.financialsWidgetId
                                                ),
                                                widget
                                            )
                                        }
                                        isReadOnly={isReadOnly}
                                        onChange={(data) =>
                                            //@ts-ignore
                                            publishedWidgetChangeHandler(data, widget.financialsWidgetId)
                                        }
                                        onPublish={() => updateProjectWidget.mutate(widget)}
                                        onRemove={() => publishedWidgetDeleteHandler(widget.financialsWidgetId)}
                                        onReset={() => publishedWidgetResetHandler(widget.financialsWidgetId)}
                                    />
                                );
                            else if (widget.type === 'Text')
                                return (
                                    <TextWidget
                                        key={widget.financialsWidgetId}
                                        widgetData={widget}
                                        hasChanged={
                                            !areTextWidgetsEqual(
                                                widgetsData!.find(
                                                    (item) => item.financialsWidgetId === widget.financialsWidgetId
                                                ),
                                                widget
                                            )
                                        }
                                        isReadOnly={isReadOnly}
                                        onChange={(data) =>
                                            //@ts-ignore
                                            publishedWidgetChangeHandler(data, widget.financialsWidgetId)
                                        }
                                        onPublish={() => updateProjectWidget.mutate(widget)}
                                        onRemove={() => publishedWidgetDeleteHandler(widget.financialsWidgetId)}
                                        onReset={() => publishedWidgetResetHandler(widget.financialsWidgetId)}
                                    />
                                );
                        })}
                        {newWidgets.sort(compareByOrdinal).map((widget, index) => {
                            if (widget.type === 'ProjectBudgetChart')
                                return (
                                    <ProjectBudgetWidget
                                        key={index}
                                        widgetData={widget}
                                        hasChanged={true}
                                        onChange={(data) => newWidgetChangeHandler(data, index)}
                                        onPublish={() => createWidget.mutate(widget)}
                                        onRemove={() => newWidgetRemoveHandler(index)}
                                        onReset={() =>
                                            newWidgetResetHandler(index, widget.ordinal, projectBudgetInitialState)
                                        }
                                    />
                                );
                            else if (widget.type === 'PieChart' || widget.type === 'BarChart')
                                return (
                                    <GraphChartWidget
                                        key={index}
                                        widgetData={widget}
                                        hasChanged={true}
                                        onChange={(data) => newWidgetChangeHandler(data, index)}
                                        onPublish={() => createWidget.mutate(widget)}
                                        onRemove={() => newWidgetRemoveHandler(index)}
                                        onReset={() =>
                                            newWidgetResetHandler(index, widget.ordinal, graphChartInitialState)
                                        }
                                    />
                                );
                            else if (widget.type === 'Text')
                                return (
                                    <TextWidget
                                        key={index}
                                        widgetData={widget}
                                        hasChanged={true}
                                        onChange={(data) => newWidgetChangeHandler(data, index)}
                                        onPublish={() => createWidget.mutate(widget)}
                                        onRemove={() => newWidgetRemoveHandler(index)}
                                        onReset={() =>
                                            newWidgetResetHandler(index, widget.ordinal, textWidgetInitialState)
                                        }
                                    />
                                );
                        })}
                        {!isDataPresent && (
                            <div className="mb-16 mt-8 flex flex-1 items-center justify-center italic">
                                Nothing to show
                            </div>
                        )}
                    </div>
                    <WithAccountPermission permission={accountFinancialsWidgetCanEdit}>
                        {addNewDisabled ? (
                            <SettingsFinancialsMaxWidgets maxCount={MAX_WIDGET_NUM} />
                        ) : (
                            <div className="flex w-full gap-8">
                                {addButtons.map((button, index) => (
                                    <div key={index} className="flex-1">
                                        <SettingsFinancialsAddWidget {...button} />
                                    </div>
                                ))}
                            </div>
                        )}
                    </WithAccountPermission>
                </div>
                <UnsavedChangesModal
                    showDialog={showPrompt}
                    confirmNavigation={confirmNavigation}
                    cancelNavigation={cancelNavigation}
                />
            </ErrorDisplay>
        </WithAnyAccountPermissions>
    );
}
