import Button from '../ButtonNew';
import Chart from 'chart.js/auto';
import ChartDataLabels, { Context } from 'chartjs-plugin-datalabels';
import TooltipContent from '../TooltipContent';
import { ArcElement, LayoutPosition, Legend, PointStyle, Tooltip, TooltipItem } from 'chart.js';
import { ArrowDownTrayIcon } from '@heroicons/react/24/solid';
import { Bar, Pie } from 'react-chartjs-2';
import { ChartData } from '../../client/Audiences/ChartModels';
import { ChartDisplayType } from '../clientApi/ClientApiTypes';
import { commaSeparatedNumber } from '../../common/utils';
import { createKpiChartExport } from '../clientApi/PowerPointBuilder';
import { CURRENCIES } from '../consts/enums';
import { downloadBlob, toPercentages } from '../../common/utils';
import { isEllipsisActive } from './../utils';
import { powerPointCanView } from '../clientApi/AccountPermissions';
import { powerPointExport } from '../../common/clientApi/PowerPointExportApi';
import { useAccountPermission } from '../userPermission';
import { useEffect, useRef, useState } from 'react';

Chart.register(ArcElement, Tooltip, Legend, ChartDataLabels);
Chart.defaults.elements.arc.borderWidth = 1;
Chart.defaults.elements.arc.borderColor = '#FFF';

enum Anchor {
    START = 'start',
    CENTER = 'center',
    END = 'end',
}

enum Align {
    BOTTOM = 'bottom',
    CENTER = 'center',
    TOP = 'top',
    END = 'end',
    START = 'start',
}

export const CHART_COLORS = [
    '#36A2EB',
    '#FF6384',
    '#4BC0C0',
    '#FF9F40',
    '#9966FF',
    '#FFCD56',
    '#C9CBCF',
    '#3E7CD6',
    '#6EC645',
    '#E16AEB',
];

export const KpiChartStyle = {
    pie: 'Pie',
    bar: 'Bar',
};

export type KpiChartData = {
    label: string;
    value: number;
    color?: string;
};

export type KpiChartParams = {
    chartType: ChartDisplayType;
    data: KpiChartData[];
    title?: string;
    count?: number;
    height: number;
    width: number;
    showLegend?: boolean;
    customLegend?: JSX.Element;
    showTooltip?: boolean;
    outsideLabels?: boolean;
    canExport?: boolean;
    customData?: Record<string, string>;
    showValueInTooltip?: boolean;
};

export default function KpiChart(params: KpiChartParams) {
    const {
        chartType,
        title,
        count,
        height,
        width,
        customLegend,
        canExport = true,
        showValueInTooltip = true,
    } = params;
    const showExport = canExport && useAccountPermission(powerPointCanView);
    const titleRef = useRef<HTMLHeadingElement>(null);
    const [isTitleTruncated, setIsTitleTruncated] = useState(false);
    const exportHandler = async () => {
        if (!showExport) {
            return;
        }

        const chartData: ChartData = {
            chartType: chartType,
            title: title ? `${title}${count && showValueInTooltip ? ` (n=${count})` : ''}` : '',
            data: params.data,
        };
        const exportData = createKpiChartExport(chartData);
        const pptx = await powerPointExport(exportData);
        downloadBlob('Polaris_Chart.pptx', pptx);
    };

    useEffect(() => {
        setIsTitleTruncated(isEllipsisActive(titleRef.current));
    }, [titleRef.current]);

    return (
        <div className="relative w-full flex-1">
            <div className="group w-full truncate">
                <h6 ref={titleRef} className="group w-full truncate">
                    {title}
                </h6>
                {isTitleTruncated && (
                    <TooltipContent className="-translate-x-60 -translate-y-16">{title}</TooltipContent>
                )}
            </div>
            <section className="flex flex-row justify-between">
                {showExport && (
                    <Button
                        buttonStyle="text"
                        isIconOnly={true}
                        onClick={exportHandler}
                        className="flex"
                        aria-label="Export to PowerPoint"
                    >
                        <>
                            <ArrowDownTrayIcon width={20} height={20} />
                        </>
                    </Button>
                )}
            </section>
            {!!count && showValueInTooltip && <aside className="description">n={count}</aside>}
            <div className="flex flex-row items-center justify-between">
                {customLegend}
                <div style={{ height: `${height}px`, width: `${width}px` }}>
                    {chartType === 'Bar' && <KpiBarChart {...params} />}
                    {chartType === 'Pie' && <KpiPieChart {...params} />}
                </div>
            </div>
        </div>
    );
}

export function KpiPieChart(params: KpiChartParams) {
    const { data, showLegend = true, showTooltip = true, outsideLabels = false, showValueInTooltip = true } = params;

    let total = 0;
    data.forEach((x) => (total += x.value));

    const percentages = toPercentages(data.map((d) => d.value));

    const d = {
        labels: data.map((x, idx) => `${x.label} (${percentages[idx]}%)`),
        datasets: [
            {
                data: data.map((x) => x.value),
                backgroundColor: data.every((x) => !!x.color) ? data.map((x) => x.color) : CHART_COLORS,
                borderColor: '#FFF',
            },
        ],
        borderWidth: 1,
    };

    const options = {
        plugins: {
            colors: {
                enabled: true,
            },
            legend: {
                display: showLegend,
                position: 'left' as LayoutPosition,
                usePointStyle: true,
                labels: {
                    usePointStyle: true,
                    boxWidth: 10,
                    pointStyle: 'circle' as PointStyle,
                },
            },
            datalabels: {
                display: (ctx: Context) => {
                    const value = ctx.dataset.data?.[ctx.dataIndex] as number;
                    return !!value && value > 0 && (outsideLabels ? 'auto' : true);
                },
                color: outsideLabels ? '#001028' : '#F7FCFE',
                font: {
                    size: 16,
                    weight: 500,
                },
                ...(outsideLabels && {
                    anchor: Anchor.END,
                    align: Align.END,
                    clip: false,
                }),
                formatter: (_: number, ctx: Context) => `${percentages[ctx.dataIndex]}%`,
            },
            tooltip: {
                enabled: showTooltip,
                callbacks: {
                    label: (context: TooltipItem<'pie'>) =>
                        showValueInTooltip
                            ? `${context.label}: ${commaSeparatedNumber(context.raw as number)}`
                            : `${context.label}`,
                },
            },
        },
        maintainAspectRatio: false,
        layout: {
            padding: outsideLabels ? 50 : 0,
        },
    };

    return <Pie data={d} options={options} updateMode="none" />;
}

export function KpiBarChart(params: KpiChartParams) {
    const {
        data,
        showTooltip = true,
        showLegend = false,
        outsideLabels = false,
        customData,
        showValueInTooltip = true,
    } = params;

    const appendUnit = (label: number, currency: string) => {
        switch (currency) {
            case CURRENCIES.usd:
                return `$${label}`;
            case CURRENCIES.eur:
                return `€${label}`;
            case CURRENCIES.gbp:
                return `£${label}`;
            default:
                return label;
        }
    };

    const d = {
        labels: data.map((x) => x.label),
        datasets: [
            {
                data: data.map((x) => x.value),
                backgroundColor: CHART_COLORS[0],
                borderColor: CHART_COLORS[0],
            },
        ],
    };

    const options = {
        plugins: {
            legend: {
                display: showLegend,
                position: 'bottom' as LayoutPosition,
                usePointStyle: true,
                labels: {
                    usePointStyle: true,
                    pointStyle: 'circle' as PointStyle,
                },
            },
            datalabels: {
                display: (ctx: Context) => {
                    const value = ctx.dataset.data?.[ctx.dataIndex] as number;
                    return outsideLabels && !!value && value > 0;
                },
                color: '#001028',
                font: {
                    size: 16,
                    weight: 500,
                },
                anchor: Anchor.END,
                align: Align.END,
                formatter: (value: number) => appendUnit(value, customData?.labelPrefix ?? ''),
            },
            tooltip: {
                enabled: showTooltip,
                callbacks: {
                    label: (context: TooltipItem<'bar'>) =>
                        showValueInTooltip
                            ? `${context.label}: ${commaSeparatedNumber(context.raw as number)}`
                            : `${context.label}`,
                },
            },
        },
        maintainAspectRatio: false,
        scales: {
            x: {
                display: true,
                grid: {
                    display: false,
                },
            },
            y: {
                beginAtZero: true,
                grid: {
                    display: false,
                },
            },
        },
        borderRadius: {
            topLeft: 8,
            topRight: 8,
        },
        layout: {
            padding: outsideLabels ? 50 : 0,
        },
    };

    return <Bar data={d} options={options} />;
}
