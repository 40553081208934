import { ReactChildren } from '../utils';

type CompositionChartWrappersParams = {
    children: ReactChildren;
};
export function CompositionChart(props: CompositionChartWrappersParams) {
    return (
        <div className="flex min-w-[350px] max-w-[400px] flex-1 rounded-sm border border-primary-50 p-4 lg:w-full lg:max-w-full">
            {props.children}
        </div>
    );
}

export function CommunityCompositionDashboard(props: CompositionChartWrappersParams) {
    return <div className="w-[530px] rounded-sm border border-primary-50 p-4">{props.children}</div>;
}
