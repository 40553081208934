import { DropdownOption, EmptySelectValue } from '../../../../../common/Dropdown';
import { marketingContentEventFetchList } from '../../../../../common/clientApi/MarketingContentSettingApi';
import {
    AudienceMarketingContentTileViewModel,
    MarketingContentSettingType,
} from '../../../../../common/clientApi/ClientApiTypes';
import PartnershipEventMarketingTile from '../../../../../common/components/PartnershipEventMarketingTile';
import { MarketingContentSettingViewModelType, Panel } from '../Panel';
import { generateKey } from '../../../../../common/utils';
import { clientId, accountId } from '../../../../Tenant';

const contentTitle = 'Events';
const successMessageTitle = 'Events';
const name = 'footer';
const settingsType: MarketingContentSettingType = 'PartnershipPageEvent';
const contentCount = 2;

type Props = {
    onError: (widgetTitle: string, action: 'publishing' | 'unpublishing') => void;
    setShowUnsavedChangesModal: (isShow: boolean) => void;
};

export default function PartnershipEventPanel({ onError, setShowUnsavedChangesModal }: Props) {
    const sectionToDisplay = (index: number) => (index === 0 ? 'Left' : 'Right');

    const isShowPreview = (
        _: Array<AudienceMarketingContentTileViewModel | undefined>,
        selectedItems: DropdownOption[]
    ) => selectedItems.some((x) => x.value !== EmptySelectValue.value);

    const mapItems = (
        data: MarketingContentSettingViewModelType[],
        selectedItems: DropdownOption[]
    ): Array<MarketingContentSettingViewModelType | undefined> =>
        selectedItems.map((selectedItem) => {
            const item = data.find((dataItem) => selectedItem.value === dataItem.tileId);
            return item;
        });

    const validateContentItems = (items: DropdownOption[]) => {
        const isPublishDisabled = items.some((x) => x.value === EmptySelectValue.value);
        const errorList = items.map((x) => x.value === EmptySelectValue.value);

        return { isPublishDisabled, errorList };
    };

    const execFetchAll = () => {
        return marketingContentEventFetchList(clientId, accountId);
    };

    const previewContent = (items: Array<AudienceMarketingContentTileViewModel | undefined>) => (
        <div className="h-full bg-background">
            <div className="p-6">
                <div className="flex gap-6">
                    {items.map((x) =>
                        x ? (
                            <PartnershipEventMarketingTile
                                key={x.tileId}
                                title={x.title}
                                description={x.description}
                                cardTitle={''}
                                linkTitle={x.linkTitle}
                                linkUrl={x.linkUrl}
                                tileMediaId={x.tileMediaId}
                            />
                        ) : (
                            <div key={generateKey()} className="basis-1/2" />
                        )
                    )}
                </div>
            </div>
        </div>
    );

    return (
        <Panel
            contentCount={contentCount}
            name={name}
            settingsType={settingsType}
            contentTitle={contentTitle}
            successMessageTitle={successMessageTitle}
            validateContentItems={validateContentItems}
            sectionToDisplay={sectionToDisplay}
            isShowPreview={isShowPreview}
            previewContent={previewContent}
            mapItems={mapItems}
            onError={onError}
            fetchAllList={execFetchAll}
            setShowUnsavedChangesModal={setShowUnsavedChangesModal}
        />
    );
}
