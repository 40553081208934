import { TrashIcon } from '@heroicons/react/24/outline';
import { PlusIcon } from '@heroicons/react/24/solid';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DateValueType } from 'react-tailwindcss-datepicker';
import Button from '../../../../common/ButtonNew';
import DatePicker from '../../../../common/DatePicker';
import Dropdown, { DropdownOption } from '../../../../common/Dropdown';
import {
    FinancialsWidgetCreateModel,
    FinancialsWidgetCurrency,
    FinancialsWidgetViewModel,
} from '../../../../common/clientApi/ClientApiTypes';
import { ErrorLabel, TextInput } from '../../../../common/components/form/Form';
import { CHART_ITEMS_ORDINAL, CURRENCIES, FIELD_TOKENS } from '../../../../common/consts/enums';
import { MAX_WIDGET_CATEGORIES_NUM } from '../../../../common/consts/settings';
import { compareByOrdinal, getLocale, getNextOrdinalNumber, roundMoney } from '../../../../common/utils';
import { calculateRemainingBudget } from './ProjectBudgetWidgetUtils';
import ProjectBudgetWidgetPreview from './ProjectBudgetWidgetPreview';
import HeaderIndicator from '../../../../common/components/HeaderIndicator';
import { HeaderIndicatorType } from '../../../../common/consts/enums';
import { SingleValue } from 'react-select';
import {
    ProjectBudgetValidationConfiguration,
    VALIDATION_CONSTS,
    getChartItemsValidationConfiguration,
} from './ProjectBudgetValidation';
import { useValidation } from '../../../../common/hooks/validation/useValidation';

type Props = {
    widgetData: FinancialsWidgetCreateModel | FinancialsWidgetViewModel;
    isReadOnly?: boolean;
    hasChanged: boolean;
    onChange: (data: FinancialsWidgetCreateModel | FinancialsWidgetViewModel) => void;
    onPublish: () => void;
    onRemove: () => void;
    onReset: () => void;
};

export default function ProjectBudgetWidgetContainer({
    widgetData,
    isReadOnly,
    hasChanged,
    onChange,
    onPublish,
    onRemove,
    onReset,
}: Props) {
    const [editedFields, setEditedFields] = useState({});
    const [editedItems, setEditedItems] = useState({});

    const [focusedField, setFocusedField] = useState<string | undefined>(undefined);
    const focusHandler = (fieldName: string) => setFocusedField(fieldName);
    const blurHandler = () => setFocusedField(undefined);

    const widgetChartItems = useMemo(() => widgetData?.chartItems || [], [widgetData]);

    const areChartItemsAdded = widgetChartItems.length > 2;
    const getNameFieldId = (ordinal: number) => `${FIELD_TOKENS.FIELD}_${ordinal}_${FIELD_TOKENS.NAME}`;
    const getValueFieldId = (ordinal: number) => `${FIELD_TOKENS.FIELD}_${ordinal}_${FIELD_TOKENS.VALUE}`;

    const totalBudget = useMemo(
        () => widgetChartItems.find((item) => item.ordinal === CHART_ITEMS_ORDINAL.TOTAL)?.value ?? 0,
        [widgetChartItems]
    );

    const remainingBudget = useMemo(
        () => roundMoney(calculateRemainingBudget(totalBudget, widgetChartItems)),
        [totalBudget, widgetChartItems]
    );

    const chartItemsModel = useMemo(() => {
        const itemsModel = {} as Record<string, string | number>;
        for (const item of widgetChartItems) {
            itemsModel[getNameFieldId(item.ordinal)] = item.name;
            itemsModel[getValueFieldId(item.ordinal)] = item.value;
        }
        return itemsModel;
    }, [widgetChartItems]);

    const maxCategoriesReached = widgetChartItems.length >= MAX_WIDGET_CATEGORIES_NUM;

    const { validationMessages, isModelInvalid } = useValidation({
        model: widgetData,
        configuration: ProjectBudgetValidationConfiguration,
        focused: focusedField,
        edited: editedFields,
    });

    const itemsValidationConfiguration = useMemo(
        () => getChartItemsValidationConfiguration(chartItemsModel),
        [chartItemsModel]
    );
    const { validationMessages: itemsValidationMessages, isModelInvalid: areItemsInvalid } = useValidation({
        model: chartItemsModel,
        configuration: itemsValidationConfiguration,
        focused: focusedField,
        edited: editedItems,
    });

    useEffect(() => {
        !isReadOnly &&
            onChange({
                ...widgetData,
                chartItems: [
                    ...widgetChartItems.map((item) =>
                        item.ordinal === CHART_ITEMS_ORDINAL.REMAINING ? { ...item, value: remainingBudget } : item
                    ),
                ],
            });
    }, [remainingBudget, isReadOnly]);

    const addField = useCallback(() => {
        onChange({
            ...widgetData,
            chartItems: [
                ...widgetChartItems,
                {
                    name: '',
                    value: 0,
                    ordinal: getNextOrdinalNumber(
                        widgetChartItems.filter((item) => item.ordinal !== CHART_ITEMS_ORDINAL.REMAINING)
                    ),
                },
            ],
        });
    }, [onChange, widgetData, widgetChartItems]);

    const removeField = useCallback(
        (ordinalNumber: number) => {
            setEditedItems({
                ...editedItems,
                [getNameFieldId(ordinalNumber)]: false,
                [getValueFieldId(ordinalNumber)]: false,
            });
            onChange({
                ...widgetData,
                chartItems: [
                    ...widgetChartItems
                        .filter((item) => item.ordinal !== ordinalNumber)
                        .map((item) =>
                            item.ordinal > ordinalNumber && item.ordinal !== CHART_ITEMS_ORDINAL.REMAINING
                                ? { ...item, ordinal: item.ordinal > 1 ? item.ordinal - 1 : item.ordinal }
                                : item
                        ),
                ],
            });
        },
        [onChange, widgetData, widgetChartItems]
    );

    const nameValueChangeHandler = useCallback(
        (name: string, value: string) => {
            if (name.startsWith(FIELD_TOKENS.FIELD)) {
                setEditedItems({ ...editedItems, [name]: true });
                if (name.endsWith(FIELD_TOKENS.VALUE)) {
                    value = value.replace(/[^0-9]/g, '');
                }
                const [ordinalNumber, field] = name.split('_').slice(1);
                onChange({
                    ...widgetData,
                    chartItems: [
                        ...widgetChartItems.map((item) =>
                            item.ordinal === +ordinalNumber
                                ? {
                                      ...item,
                                      [field]: field === FIELD_TOKENS.VALUE ? (value ? +value : NaN) : value,
                                  }
                                : item
                        ),
                    ],
                });
            } else {
                setEditedFields({ ...editedFields, [name]: true });
                onChange({
                    ...widgetData,
                    [name]: value,
                });
            }
        },
        [onChange, widgetData, widgetChartItems]
    );

    const dateRangeChangeHandler = useCallback(
        (_name: string, e: DateValueType) => {
            setEditedFields({ ...editedFields, startDate: true, stopDate: true });
            onChange({
                ...widgetData,
                startDate: e?.startDate ? new Date(e.startDate).toISOString() : '',
                stopDate: e?.endDate ? new Date(e.endDate).toISOString() : '',
            });
        },
        [onChange, widgetData]
    );

    const currencyChangeHandler = useCallback(
        (selectedValue: SingleValue<DropdownOption>) => {
            setEditedFields({ ...editedFields, currency: true });
            onChange({
                ...widgetData,
                currency: selectedValue!.value as FinancialsWidgetCurrency,
            });
        },
        [onChange, widgetData]
    );

    const currencyOptions = useMemo(
        () =>
            Object.values(CURRENCIES)
                .filter((currency) => currency !== CURRENCIES.percentages)
                .map((currency) => ({ label: currency, value: currency })),
        []
    );

    return (
        <div className="mb-8 flex w-full gap-1">
            <div className="flex w-[420px] flex-none flex-col rounded-l-sm bg-white px-6 py-8">
                <TextInput
                    hasError={!!validationMessages.title}
                    className="px-3.5 py-2.5 text-xl font-semibold leading-6"
                    name="title"
                    maxLength={VALIDATION_CONSTS.TITLE_MAX_LENGTH}
                    readOnly={isReadOnly}
                    value={widgetData.title}
                    onChange={nameValueChangeHandler}
                    onFocus={focusHandler}
                    onBlur={blurHandler}
                />
                {validationMessages.title && (
                    <ErrorLabel className="mt-1 text-sm text-error-700" errors={validationMessages.title} />
                )}
                {widgetData.title.length >= VALIDATION_CONSTS.TITLE_MAX_LENGTH && (
                    <div className="mt-1 text-sm">{`Maximum ${VALIDATION_CONSTS.TITLE_MAX_LENGTH} characters`}</div>
                )}
                <div className="mt-6 flex flex-col items-start gap-2 bg-background px-4 py-2">
                    <span className="text-xs font-bold uppercase">Date Range:</span>
                    <DatePicker
                        isValid={!validationMessages.startDate && !validationMessages.stopDate}
                        i18n={getLocale()}
                        inputName="dates"
                        containerClassName="w-3/4"
                        disabled={isReadOnly}
                        startDate={widgetData.startDate ? new Date(widgetData.startDate) : null}
                        endDate={widgetData.stopDate ? new Date(widgetData.stopDate) : null}
                        onChange={dateRangeChangeHandler}
                    />
                </div>
                {(!!validationMessages.startDate || !!validationMessages.stopDate) && (
                    <ErrorLabel className="mt-1 text-sm text-error-700" errors={'Required field'} />
                )}
                <div className="my-4 w-32 self-end bg-purewhite">
                    <Dropdown
                        options={currencyOptions}
                        value={currencyOptions.find((curr) => curr.value === widgetData.currency)}
                        isDisabled={isReadOnly}
                        onChange={currencyChangeHandler}
                    />
                </div>
                <div className="mb-4 flex w-full flex-col gap-4">
                    {widgetChartItems.sort(compareByOrdinal).map((field) => (
                        <div key={field.ordinal} className="flex items-start gap-4">
                            {field.ordinal === CHART_ITEMS_ORDINAL.TOTAL && (
                                <>
                                    <input
                                        className="flex-1 border-0 bg-transparent px-3.5 py-2.5 text-left focus:ring-0"
                                        readOnly={true}
                                        value="Total*"
                                    />
                                    <div className="flex w-28 flex-col items-start gap-1">
                                        <TextInput
                                            className="w-full flex-none px-3.5 py-2.5 text-right"
                                            hasError={!!itemsValidationMessages[getValueFieldId(field.ordinal)]}
                                            name={getValueFieldId(CHART_ITEMS_ORDINAL.TOTAL)}
                                            maxLength={VALIDATION_CONSTS.FIELD_VALUE_MAX_LENGTH}
                                            readOnly={isReadOnly}
                                            value={Number.isNaN(field.value) ? '' : String(field.value)}
                                            onChange={nameValueChangeHandler}
                                            onFocus={focusHandler}
                                            onBlur={blurHandler}
                                        />
                                        {field.value.toString().length >= VALIDATION_CONSTS.FIELD_VALUE_MAX_LENGTH && (
                                            <div className="mt-1 text-sm">{`Maximum ${VALIDATION_CONSTS.FIELD_VALUE_MAX_LENGTH} digits`}</div>
                                        )}
                                        {!!itemsValidationMessages[getValueFieldId(field.ordinal)] && (
                                            <ErrorLabel
                                                className="mt-1 text-sm text-error-700"
                                                errors={itemsValidationMessages[getValueFieldId(field.ordinal)]!}
                                            />
                                        )}
                                    </div>
                                    {!isReadOnly && <div className="w-4" />}
                                </>
                            )}
                            {field.ordinal === CHART_ITEMS_ORDINAL.REMAINING && (
                                <>
                                    <input
                                        className={`transition-colors ${
                                            remainingBudget <= 0 ? 'text-error-700' : 'text-black'
                                        } flex-1 border-0 bg-transparent px-3.5 py-2.5 text-left font-bold focus:ring-0`}
                                        readOnly={true}
                                        value="Remaining"
                                    />
                                    <div
                                        className={`transition-colors ${
                                            remainingBudget <= 0 ? 'text-error-700' : 'text-black'
                                        } w-28 px-3.5 py-2.5 text-right font-bold`}
                                    >
                                        {remainingBudget}
                                    </div>
                                    {!isReadOnly && <div className="w-4 flex-none" />}
                                </>
                            )}
                            {field.ordinal !== CHART_ITEMS_ORDINAL.TOTAL &&
                                field.ordinal !== CHART_ITEMS_ORDINAL.REMAINING && (
                                    <>
                                        <div className="flex grow flex-col items-start gap-1">
                                            <TextInput
                                                className="w-full flex-1 px-3.5 py-2.5 text-left"
                                                hasError={!!itemsValidationMessages[getNameFieldId(field.ordinal)]}
                                                maxLength={VALIDATION_CONSTS.FIELD_NAME_MAX_LENGTH}
                                                name={getNameFieldId(field.ordinal)}
                                                value={String(field.name)}
                                                placeholder="Add category"
                                                readOnly={isReadOnly}
                                                onChange={nameValueChangeHandler}
                                                onFocus={focusHandler}
                                                onBlur={blurHandler}
                                            />
                                            {field.name.length >= VALIDATION_CONSTS.FIELD_NAME_MAX_LENGTH && (
                                                <div className="mt-1 text-sm">{`Maximum ${VALIDATION_CONSTS.FIELD_NAME_MAX_LENGTH} characters`}</div>
                                            )}
                                            {!!itemsValidationMessages[getNameFieldId(field.ordinal)] && (
                                                <ErrorLabel
                                                    className="mt-1 text-sm text-error-700"
                                                    errors={itemsValidationMessages[getNameFieldId(field.ordinal)]!}
                                                />
                                            )}
                                        </div>
                                        <div className="flex w-28 flex-col items-start gap-1">
                                            <TextInput
                                                className="w-full px-3.5 py-2.5 text-right"
                                                hasError={!!itemsValidationMessages[getValueFieldId(field.ordinal)]}
                                                name={getValueFieldId(field.ordinal)}
                                                value={Number.isNaN(field.value) ? '' : String(field.value)}
                                                maxLength={VALIDATION_CONSTS.FIELD_VALUE_MAX_LENGTH}
                                                readOnly={isReadOnly}
                                                onChange={nameValueChangeHandler}
                                                onFocus={focusHandler}
                                                onBlur={blurHandler}
                                            />
                                            {field.value.toString().length >=
                                                VALIDATION_CONSTS.FIELD_VALUE_MAX_LENGTH && (
                                                <div className="mt-1 text-sm">{`Maximum ${VALIDATION_CONSTS.FIELD_VALUE_MAX_LENGTH} digits`}</div>
                                            )}
                                            {!!itemsValidationMessages[getValueFieldId(field.ordinal)] && (
                                                <ErrorLabel
                                                    className="mt-1 text-sm text-error-700"
                                                    errors={itemsValidationMessages[getValueFieldId(field.ordinal)]!}
                                                />
                                            )}
                                        </div>
                                        {!isReadOnly && (
                                            <TrashIcon
                                                className="mt-3 h-4 w-4 cursor-pointer transition-transform hover:scale-125"
                                                onClick={() => removeField(field.ordinal)}
                                            />
                                        )}
                                    </>
                                )}
                        </div>
                    ))}
                </div>

                {!isReadOnly && (
                    <>
                        <Button
                            buttonStyle="secondary"
                            buttonSize="sm"
                            onMouseDown={addField}
                            isDisabled={maxCategoriesReached}
                        >
                            <div className="flex items-center justify-center gap-2">
                                <PlusIcon className="h-4 w-4" />
                                <span>Add Row</span>
                            </div>
                        </Button>
                        {maxCategoriesReached && (
                            <span className="mt-4 text-xs">{`Maximum of ${MAX_WIDGET_CATEGORIES_NUM} rows reached`}</span>
                        )}
                        <div className="mb-4 mt-10 w-full border-t border-gray-100" />
                        <div className="flex items-center justify-start gap-4">
                            <Button
                                buttonSize="sm"
                                onMouseDown={onPublish}
                                isDisabled={isModelInvalid || areItemsInvalid || !areChartItemsAdded}
                            >
                                <span>Publish</span>
                            </Button>
                            <Button
                                buttonSize="sm"
                                buttonStyle="secondary"
                                onMouseDown={() => {
                                    setEditedFields({});
                                    setEditedItems({});
                                    onReset();
                                }}
                            >
                                <span>Reset</span>
                            </Button>
                            <Button usePadding={false} buttonSize="sm" buttonStyle="text" onMouseDown={onRemove}>
                                <span>Delete</span>
                            </Button>
                        </div>
                    </>
                )}
            </div>
            <div className="flex flex-1 flex-col justify-start rounded-r-sm bg-white px-6 py-9">
                <div className="mb-3">
                    <HeaderIndicator type={hasChanged ? HeaderIndicatorType.Draft : HeaderIndicatorType.Published} />
                </div>
                <div className="w-full border-t border-primary-50" />
                <div className="mt-6 flex flex-col rounded-sm border border-primary-50 px-6 py-8">
                    <ProjectBudgetWidgetPreview widgetData={widgetData} />
                </div>
            </div>
        </div>
    );
}
