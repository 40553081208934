import ContentDetails from './ContentDetails';
import HeaderIndicator from '../../../../common/components/HeaderIndicator';
import { HeaderIndicatorType } from '../../../../common/consts/enums';
import { useEffect, useState } from 'react';
import { accountFetchByName as getAccountInfo } from '../../../../common/clientApi/AccountApi';
import { PError } from '../../../../common/apiTypes';
import { FormError } from '../../../../common/components/form/Form';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { DropdownOption, EmptySelectValue } from '../../../../common/Dropdown';
import { useParams } from 'react-router-dom';
import {
    AccountDetailModel,
    AudienceMarketingContentTileViewModel,
    MarketingContentSettingCreateModel,
    MarketingContentSettingType,
    MarketingContentSettingViewModel,
    PartnershipEventTileViewModel,
    PartnershipHeroTileViewModel,
    ProjectsPageMarketingTileViewModel,
} from '../../../../common/clientApi/ClientApiTypes';
import {
    marketingContentSettingsDelete,
    marketingContentSettingsFetchList as fetchSelectedList,
    marketingContentSettingsSave,
} from '../../../../common/clientApi/MarketingContentSettingApi';
import { successToast } from '../../../../common/components/toastNotification';
import { SingleValue } from 'react-select';
import { marketingContentSettingCanEdit } from '../../../../common/clientApi/AccountPermissions';
import { useAccountPermission } from '../../../../common/userPermission';
import ErrorDisplay from '../../../../common/components/ErrorDisplay';
import { ReactChildren } from '../../../../common/utils';

export type MarketingContentSettingViewModelType =
    | AudienceMarketingContentTileViewModel
    | PartnershipEventTileViewModel
    | PartnershipHeroTileViewModel
    | ProjectsPageMarketingTileViewModel;

type Props<TViewModel, TPreviewItems> = {
    contentCount: number;
    name: string;
    settingsType: MarketingContentSettingType;
    panelTitle?: string;
    successMessageTitle: string;
    panelDescription?: string;
    contentTitle: string;
    validateContentItems: (items: DropdownOption[]) => { isPublishDisabled: boolean; errorList: boolean[] };
    sectionToDisplay: (index: number) => string;
    isShowPreview: (previewItems: TPreviewItems, selectedItems: DropdownOption[]) => boolean;
    previewContent: (previewItems: TPreviewItems) => ReactChildren;
    fetchAllList: () => Promise<TViewModel[]>;
    onError: (widgetTitle: string, action: 'publishing' | 'unpublishing') => void;
    setShowUnsavedChangesModal: (isShow: boolean) => void;
    mapItems: (data: MarketingContentSettingViewModelType[], selectedItems: DropdownOption[]) => TPreviewItems;
};

export function Panel<TViewModel extends MarketingContentSettingViewModelType, TPreviewItems>({
    contentCount,
    name,
    settingsType,
    panelTitle,
    panelDescription,
    contentTitle,
    successMessageTitle,
    sectionToDisplay,
    mapItems,
    isShowPreview,
    previewContent,
    onError,
    fetchAllList,
    setShowUnsavedChangesModal,
    validateContentItems,
}: Props<TViewModel, TPreviewItems>) {
    const queryClient = useQueryClient();
    const [selectedContentList, setSelectedContentList] = useState<DropdownOption[]>(
        Array.from(Array(contentCount), () => EmptySelectValue)
    );
    const [contentList, setContentList] = useState<DropdownOption[]>([]);
    const [isErrorList, setIsErrorList] = useState<boolean[]>(Array.from(Array(contentCount), () => false));
    const [isPublishDisabled, setIsPublishDisabled] = useState<boolean>(false);
    const [isUnpublishDisabled, setIsUnpublishDisabled] = useState<boolean>(false);
    const [headerIndicatorType, setHeaderIndicatorType] = useState<HeaderIndicatorType>(HeaderIndicatorType.Draft);

    useEffect(() => {
        setIsPublishDisabled(
            selectedContentList.every((v) => v.value === EmptySelectValue.value) || isErrorList.some((v) => v)
        );
    }, [selectedContentList, isErrorList]);

    const { client, account } = useParams();
    const { data: accountData, error: errorAccountInfo } = useQuery<AccountDetailModel, Error>({
        queryKey: ['accountInfo', client!, account!],
        queryFn: () => getAccountInfo(client!, account!),
    });

    const {
        data,
        isSuccess: isSuccessAll,
        error: errorAll,
    } = useQuery<TViewModel[], Error>({
        queryKey: [`settings-${name}-all`],
        queryFn: async () => {
            const res = await fetchAllList();
            const marketingContent =
                res?.map((item) => ({
                    label: item.title,
                    value: item.tileId,
                })) ?? [];
            marketingContent.unshift(EmptySelectValue);
            setContentList(marketingContent);
            return res;
        },
    });

    const { isSuccess: isSuccessSelected, error: errorSelected } = useQuery<MarketingContentSettingViewModel[], Error>({
        queryKey: [`setting-${name}-selected`],
        enabled: isSuccessAll && !!accountData,
        queryFn: async () => {
            const res = await fetchSelectedList(accountData!.clientId, accountData!.accountId, {
                type: settingsType,
            });

            const newIsErrorList = Array.from(Array(contentCount), () => false);
            setIsErrorList(newIsErrorList);

            setHeaderIndicatorType(HeaderIndicatorType.Published);

            if (res && res.length > 0) {
                setIsUnpublishDisabled(false);
                if (data) {
                    const newSelectedContentList = Array.from(Array(contentCount), () => EmptySelectValue);

                    selectedContentList.forEach((_, i) => {
                        const item = data.find((item) => item.tileId === res[i]?.contentId);

                        newSelectedContentList[i] = item ? { label: item.title, value: item.tileId } : EmptySelectValue;
                    });

                    setSelectedContentList(newSelectedContentList);
                }
            } else {
                const newSelectedContentList = Array(selectedContentList.length).fill({ ...EmptySelectValue });
                setSelectedContentList(newSelectedContentList);
                setIsUnpublishDisabled(true);
            }
            return res;
        },
    });

    const saveMarketingContent = async () => {
        const saveModel: MarketingContentSettingCreateModel = {
            type: settingsType,
            content: selectedContentList
                .filter((x) => x.value !== EmptySelectValue.value)
                .map((x, i) => ({
                    contentId: x.value,
                    ordinal: i + 1,
                })),
        };

        return await marketingContentSettingsSave(accountData!.clientId, accountData!.accountId, saveModel);
    };

    const {
        mutate: publishMutate,
        isLoading: isPublishLoading,
        error: publishError,
    } = useMutation<MarketingContentSettingViewModel[], PError>(saveMarketingContent, {
        onSuccess: () => {
            setIsUnpublishDisabled(false);
            setHeaderIndicatorType(HeaderIndicatorType.Published);
            successToast(`${successMessageTitle} content has been published`, 'RIGHT');
        },
        onError: () => onError(panelTitle || contentTitle, 'publishing'),
    });

    const contentChangedHandler = (index: number) => (option: SingleValue<DropdownOption>) => {
        if (!option) {
            return;
        }

        const newSelectedContentList = [...selectedContentList];
        newSelectedContentList[index] = option;
        setSelectedContentList(newSelectedContentList);
        validateItems(newSelectedContentList);
    };

    const onPublishClickHandler = () => {
        if (validateItems(selectedContentList)) {
            publishMutate();
        }
    };

    const onUnpublishClickHandler = () => {
        unpublishMutate();
    };

    const validateItems = (items: DropdownOption[]) => {
        const { isPublishDisabled, errorList } = validateContentItems(items);
        setIsErrorList(errorList);
        setIsPublishDisabled(isPublishDisabled);
        return !isPublishDisabled;
    };

    const deleteAudienceTiles = async () => {
        setIsUnpublishDisabled(true);
        await marketingContentSettingsDelete(accountData!.clientId, accountData!.accountId, settingsType);
    };

    const { mutate: unpublishMutate, isLoading: isUnpublishLoading } = useMutation(deleteAudienceTiles, {
        onSuccess: () => {
            queryClient.invalidateQueries([`setting-${name}-selected`]);
            successToast(`${successMessageTitle} content has been unpublished`, 'RIGHT');
        },
        onError: () => onError(panelTitle || contentTitle, 'unpublishing'),
    });

    const isResetDisabled = headerIndicatorType === HeaderIndicatorType.Published;

    const onResetClickHandler = () => {
        queryClient.invalidateQueries([`setting-${name}-selected`]);
    };

    const isPublishHidden = !useAccountPermission(marketingContentSettingCanEdit);
    const contentBodyList = selectedContentList.map((_, i) => ({
        selectedContent: selectedContentList[i],
        onContentChanged: contentChangedHandler(i),
        content: contentList.filter(
            (content) =>
                !selectedContentList
                    .filter((_, selectedContentIndex) => i !== selectedContentIndex)
                    .some((selectedContent) => selectedContent.value === content.value)
        ),
        sectionToDisplay: sectionToDisplay(i),
        isError: isErrorList[i],
    }));

    const items = mapItems(data || [], selectedContentList);

    return (
        <ErrorDisplay error={errorAccountInfo ?? errorAll ?? errorSelected} errorMessageClassName="bg-white px-6 py-8">
            {isSuccessAll && isSuccessSelected && (
                <>
                    <div className="bg-white px-6 py-8">
                        {panelTitle && (
                            <div className="mb-10 w-[656px] border-b-[1px] border-primary-50">
                                <h3>{panelTitle}</h3>

                                {panelDescription && <div className="pb-3 pt-2 text-sm">{panelDescription}</div>}
                            </div>
                        )}

                        {contentTitle && <h4 className="pb-4 uppercase">{contentTitle}</h4>}

                        {publishError?.response?.data.title && (
                            <div className="mx-6 pb-4">
                                <FormError>{publishError.response.data.title}</FormError>
                            </div>
                        )}

                        <ContentDetails
                            contentBodyList={contentBodyList}
                            isPublishDisabled={isPublishDisabled || isPublishLoading}
                            isUnpublishedDisabled={isUnpublishDisabled || isUnpublishLoading}
                            isPublishHidden={isPublishHidden}
                            isResetDisabled={isResetDisabled}
                            setHeaderIndicatorType={setHeaderIndicatorType}
                            setShowUnsavedChangesModal={setShowUnsavedChangesModal}
                            onPublish={onPublishClickHandler}
                            onUnpublish={onUnpublishClickHandler}
                            onReset={onResetClickHandler}
                        />
                    </div>

                    {isShowPreview(items, selectedContentList) && (
                        <div className="mt-1 flex min-h-[470px] flex-col bg-white p-6">
                            <div className="mb-3">
                                <HeaderIndicator type={headerIndicatorType} />
                            </div>

                            {previewContent(items)}
                        </div>
                    )}
                </>
            )}
        </ErrorDisplay>
    );
}
