import React from 'react';
import { SettingsTab } from './Settings/SettingsPage';
import { Navigate } from 'react-router-dom';
import { PageNotFound } from '../common/ErrorPage';
import { lazyWithRetries } from '../common/lazyWithRetries';
import LoadingFullScreen from '../common/components/LoadingFullScreen';
import { MyProfileTabs } from './Settings/MyProfile/MyProfileTabs';

const ClientProjectList = lazyWithRetries(() => import('./Projects/ProjectsPage'));
const ProjectDetail = lazyWithRetries(() => import('./Projects/ProjectDetail/ProjectDetail'));
const ClientShell = lazyWithRetries(() => import('./ClientShell'));
const ProjectManage = lazyWithRetries(() => import('./Projects/ProjectManage/ProjectManage'));
const PartnershipsPage = lazyWithRetries(() => import('./Partnerships/PartnershipsPage'));
const AccountSelect = lazyWithRetries(() => import('./AccountList/AccountSelect'));
const ClientAudiences = lazyWithRetries(() => import('./Audiences/ClientAudiences'));
const AudienceRequest = lazyWithRetries(() => import('./Audiences/AudienceRequest'));
const CommunityComposition = lazyWithRetries(() => import('./Audiences/CommunityComposition'));
const SettingsPage = lazyWithRetries(() => import('./Settings/SettingsPage'));
const MyProfile = lazyWithRetries(() => import('./MyProfile'));

export const clientRoutes = [
    {
        path: '/',
        element: (
            <React.Suspense fallback={<LoadingFullScreen />}>
                <ClientShell showNav={false} />
            </React.Suspense>
        ),
        children: [
            {
                path: '',
                element: <AccountSelect />,
            },
            {
                path: 'myprofile',
                element: <MyProfile tab={MyProfileTabs.Profile} />,
            },
            {
                path: 'notifications',
                element: <MyProfile tab={MyProfileTabs.Notifications} />,
            },
        ],
    },
    {
        path: '/:client/:account',
        element: (
            <React.Suspense fallback={<LoadingFullScreen />}>
                <ClientShell />
            </React.Suspense>
        ),
        children: [
            {
                path: '',
                element: <Navigate to="projects" />,
            },
            {
                path: 'projects/create',
                element: <ProjectManage />,
            },
            {
                path: 'projects/:projectId',
                element: <ProjectDetail />,
            },
            {
                path: 'projects/:projectId/edit',
                element: <ProjectManage />,
            },
            {
                path: 'projects/:projectId/edit/submit',
                element: <ProjectManage isSubmit={true} />,
            },
            {
                path: 'projects/:projectId/view',
                element: <ProjectManage readOnly={true} />,
            },
            {
                path: 'projects',
                element: <ClientProjectList />,
            },
            {
                path: 'audiences/communityComposition/:dataProviderId',
                element: <CommunityComposition />,
            },
            {
                path: 'audiences',
                element: <ClientAudiences />,
            },
            {
                path: 'audiences/audienceRequest',
                element: <AudienceRequest />,
            },
            {
                path: 'partnership/:widgetId?',
                element: <PartnershipsPage />,
            },
            {
                path: 'settings',
                element: <Navigate to="financials" replace />,
            },
            {
                path: 'settings/financials',
                element: <SettingsPage tab={SettingsTab.Financials} />,
            },
            {
                path: 'settings/marketingContent',
                element: <SettingsPage tab={SettingsTab.MarketingContent} />,
            },
            {
                path: 'settings/teamMembers',
                element: <SettingsPage tab={SettingsTab.TeamMembers} />,
            },
            {
                path: 'settings/communityComposition',
                element: <SettingsPage tab={SettingsTab.CommunityComposition} />,
            },
            {
                path: 'myprofile',
                element: <MyProfile tab={MyProfileTabs.Profile} />,
            },
            {
                path: 'notifications',
                element: <MyProfile tab={MyProfileTabs.Notifications} />,
            },
        ],
    },

    {
        path: '*',
        element: <PageNotFound />,
    },
];
