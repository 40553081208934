import React from 'react';
import { FinancialsWidgetCreateModel, FinancialsWidgetViewModel } from '../../../../common/clientApi/ClientApiTypes';
import { formatDateToMMMDDYYYY } from '../../../../common/dateUtils';

type Props = {
    widgetData: FinancialsWidgetCreateModel | FinancialsWidgetViewModel;
};

export default function TextWidgetPreview({ widgetData }: Props) {
    return (
        <>
            <h3 className="mb-5">{widgetData.title}</h3>
            {(widgetData as FinancialsWidgetViewModel).updatedDate && (
                <div className="text-xs leading-[18px]">
                    Updated {formatDateToMMMDDYYYY((widgetData as FinancialsWidgetViewModel).updatedDate)}
                </div>
            )}
            <div className="ql-snow ql-container">
                <div
                    className="ql-editor max-h-[300px] overflow-auto break-all !bg-transparent !p-0"
                    dangerouslySetInnerHTML={{ __html: widgetData?.textItemValue ?? '' }}
                />
            </div>
        </>
    );
}
