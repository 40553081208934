import ReactDOM from 'react-dom/client';
import IndexBase from '../IndexBase';
import ClientApp from './App';
import React from 'react';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <React.StrictMode>
        <IndexBase>
            <ClientApp />
        </IndexBase>
    </React.StrictMode>
);
