/*-----------------------------------------------
    Generated by SwaggerToTS
    Do not customize this file, update the generator
    Copyright C Space 2024
-----------------------------------------------*/
/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from 'axios';
import { appId } from '../azure/auth';
import {
    getAuthToken,
    clientApiScope,
    clientApiUri
} from '../azure/auth';
import {
    AccountViewModelPagedList,
    AccountStatus,
    AccountType,
    GlobalAccountOrder,
    AccountFetchListParams,
    AccountDetailModel,
    AccountTeamEmailUpdateModel,
} from './ClientApiTypes';

/**
 * Returns a paged list of Accounts
*/
export async function accountFetchList(input: AccountFetchListParams): Promise<AccountViewModelPagedList> { 
    const token = await getAuthToken([clientApiScope]);
    const q: string[] = [];
    if (input.clientId) { q.push(`clientId=${encodeURIComponent(input.clientId)}`); }
    if (input.accountId) { q.push(`accountId=${encodeURIComponent(input.accountId)}`); }
    if (input.idList) { q.push(...input.idList.map(x => `idList=${encodeURIComponent(x)}`)); }
    if (input.accountStatus) { q.push(`accountStatus=${encodeURIComponent(input.accountStatus)}`); }
    if (input.accountType) { q.push(`accountType=${encodeURIComponent(input.accountType)}`); }
    if (input.filter) { q.push(`filter=${encodeURIComponent(input.filter)}`); }
    if (input.forUserId) { q.push(`forUserId=${encodeURIComponent(input.forUserId)}`); }
    if (input.page) { q.push(`page=${encodeURIComponent(input.page)}`); }
    if (input.pageSize) { q.push(`pageSize=${encodeURIComponent(input.pageSize)}`); }
    if (input.orderBy) { q.push(`orderBy=${encodeURIComponent(input.orderBy)}`); }
    const p = q.length > 0 ? `?${q.join('&')}` : '';
    const resp = await axios.get<AccountViewModelPagedList>(
        `${clientApiUri}/Account${p}`,
        { headers: { 
            'authorization': `bearer ${token}`,
			'cspace-appId': `${appId}` 
        } });
    return resp.data;
}

/**
 * Returns Account for given ID
*/
export async function accountFetchById(id: string): Promise<AccountDetailModel> { 
    const token = await getAuthToken([clientApiScope]);
    
    const resp = await axios.get<AccountDetailModel>(
        `${clientApiUri}/Account/${id}`,
        { headers: { 
            'authorization': `bearer ${token}`,
			'cspace-appId': `${appId}` 
        } });
    return resp.data;
}

/**
 * Returns information about the account by name
*/
export async function accountFetchByName(client: string, account: string): Promise<AccountDetailModel> { 
    const token = await getAuthToken([clientApiScope]);
    
    const resp = await axios.get<AccountDetailModel>(
        `${clientApiUri}/Account/info/${client}/${account}`,
        { headers: { 
            'authorization': `bearer ${token}`,
			'cspace-appId': `${appId}` 
        } });
    return resp.data;
}

/**
 * Saves new Team Email to account
*/
export async function accountFetchTeamEmail(clientId: string, accountId: string, input: AccountTeamEmailUpdateModel): Promise<AccountViewModelPagedList> { 
    const token = await getAuthToken([clientApiScope]);
    const resp = await axios.post<AccountViewModelPagedList>(
        `${clientApiUri}/Account/${clientId}/${accountId}/teamEmail`,
            input,
        { headers: { 
            'authorization': `bearer ${token}`,
			'cspace-appId': `${appId}`  
        } });
    return resp.data;
}

/**
 * Fetches the value for ShowChartN for a given account id
*/
export async function fetchShowChartN(clientId: string, accountId: string): Promise<boolean> { 
    const token = await getAuthToken([clientApiScope]);
    
    const resp = await axios.get<boolean>(
        `${clientApiUri}/Account/${clientId}/${accountId}/fetchShowChartN`,
        { headers: { 
            'authorization': `bearer ${token}`,
			'cspace-appId': `${appId}` 
        } });
    return resp.data;
}

/**
 * Toggles the value for ShowChartN for the Account
*/
export async function toggleShowChartN(clientId: string, accountId: string): Promise<boolean> { 
    const token = await getAuthToken([clientApiScope]);
    const resp = await axios.put<boolean>(
        `${clientApiUri}/Account/${clientId}/${accountId}/toggleShowChartN`,
            {},
        { headers: {
            'authorization': `bearer ${token}`,
			'cspace-appId': `${appId}`
        } });
    return resp.data;
}

