import React from 'react';
import { PlusCircleIcon } from '@heroicons/react/24/solid';
import Button from '../../../common/ButtonNew';

type Props = {
    title: string;
    onClick: () => void;
    label: string;
    disabled?: boolean;
};

export default function SettingsFinancialsAddWidget({ title, onClick, label, disabled = false }: Props) {
    return (
        <div className="flex h-[307px] flex-col justify-between rounded-sm bg-white px-6 py-8">
            <div className="mb-6">
                <div className="mb-3 text-xl font-medium leading-6">{title}</div>
                <div className="w-full border-t border-primary-50" />
            </div>
            <div
                {...(!disabled && { onClick: onClick })}
                className={`${
                    disabled ? 'cursor-not-allowed' : 'cursor-pointer hover:bg-primary-50'
                } group flex flex-col items-center justify-center gap-4 rounded border-2 border-dashed border-primary-100 bg-background p-10 transition-colors`}
            >
                <PlusCircleIcon
                    className={`h-12 w-12 text-primary-500 transition-transform ${
                        !disabled && 'group-hover:scale-125'
                    }`}
                />
                <Button className="pointer-events-none w-full" buttonStyle="secondary" buttonSize="xs">
                    <span>{label}</span>
                </Button>
            </div>
        </div>
    );
}
