/*-----------------------------------------------
    Generated by SwaggerToTS
    Do not customize this file, update the generator
    Copyright C Space 2024
-----------------------------------------------*/
/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from 'axios';
import { appId } from '../azure/auth';
import {
    getAuthToken,
    clientApiScope,
    clientApiUri
} from '../azure/auth';
import {
    FinancialsWidgetViewModel,
    FinancialsWidgetCreateModel,
} from './ClientApiTypes';

/**
 * Get widgets
*/
export async function financialsWidgetFetchList(clientId: string, accountId: string): Promise<FinancialsWidgetViewModel[]> { 
    const token = await getAuthToken([clientApiScope]);
    
    const resp = await axios.get<FinancialsWidgetViewModel[]>(
        `${clientApiUri}/FinancialsWidget/${clientId}/${accountId}`,
        { headers: { 
            'authorization': `bearer ${token}`,
			'cspace-appId': `${appId}` 
        } });
    return resp.data;
}

/**
 * Create a new widget
*/
export async function financialsWidgetCreate(clientId: string, accountId: string, input: FinancialsWidgetCreateModel): Promise<FinancialsWidgetViewModel> { 
    const token = await getAuthToken([clientApiScope]);
    const resp = await axios.post<FinancialsWidgetViewModel>(
        `${clientApiUri}/FinancialsWidget/${clientId}/${accountId}`,
            input,
        { headers: { 
            'authorization': `bearer ${token}`,
			'cspace-appId': `${appId}`  
        } });
    return resp.data;
}

/**
 * Delete a widget
*/
export async function financialsWidgetDeleteById(clientId: string, accountId: string, widgetId: string): Promise<void> { 
    const token = await getAuthToken([clientApiScope]);
    const resp = await axios.delete(
        `${clientApiUri}/FinancialsWidget/${clientId}/${accountId}/${widgetId}`,
        { headers: { 
            'authorization': `bearer ${token}`,
			'cspace-appId': `${appId}`  
        } });
    return resp.data;
}

/**
 * Update a widget
*/
export async function financialsWidgetSave(clientId: string, accountId: string, widgetId: string, input: FinancialsWidgetCreateModel): Promise<FinancialsWidgetViewModel> { 
    const token = await getAuthToken([clientApiScope]);
    const resp = await axios.put<FinancialsWidgetViewModel>(
        `${clientApiUri}/FinancialsWidget/${clientId}/${accountId}/${widgetId}`,
            input,
        { headers: {
            'authorization': `bearer ${token}`,
			'cspace-appId': `${appId}`
        } });
    return resp.data;
}

