import React from 'react';
import { CatProfileField } from '../../../common/clientApi/ClientApiTypes';
import { Checkbox, CheckboxReadonly } from '../../../common/styles/FormStyles';

type Props = {
    pf: CatProfileField;
    isChecked: boolean;
    isDisabled: boolean;
    onSelected: (pf: CatProfileField) => void;
    onUnselected: (pf: CatProfileField) => void;
};

export default function CommunityConfigurationCheckbox({ pf, isChecked, isDisabled, onSelected, onUnselected }: Props) {
    const clickHandler = () => {
        if (isDisabled) {
            return;
        }
        if (isChecked) {
            onUnselected(pf);
        } else {
            onSelected(pf);
        }
    };

    return (
        <td className="text-center">
            <input
                type="checkbox"
                className={isDisabled ? CheckboxReadonly : Checkbox}
                aria-label={`Click to select ${pf.profileLabel}`}
                checked={isChecked}
                readOnly={isDisabled}
                onChange={clickHandler}
            />
        </td>
    );
}
