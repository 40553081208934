/*-----------------------------------------------
    Generated by PermissionsToTS
    Do not customize this file, update the generator
    Copyright C Space 2024
-----------------------------------------------*/

/**
 * Client Account Manager
*/
export const accountCanManage = 'Client_Account_CanManage';

/**
 * Client Account Viewer
*/
export const accountCanView = 'Client_Account_CanView';

/**
 * Can create and edit charts
*/
export const accountChartCanEdit = 'Account_Chart_CanEdit';

/**
 * Can view charts
*/
export const accountChartCanView = 'Account_Chart_CanView';

/**
 * Can edit and delete activities associated with a project
*/
export const accountClientProjectActivityCanDelete = 'Account_ClientProjectActivity_CanDelete';

/**
 * Can view and select activity hyperlink when not toggled to Visible
*/
export const accountClientProjectActivityCanViewHiddenUrl = 'Account_ClientProjectActivity_CanViewHiddenURL';

/**
 * Can view the content within a project folder
*/
export const accountClientProjectFolderCanView = 'Account_ClientProjectFolder_CanView';

/**
 * Allows deletion of files
*/
export const accountClientProjectMediaCanDelete = 'Account_ClientProjectMedia_CanDelete';

/**
 * Can edit and publish team email address to an account
*/
export const accountFetchTeamEmailCanEdit = 'Account_FetchTeamEmail_CanEdit';

/**
 * Can edit the project sequence portion of the Project ID
*/
export const accountClientProjectIdCanEdit = 'Account_ClientProjectID_CanEdit';

/**
 * Can create, modify, delete, and publish financial charts and widgets
*/
export const accountFinancialsWidgetCanEdit = 'Account_FinancialsWidget_CanEdit';

/**
 * Can view financial charts and widgets such as work/project budget, other budgets etc
*/
export const accountFinancialsWidgetCanView = 'Account_FinancialsWidget_CanView';

/**
 * Allow deleting external account related documents
*/
export const accountDocumentExternalCanDelete = 'Account_Document_External_CanDelete';

/**
 * Allow managing external account related documents
*/
export const accountDocumentExternalCanManage = 'Account_Document_External_CanManage';

/**
 * Allow viewing external account related documents
*/
export const accountDocumentExternalCanView = 'Account_Document_External_CanView';

/**
 * Allow deleting internal account related documents
*/
export const accountDocumentInternalCanDelete = 'Account_Document_Internal_CanDelete';

/**
 * Allow managing internal account related documents
*/
export const accountDocumentInternalCanManage = 'Account_Document_Internal_CanManage';

/**
 * Allow viewing internal account related documents
*/
export const accountDocumentInternalCanView = 'Account_Document_Internal_CanView';

/**
 * Can export Audience and Financial charts to Powerpoint
*/
export const accountPowerpointExportCanEdit = 'Account_PowerpointExport.CanEdit';

/**
 * Can view the Settings page
*/
export const accountSettingsCanView = 'Account_Settings_CanView';

/**
 * Can edit and remove team members to spotlight them for a given project
*/
export const accountTeamMemberCanEdit = 'Account_TeamMember_CanEdit';

/**
 * Can view team members added to an account
*/
export const accountTeamMemberCanView = 'Account_TeamMember_CanView';

/**
 * Can create activities that are associated with a project
*/
export const activityCanCreate = 'Account_ClientProjectActivity_CanCreate';

/**
 * Can view activities that are linked to a project
*/
export const activityCanView = 'Account_ClientProjectActivity_CanView';

/**
 * Can select and unselect which audience charts can be displayed on the audience page and within the composition dashboard
*/
export const audienceChartsCanManage = 'Account_AudienceChart_CanEdit';

/**
 * Can create an "Include different audience" request
*/
export const audienceRequestCanCreate = 'Account_AudienceRequest_CanCreate';

/**
 * Audiences: Can View
*/
export const audiencesCanView = 'Account_Audiences_CanView';

/**
 * Can create and edit all details of project brief (excluding project phase, status, and deliverable date fields)
*/
export const clientProjectCanManage = 'Account_ClientProject_CanManage';

/**
 * Can view a given project
*/
export const clientProjectCanView = 'Account_ClientProject_CanView';

/**
 * Allow deleting external client project related documents
*/
export const clientProjectDocumentExternalCanDelete = 'Account_ClientProjectDocument_External_CanDelete';

/**
 * Allow managing external client project related documents
*/
export const clientProjectDocumentExternalCanManage = 'Account_ClientProjectDocument_External_CanManage';

/**
 * Allow viewing external client project related documents
*/
export const clientProjectDocumentExternalCanView = 'Account_ClientProjectDocument_External_CanView';

/**
 * Allow deleting internal client project related documents
*/
export const clientProjectDocumentInternalCanDelete = 'Account_ClientProjectDocument_Internal_CanDelete';

/**
 * Allow managing internal client project related documents
*/
export const clientProjectDocumentInternalCanManage = 'Account_ClientProjectDocument_Internal_CanManage';

/**
 * Allow viewing internal client project related documents
*/
export const clientProjectDocumentInternalCanView = 'Account_ClientProjectDocument_Internal_CanView';

/**
 * Can edit the project phase, status, and deliverable date
*/
export const clientProjectPhaseCanManage = 'Account_ClientProjectPhase_CanEdit';

/**
 * Can view the project phase, status, and deliverable date
*/
export const clientProjectPhaseCanView = 'Account_ClientProjectPhase_CanView';

/**
 * DataProvider: Can View
*/
export const dataProviderCanView = 'Account_DataProvider_CanView';

/**
 * Can manage project folders (i.e. create, delete, name)
*/
export const foldersCanManage = 'Account_ClientProjectFolder_CanManage';

/**
 * Account activity Url: Can View
*/
export const hiddenUrlCanView = 'Account_Activity_CanViewHiddenUrl';

/**
 * Can select which marketing content to be published throughout the various Partner Portal pages
*/
export const marketingContentSettingCanEdit = 'Account_MarketingContentSettings_CanEdit';

/**
 * Can view the marketing content selected to be published throughout the various Partner Portal pages
*/
export const marketingContentSettingCanView = 'Account_MarketingContentSettings_CanView';

/**
 * Media: Can Upload Content
*/
export const mediaCanCreateContent = 'Account_Media_CanUpload_Content';

/**
 * Media: Can Upload Member Contribution
*/
export const mediaCanCreateContribution = 'Account_Media_CanUpload_Contribution';

/**
 * Media: Can Upload Theme Hero
*/
export const mediaCanCreateHero = 'Account_Media_CanUpload_Hero';

/**
 * Media: Can Upload Theme Masthead
*/
export const mediaCanCreateMasthead = 'Account_Media_CanUpload_Masthead';

/**
 * Media: Can Upload Profile
*/
export const mediaCanCreateProfile = 'Account_Media_CanUpload_Profile';

/**
 * Media: Can Upload Tile Image
*/
export const mediaCanCreateTile = 'Account_Media_CanUpload_Tile';

/**
 * Allows Allows deletion of files
*/
export const mediaCanDelete = 'Account_Media_CanDelete';

/**
 * Allows upload and download of media files
*/
export const mediaCanEdit = 'Account_ClientProjectMedia_CanEdit';

/**
 * Allows viewing of media (i.e. uploaded file: image, video, office, pdf etc)
*/
export const mediaCanView = 'Account_ClientProjectMedia_CanView';

/**
 * Can download PowerPoints
*/
export const powerPointCanView = 'Account_PowerpointExport_CanCreate';

/**
 * Allows receiving client project related notifications.
*/
export const projectNotificationCanReceive = 'Account_Notification_ClientProject_CanReceive';

/**
 * Allows users to receive notifications related to financial charts.
*/
export const notificationFinancialsCanReceive = 'Account_Notification_Financials_CanReceive';

/**
 * Allows users to receive notifications related to account.
*/
export const accountTeamUpdateNotificationCanReceive = 'Account_Notification_AccountTeamUpdate_CanReceive';

/**
 * Allows users to receive notifications related to account internal files.
*/
export const accountNotificationInternalFilesCanReceive = 'Account_Notification_InternalFiles_CanReceive';

/**
 * Allows users to receive notifications related to account shared files.
*/
export const accountNotificationSharedFilesCanReceive = 'Account_Notification_SharedFiles_CanReceive';

/**
 * Theme: Can Edit Custom CSS
*/
export const themeCanCustomize = 'Account_Theme_CanCustomize';

/**
 * Theme: Can Edit
*/
export const themeCanManage = 'Account_Theme_CanManage';

/**
 * User: Can view
*/
export const userCanView = 'Account_User_CanView';

