import React, { useState } from 'react';
import UnsavedChangesModal from '../../../common/UnsavedChangesModal';
import AudiencesPanel from './components/panels/AudiencesPanel';
import PartnershipEventPanel from './components/panels/PartnershipEventPanel';
import PartnershipHeroPanel from './components/panels/PartnershipHeroPanel';
import ProjectsPanel from './components/panels/ProjectsPanel';
import { useUnsavedChanges } from '../../../common/hooks/useUnsavedChanges';
import ErrorDialog from '../../../common/ErrorDialog';

interface IDictionary {
    [key: string]: boolean;
}

export default function MarketingContent() {
    const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [unsavedChangesSectionFlags, setUnsavedChangesSectionFlags] = useState<IDictionary>({
        projects: false,
        audiences: false,
        partnershipHero: false,
        partnershipEvent: false,
    });
    const setShowUnsavedChangesModalHandler = (section: string) => {
        return (isShow: boolean) => {
            unsavedChangesSectionFlags[section] = isShow;
            setUnsavedChangesSectionFlags(unsavedChangesSectionFlags);
            setShowUnsavedChangesModal(Object.values(unsavedChangesSectionFlags).some((item) => item));
        };
    };
    const { showPrompt, confirmNavigation, cancelNavigation } = useUnsavedChanges(showUnsavedChangesModal);

    const errorHandler = (widgetTitle: string, action: 'publishing' | 'unpublishing') => {
        setErrorMessage(`There was an error ${action} the ${widgetTitle} content. Please try again.`);
    };

    return (
        <>
            {errorMessage && (
                <ErrorDialog onClose={() => setErrorMessage(null)}>
                    <>{errorMessage}</>
                </ErrorDialog>
            )}
            <div className="mx-16 mb-12">
                <div>
                    <ProjectsPanel
                        onError={errorHandler}
                        setShowUnsavedChangesModal={setShowUnsavedChangesModalHandler('projects')}
                    />
                </div>
                <div className="mt-16">
                    <AudiencesPanel
                        onError={errorHandler}
                        setShowUnsavedChangesModal={setShowUnsavedChangesModalHandler('audiences')}
                    />
                </div>
                <div className="mt-16">
                    <PartnershipHeroPanel
                        onError={errorHandler}
                        setShowUnsavedChangesModal={setShowUnsavedChangesModalHandler('partnershipHero')}
                    />
                </div>
                <div className="mt-1">
                    <PartnershipEventPanel
                        onError={errorHandler}
                        setShowUnsavedChangesModal={setShowUnsavedChangesModalHandler('partnershipEvent')}
                    />
                </div>
                <UnsavedChangesModal
                    showDialog={showPrompt as boolean}
                    confirmNavigation={confirmNavigation}
                    cancelNavigation={cancelNavigation}
                />
            </div>
        </>
    );
}
