/*-----------------------------------------------
    Generated by SwaggerToTS
    Do not customize this file, update the generator
    Copyright C Space 2024
-----------------------------------------------*/
/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from 'axios';
import { appId } from '../azure/auth';
import {
    getAuthToken,
    clientApiScope,
    clientApiUri
} from '../azure/auth';
import {
    CatProfileFieldPagedList,
    CatProfileFieldOrder,
    CatProfileFieldFetchListParams,
    CatProfileField,
    CatProfileFieldMetrics,
} from './ClientApiTypes';

/**
 * Returns a paged list of Catalyst ProfileFields
*/
export async function catProfileFieldFetchList(clientId: string, accountId: string, accountDataProviderId: string, input: CatProfileFieldFetchListParams): Promise<CatProfileFieldPagedList> { 
    const token = await getAuthToken([clientApiScope]);
    const q: string[] = [];
    if (input.filter) { q.push(`filter=${encodeURIComponent(input.filter)}`); }
    if (input.isPublished) { q.push(`isPublished=${encodeURIComponent(input.isPublished)}`); }
    if (input.isRemoved) { q.push(`isRemoved=${encodeURIComponent(input.isRemoved)}`); }
    if (input.isReportPreferred) { q.push(`isReportPreferred=${encodeURIComponent(input.isReportPreferred)}`); }
    if (input.isMemberField) { q.push(`isMemberField=${encodeURIComponent(input.isMemberField)}`); }
    if (input.isRequired) { q.push(`isRequired=${encodeURIComponent(input.isRequired)}`); }
    if (input.isExportable) { q.push(`isExportable=${encodeURIComponent(input.isExportable)}`); }
    if (input.profileFieldDataType) { q.push(...input.profileFieldDataType.map(x => `profileFieldDataType=${encodeURIComponent(x)}`)); }
    if (input.page) { q.push(`page=${encodeURIComponent(input.page)}`); }
    if (input.pageSize) { q.push(`pageSize=${encodeURIComponent(input.pageSize)}`); }
    if (input.orderBy) { q.push(`orderBy=${encodeURIComponent(input.orderBy)}`); }
    const p = q.length > 0 ? `?${q.join('&')}` : '';
    const resp = await axios.get<CatProfileFieldPagedList>(
        `${clientApiUri}/Catalyst/ProfileField/${clientId}/${accountId}/${accountDataProviderId}${p}`,
        { headers: { 
            'authorization': `bearer ${token}`,
			'cspace-appId': `${appId}` 
        } });
    return resp.data;
}

/**
 * Returns a Catalyst ProfileFields
*/
export async function catProfileFieldFetchById(clientId: string, accountId: string, accountDataProviderId: string, profileFieldId: string): Promise<CatProfileField> { 
    const token = await getAuthToken([clientApiScope]);
    
    const resp = await axios.get<CatProfileField>(
        `${clientApiUri}/Catalyst/ProfileField/${clientId}/${accountId}/${accountDataProviderId}/${profileFieldId}`,
        { headers: { 
            'authorization': `bearer ${token}`,
			'cspace-appId': `${appId}` 
        } });
    return resp.data;
}

/**
 * Returns a Catalyst ProfileFields
*/
export async function catProfileFieldFetchMetricsById(clientId: string, accountId: string, accountDataProviderId: string, profileFieldId: string): Promise<CatProfileFieldMetrics> { 
    const token = await getAuthToken([clientApiScope]);
    
    const resp = await axios.get<CatProfileFieldMetrics>(
        `${clientApiUri}/Catalyst/ProfileField/${clientId}/${accountId}/${accountDataProviderId}/${profileFieldId}/metrics`,
        { headers: { 
            'authorization': `bearer ${token}`,
			'cspace-appId': `${appId}` 
        } });
    return resp.data;
}

