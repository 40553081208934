import TeamEmailAddress from './TeamEmailAddress';
import TeamMembers from './TeamMembers';

export default function TeamMembersContainer() {
    return (
        <div className="mx-16 flex justify-between gap-8">
            <TeamMembers />
            <TeamEmailAddress />
        </div>
    );
}
