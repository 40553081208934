import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { clientRoutes } from './clientRoutes';
import * as Tenant from './Tenant';
import { accountFetchByName as fetchInfo } from '../common/clientApi/AccountApi';
import { useEffect, useState } from 'react';
import { PageNotFound, ServerFailure } from '../common/ErrorPage';
import { AxiosError } from 'axios';
import { getApiErrorStatus } from '../common/utils';
import { ApiResultError } from '../common/clientApi/ClientApiTypes';
import LoadingFullScreen from '../common/components/LoadingFullScreen';
import ErrorBoundary from '../common/ErrorBoundary';

const router = createBrowserRouter(clientRoutes);

export default function App() {
    const [tenant, setTenant] = useState<string>('');
    const [showNotFound, setShowNotFound] = useState<boolean>(false);
    const [showError, setShowError] = useState<boolean>(false);
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
            },
        },
    });

    useEffect(() => {
        const exec = async () => {
            // look for tenant params
            const url = window.location.pathname;
            const pathParts = Tenant.extractTenantParams(url);

            if (pathParts.length >= 2) {
                const [clientName, accountName] = pathParts;

                try {
                    const info = await fetchInfo(clientName, accountName);
                    if (info) {
                        Tenant.updateViaAccount(info);
                        setTenant(`${Tenant.clientId}-${Tenant.accountId}`);
                        return;
                    } else {
                        setShowNotFound(true);
                    }
                } catch (ex) {
                    const error = ex as AxiosError<ApiResultError>;
                    if (error) {
                        const status = getApiErrorStatus(error);
                        if (status === 404) {
                            setShowNotFound(true);
                            return;
                        }
                    }
                    setShowError(true);
                }
            }

            // if here, then the tenant params are not in the url
            setTenant('default');
        };

        exec();
    }, []);

    if (showNotFound) {
        return <PageNotFound />;
    }

    if (showError) {
        return <ServerFailure />;
    }

    if (!tenant) {
        return <LoadingFullScreen />;
    }

    return (
        <ErrorBoundary>
            <QueryClientProvider client={queryClient}>
                <RouterProvider router={router} />
                <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
        </ErrorBoundary>
    );
}
