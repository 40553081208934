import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { accountDataProviderFetchList } from '../../common/clientApi/AccountDataProviderApi';
import {
    AccountDataProviderFetchListParams,
    AccountDataProviderViewModel,
    AccountDataProviderViewModelPagedList,
    AudienceChartFetchListParams,
    AudienceChartViewModelPagedList,
} from '../../common/clientApi/ClientApiTypes';
import { Loading } from '../../common/components/form/Form';
import * as FormStyles from '../../common/styles/FormStyles';
import { accountCanView } from '../../common/clientApi/AccountPermissions';
import { PermissionDenied, WithAccountPermission } from '../../common/userPermission';
import { audienceChartFetchList } from '../../common/clientApi/AudienceChartApi';
import { getDataProviderWithChartCounts, DataProviderWithChartCount } from './AudienceUtils';

type CommunitySelectParams = {
    clientId: string;
    accountId: string;
    defaultDataProviderId?: string;
    onSelected: (dataProvider: AccountDataProviderViewModel) => void;
    onIsEmpty?: () => void;
    isCompositionPanel?: boolean;
};

const getLastDataProviderId = (localStorageKey: string, defaultDataProviderId?: string) => {
    if (defaultDataProviderId) {
        return defaultDataProviderId.toString();
    }

    const savedView: string | null = localStorage.getItem(localStorageKey);
    return savedView;
};

const saveLastDataProviderId = (localStorageKey: string, id: string) => {
    localStorage.setItem(localStorageKey, id);
};

export function CommunitySelect(params: CommunitySelectParams) {
    const { clientId, accountId, defaultDataProviderId, isCompositionPanel } = params;
    const localStorageKey = `lastDataProvider_${clientId}_${accountId}`;
    const [dataProviderId, setDataProviderId] = useState<string | null>(
        getLastDataProviderId(localStorageKey, defaultDataProviderId)
    );
    const [dataProviders, setDataProviders] = useState<DataProviderWithChartCount[]>([]);

    const chartParams: AudienceChartFetchListParams = {
        type: 'Audience',
        status: 'Active',
        orderBy: 'NameAsc',
    };

    const selectCommunityFromData = (data: AccountDataProviderViewModelPagedList) => {
        if (data.list && data.list.length > 0) {
            if (dataProviderId) {
                const p = data.list.find((x) => x.accountDataProviderId == dataProviderId);
                if (p) {
                    params.onSelected(p);
                    return;
                }
            }

            params.onSelected(data.list[0]);
        }
    };

    const filters: AccountDataProviderFetchListParams = {
        pageSize: '0' as unknown as number,
    };
    const execFetchDataProviders = async (): Promise<AccountDataProviderViewModelPagedList> => {
        const data = await accountDataProviderFetchList(accountId, filters);
        selectCommunityFromData(data);

        return data;
    };

    const execFetchAudienceCharts = async () => {
        const data = await audienceChartFetchList(clientId, accountId, chartParams);
        if (data && data.list && dataProviderData && dataProviderData.list) {
            const charts = data.list;
            let dpsWithCharts = getDataProviderWithChartCounts(dataProviderData.list, charts);

            if (dpsWithCharts.filter((dp) => dp.chartCount !== 0).length === 0 && params.onIsEmpty) {
                params.onIsEmpty();
            }

            if (isCompositionPanel) {
                dpsWithCharts = dpsWithCharts.filter((dp) => dp.chartCount !== 0);
            }

            setDataProviders(dpsWithCharts);

            if (dpsWithCharts.length) {
                const dp = dpsWithCharts[0];

                if (isCompositionPanel && dp) {
                    setDataProviderId(dp.accountDataProviderId);
                    params.onSelected(dp);
                }
            }
        }
        return data;
    };

    const { isLoading: isLoadingProviders, data: dataProviderData } = useQuery<AccountDataProviderViewModelPagedList>({
        queryKey: ['AccountDataProviders', clientId, accountId],
        queryFn: () => execFetchDataProviders(),
    });

    const { isLoading: isLoadingCharts } = useQuery<AudienceChartViewModelPagedList, Error>({
        queryKey: ['AudienceCharts', clientId, accountId],
        queryFn: () => execFetchAudienceCharts(),
        enabled: !!dataProviderData,
    });

    const communitySelectedHandler = (evt: React.FormEvent<HTMLSelectElement>) => {
        evt.preventDefault();
        if (dataProviderData && dataProviderData.list) {
            const id = evt.currentTarget.value;
            const dp = dataProviderData.list.find((x) => x.accountDataProviderId === id);
            if (dp) {
                saveLastDataProviderId(localStorageKey, dp.accountDataProviderId);
                setDataProviderId(dp.accountDataProviderId);
                params.onSelected(dp);
            }
        }
    };

    const isLoading = isLoadingProviders || isLoadingCharts;

    if (!isLoading && dataProviders.length < 2) {
        return <></>;
    }

    return (
        <>
            <Loading isLoading={isLoading} />
            {!isLoading && !!dataProviders && (
                <WithAccountPermission permission={accountCanView} permissionDeniedElem={<PermissionDenied />}>
                    <select
                        className={FormStyles.Input}
                        aria-label="Select a community"
                        value={dataProviderId ?? undefined}
                        onChange={communitySelectedHandler}
                    >
                        {dataProviders.map((x) => (
                            <option key={x.accountDataProviderId} value={x.accountDataProviderId}>
                                {x.name}
                            </option>
                        ))}
                    </select>
                </WithAccountPermission>
            )}
        </>
    );
}
