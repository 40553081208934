import NukaCarousel from 'nuka-carousel';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import { useState } from 'react';
import { classNames, generateKey } from '../utils';
import AudienceMarketingMaterialTile from './AudienceMarketingMaterialTile';

export type Props = {
    items: CarouselItem[];
};

export interface CarouselItem {
    title: string;
    description: string;
    linkTitle: string;
    linkUrl: string;
    backgroundMediaId: string;
}

export default function Carousel({ items }: Props) {
    const [currentSlide, setCurrentSlide] = useState(0);

    const onNext = () => {
        setCurrentSlide((oldCurrentSlide) => oldCurrentSlide + 1);
    };

    const onPrev = () => {
        setCurrentSlide((oldCurrentSlide) => oldCurrentSlide - 1);
    };

    const updateCurrentSlide = (index: number) => {
        if (currentSlide !== index) {
            setCurrentSlide(index);
        }
    };

    if (!items.length) return null;

    const isMultipleSlides = items.length > 1;

    return (
        <>
            <div className="mb-5">
                <NukaCarousel
                    slidesToShow={isMultipleSlides ? 1.25 : 1}
                    cellAlign="center"
                    beforeSlide={(_, index) => updateCurrentSlide(index)}
                    slideIndex={currentSlide}
                    withoutControls={!isMultipleSlides}
                    wrapAround={isMultipleSlides}
                    dragging={isMultipleSlides}
                    className={classNames(isMultipleSlides && 'cursor-grab active:cursor-grabbing')}
                    renderCenterRightControls={({ nextSlide }) => (
                        <button
                            onClick={nextSlide}
                            className="h-[380px] w-12 cursor-pointer bg-[linear-gradient(270deg,#001028_0%,rgba(0,16,40,0.00)_87.77%)]"
                        />
                    )}
                    renderCenterLeftControls={({ previousSlide }) => (
                        <button
                            onClick={previousSlide}
                            className="h-[380px] w-12 cursor-pointer bg-[linear-gradient(90deg,#001028_0%,rgba(0,16,40,0.00)_87.77%)]"
                        />
                    )}
                    renderBottomCenterControls={null}
                >
                    {items.map((x) => (
                        <AudienceMarketingMaterialTile
                            key={generateKey()}
                            tileMediaId={x.backgroundMediaId}
                            description={x.description}
                            linkUrl={x.linkUrl}
                            linkTitle={x.linkTitle}
                            title={x.title}
                        />
                    ))}
                </NukaCarousel>
            </div>
            {isMultipleSlides && (
                <div className="flex justify-between border-t border-primary-50 pt-2">
                    <div className="text-sm font-medium uppercase text-black">
                        {currentSlide + 1} / {items.length}
                    </div>
                    <div className="flex">
                        <button
                            className="mr-4 flex h-9 w-9 cursor-pointer items-center justify-center rounded-full bg-background hover:bg-cs-gray"
                            onClick={onPrev}
                        >
                            <ChevronLeftIcon width={18} height={18} color="#01357c" />
                        </button>
                        <button
                            className="flex h-9 w-9 cursor-pointer items-center justify-center rounded-full bg-background hover:bg-cs-gray"
                            onClick={onNext}
                        >
                            <ChevronRightIcon width={18} height={18} color="#01357c" />
                        </button>
                    </div>
                </div>
            )}
        </>
    );
}
