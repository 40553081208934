import { useLocation } from "react-router-dom";
import { GlobalUserFetchListParams, 
    TeamMemberFetchListParams, 
    TeamMemberFilterOrder,
    TeamMemberViewModel,
    UserLoginTypeList,
    UserPageOrder,
    UserTypeList,
    UserViewModel} from "../../../common/clientApi/ClientApiTypes";
import React from "react";
import { TeamMemberTileViewModel } from "./types";

export function useQueryStringTM(): TeamMemberFetchListParams {
    const { search } = useLocation();
    const r = React.useMemo(() => new URLSearchParams(search), [search]);

    return {
        orderBy: r.get('orderBy') as TeamMemberFilterOrder | null ?? 'TitleAsc',
        page: parseInt(r.get('page') ?? '', 10) ?? 1,
    };
}

export function useQueryStringUser(): GlobalUserFetchListParams {
    const { search } = useLocation();
    const r = React.useMemo(() => new URLSearchParams(search), [search]);
    return {
        orderBy: (r.get('orderBy') || 'DisplayNameDesc') as UserPageOrder,
        page: parseInt(r.get('page') ?? '', 10) ?? 1,
        pageSize: 1000
    };
}

export function teamMemberTiles(members: TeamMemberViewModel[], users: UserViewModel[]): TeamMemberTileViewModel[] {
    const result: TeamMemberTileViewModel[] = [];
    users.forEach(user => {
        const member = members.find(x => x.userId == user.userId);
        if (member) {
            result.push({...member, displayName: user?.displayName ?? ''})
        }
    });
    return result;
}

export function getModalList(users: UserViewModel[], members: TeamMemberViewModel[]): UserViewModel[] {
    const memberIds = members.map(m => m.userId);
    const list = users.filter(u => !memberIds.includes(u.userId));

    // adding placeholder option
    const placeHolder = {
        userId: "",
        displayName: "--Select User--",
        userLoginType: UserLoginTypeList[0],
        userType: UserTypeList[0]
    };

    list.unshift(placeHolder);
    return list;
}
