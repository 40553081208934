/*-----------------------------------------------
    Generated by SwaggerToTS
    Do not customize this file, update the generator
    Copyright C Space 2024
-----------------------------------------------*/
/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from 'axios';
import { appId } from '../azure/auth';
import {
    getAuthToken,
    clientApiScope,
    clientApiUri
} from '../azure/auth';
import {
    MarketingContentSettingViewModel,
    MarketingContentSettingType,
    MarketingContentSettingsFetchListParams,
    MarketingContentSettingCreateModel,
    PartnershipHeroTileViewModel,
    PartnershipEventTileViewModel,
    ProjectsPageMarketingTileViewModel,
    AudienceMarketingContentTileViewModel,
} from './ClientApiTypes';

/**
 * Returns a list of MarketingContentSettings
*/
export async function marketingContentSettingsFetchList(clientId: string, accountId: string, input: MarketingContentSettingsFetchListParams): Promise<MarketingContentSettingViewModel[]> { 
    const token = await getAuthToken([clientApiScope]);
    const q: string[] = [];
    if (input.type) { q.push(`type=${encodeURIComponent(input.type)}`); }
    const p = q.length > 0 ? `?${q.join('&')}` : '';
    const resp = await axios.get<MarketingContentSettingViewModel[]>(
        `${clientApiUri}/MarketingContentSetting/${clientId}/${accountId}${p}`,
        { headers: { 
            'authorization': `bearer ${token}`,
			'cspace-appId': `${appId}` 
        } });
    return resp.data;
}

/**
 * Creates a new MarketingContentSetting.
*/
export async function marketingContentSettingsSave(clientId: string, accountId: string, input: MarketingContentSettingCreateModel): Promise<MarketingContentSettingViewModel[]> { 
    const token = await getAuthToken([clientApiScope]);
    const resp = await axios.post<MarketingContentSettingViewModel[]>(
        `${clientApiUri}/MarketingContentSetting/${clientId}/${accountId}`,
            input,
        { headers: { 
            'authorization': `bearer ${token}`,
			'cspace-appId': `${appId}`  
        } });
    return resp.data;
}

/**
 * Delete MarketingContentSetting.
*/
export async function marketingContentSettingsDelete(clientId: string, accountId: string, contentType: string): Promise<void> { 
    const token = await getAuthToken([clientApiScope]);
    const resp = await axios.delete(
        `${clientApiUri}/MarketingContentSetting/${clientId}/${accountId}/${contentType}`,
        { headers: { 
            'authorization': `bearer ${token}`,
			'cspace-appId': `${appId}`  
        } });
    return resp.data;
}

/**
 * Returns current active PartnershipHeroTile
*/
export async function marketingContentHeroFetchList(clientId: string, accountId: string): Promise<PartnershipHeroTileViewModel[]> { 
    const token = await getAuthToken([clientApiScope]);
    
    const resp = await axios.get<PartnershipHeroTileViewModel[]>(
        `${clientApiUri}/MarketingContentSetting/${clientId}/${accountId}/MarketingContentHeroList`,
        { headers: { 
            'authorization': `bearer ${token}`,
			'cspace-appId': `${appId}` 
        } });
    return resp.data;
}

/**
 * Returns a list of current active PartnershipEventTile
*/
export async function marketingContentEventFetchList(clientId: string, accountId: string): Promise<PartnershipEventTileViewModel[]> { 
    const token = await getAuthToken([clientApiScope]);
    
    const resp = await axios.get<PartnershipEventTileViewModel[]>(
        `${clientApiUri}/MarketingContentSetting/${clientId}/${accountId}/PartnershipEventTile`,
        { headers: { 
            'authorization': `bearer ${token}`,
			'cspace-appId': `${appId}` 
        } });
    return resp.data;
}

/**
 * Returns a list of current active ProjectsPageMarketingTile
*/
export async function marketingContentProjectFetchList(clientId: string, accountId: string): Promise<ProjectsPageMarketingTileViewModel[]> { 
    const token = await getAuthToken([clientApiScope]);
    
    const resp = await axios.get<ProjectsPageMarketingTileViewModel[]>(
        `${clientApiUri}/MarketingContentSetting/${clientId}/${accountId}/ProjectsPageMarketingTile`,
        { headers: { 
            'authorization': `bearer ${token}`,
			'cspace-appId': `${appId}` 
        } });
    return resp.data;
}

/**
 * Returns a list of current active AudienceMarketingContentTile
*/
export async function audienceMarketingContentFetchList(clientId: string, accountId: string): Promise<AudienceMarketingContentTileViewModel[]> { 
    const token = await getAuthToken([clientApiScope]);
    
    const resp = await axios.get<AudienceMarketingContentTileViewModel[]>(
        `${clientApiUri}/MarketingContentSetting/${clientId}/${accountId}/AudienceMarketingContentTile`,
        { headers: { 
            'authorization': `bearer ${token}`,
			'cspace-appId': `${appId}` 
        } });
    return resp.data;
}

