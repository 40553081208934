import { TrashIcon } from '@heroicons/react/24/outline';
import { PlusIcon } from '@heroicons/react/24/solid';
import React, { useCallback, useMemo, useState } from 'react';
import Button from '../../../../common/ButtonNew';
import Dropdown, { DropdownOption } from '../../../../common/Dropdown';
import ToggleSwitch from '../../../../common/ToggleSwitch';
import {
    FinancialsWidgetChartItemCreateModel,
    FinancialsWidgetCreateModel,
    FinancialsWidgetCurrency,
    FinancialsWidgetType,
    FinancialsWidgetViewModel,
} from '../../../../common/clientApi/ClientApiTypes';
import { ErrorLabel, TextInput } from '../../../../common/components/form/Form';
import { MAX_WIDGET_CATEGORIES_NUM } from '../../../../common/consts/settings';
import { CURRENCIES, FIELD_TOKENS } from '../../../../common/consts/enums';
import { compareByOrdinal, getNextOrdinalNumber } from '../../../../common/utils';
import GraphChartWidgetPreview from './GraphChartWidgetPreview';
import HeaderIndicator from '../../../../common/components/HeaderIndicator';
import { HeaderIndicatorType } from '../../../../common/consts/enums';
import { SingleValue } from 'react-select';
import { useValidation } from '../../../../common/hooks/validation/useValidation';
import {
    GraphChartValidationConfiguration,
    getGraphChartValidationConfiguration,
    VALIDATION_CONSTS,
} from './GraphChartWidgetValidation';
import { isExceeding } from './GraphChartWidgetUtils';

type Props = {
    widgetData: FinancialsWidgetCreateModel | FinancialsWidgetViewModel;
    hasChanged: boolean;
    isReadOnly?: boolean;
    onChange: (data: FinancialsWidgetCreateModel | FinancialsWidgetViewModel) => void;
    onPublish: () => void;
    onRemove: () => void;
    onReset: () => void;
};

export default function GraphChartWidgetContainer({
    widgetData,
    hasChanged,
    isReadOnly,
    onChange,
    onPublish,
    onRemove,
    onReset,
}: Props) {
    const [editedFields, setEditedFields] = useState({});
    const [editedItems, setEditedItems] = useState({});

    const [focusedField, setFocusedField] = useState<string | undefined>(undefined);
    const focusHandler = (fieldName: string) => setFocusedField(fieldName);
    const blurHandler = () => setFocusedField(undefined);

    const widgetChartItems = useMemo(() => widgetData?.chartItems || [], [widgetData]);

    const areChartItemsAdded = widgetChartItems.length > 0;
    const getNameFieldId = (ordinal: number) => `${FIELD_TOKENS.FIELD}_${ordinal}_${FIELD_TOKENS.NAME}`;
    const getValueFieldId = (ordinal: number) => `${FIELD_TOKENS.FIELD}_${ordinal}_${FIELD_TOKENS.VALUE}`;

    const chartItemsModel = useMemo(() => {
        const itemsModel = {} as Record<string, string | number>;
        for (const item of widgetChartItems) {
            itemsModel[getNameFieldId(item.ordinal)] = item.name;
            itemsModel[getValueFieldId(item.ordinal)] = item.value;
        }
        return itemsModel;
    }, [widgetChartItems]);

    const maxCategoriesReached = widgetChartItems.length >= MAX_WIDGET_CATEGORIES_NUM;

    const { validationMessages, isModelInvalid } = useValidation({
        model: widgetData,
        configuration: GraphChartValidationConfiguration,
        focused: focusedField,
        edited: editedFields,
    });

    const itemsValidationConfiguration = useMemo(
        () => getGraphChartValidationConfiguration(chartItemsModel),
        [chartItemsModel]
    );

    const { validationMessages: itemsValidationMessages, isModelInvalid: areItemsInvalid } = useValidation({
        model: chartItemsModel,
        configuration: itemsValidationConfiguration,
        focused: focusedField,
        edited: editedItems,
    });

    const addField = useCallback(() => {
        onChange({
            ...widgetData,
            chartItems: [
                ...widgetChartItems,
                {
                    name: '',
                    value: 0,
                    ordinal: getNextOrdinalNumber(widgetChartItems),
                },
            ],
        });
    }, [onChange, widgetData, widgetChartItems]);

    const getPreviousOrdinal = (currentOrdinal: number, removedOrdinal: number) =>
        currentOrdinal > removedOrdinal ? Math.max(currentOrdinal - 1, 0) : currentOrdinal;

    const removeField = useCallback(
        (ordinalNumber: number) => {
            setEditedItems({
                ...editedItems,
                [getNameFieldId(ordinalNumber)]: false,
                [getValueFieldId(ordinalNumber)]: false,
            });
            onChange({
                ...widgetData,
                chartItems: [
                    ...widgetChartItems
                        .filter((item) => item.ordinal !== ordinalNumber)
                        .map((item) => ({
                            ...item,
                            ordinal: getPreviousOrdinal(item.ordinal, ordinalNumber),
                        })),
                ],
            });
        },
        [onChange, widgetData, widgetChartItems]
    );

    const getUpdatedChartItem = (
        item: FinancialsWidgetChartItemCreateModel,
        updatedFieldName: string,
        value: string
    ) => {
        const [ordinalNumber, field] = updatedFieldName.split('_').slice(1);
        if (item.ordinal !== +ordinalNumber) {
            return item;
        }
        return {
            ...item,
            [field]: field === FIELD_TOKENS.VALUE ? (value ? +value : NaN) : value,
        };
    };

    const nameValueChangeHandler = useCallback(
        (name: string, value: string) => {
            if (name.startsWith(FIELD_TOKENS.FIELD)) {
                setEditedItems({ ...editedItems, [name]: true });
                if (name.endsWith(FIELD_TOKENS.VALUE)) {
                    value = value.replace(/[^0-9]/g, '');
                }
                onChange({
                    ...widgetData,
                    chartItems: [...widgetChartItems.map((item) => getUpdatedChartItem(item, name, value))],
                });
            } else {
                setEditedFields({ ...editedFields, [name]: true });
                onChange({
                    ...widgetData,
                    [name]: value,
                });
            }
        },
        [onChange, widgetData, widgetChartItems]
    );

    const currencyChangeHandler = useCallback(
        (selectedValue: SingleValue<DropdownOption>) => {
            setEditedFields({ ...editedFields, currency: true });
            onChange({
                ...widgetData,
                currency: selectedValue!.value as FinancialsWidgetCurrency,
            });
        },
        [onChange, widgetData]
    );

    const toggleChangeHandler = useCallback(
        (value: string) => {
            setEditedFields({ ...editedFields, type: true });
            onChange({ ...widgetData, type: value as FinancialsWidgetType });
        },
        [onChange, widgetData]
    );

    const toggleOptions = useMemo(
        () => [
            { label: 'Pie Chart', value: 'PieChart' },
            { label: 'Bar Graph', value: 'BarChart' },
        ],
        []
    );

    const currencyOptions = useMemo(
        () =>
            Object.values(CURRENCIES)
                .filter(
                    (currency) =>
                        currency !== CURRENCIES.credits &&
                        (widgetData.type !== 'BarChart' || currency !== CURRENCIES.percentages)
                )
                .map((currency) => ({ label: currency === CURRENCIES.percentages ? '%' : currency, value: currency })),
        [widgetData.type]
    );

    return (
        <div className="mb-8 flex w-full gap-1">
            <div className="flex w-[420px] flex-none flex-col rounded-l-sm bg-white px-6 py-8">
                <TextInput
                    hasError={!!validationMessages.title}
                    className="px-3.5 py-2.5 text-xl font-semibold leading-6"
                    name="title"
                    maxLength={VALIDATION_CONSTS.TITLE_MAX_LENGTH}
                    value={widgetData.title}
                    readOnly={isReadOnly}
                    onChange={nameValueChangeHandler}
                    onFocus={focusHandler}
                    onBlur={blurHandler}
                />
                {validationMessages.title && (
                    <ErrorLabel className="mt-1 text-sm text-error-700" errors={validationMessages.title} />
                )}
                {widgetData.title.length >= VALIDATION_CONSTS.TITLE_MAX_LENGTH && (
                    <div className="mt-1 text-sm">{`Maximum ${VALIDATION_CONSTS.TITLE_MAX_LENGTH} characters`}</div>
                )}
                <div className="my-6 flex w-full items-center justify-between gap-8">
                    <ToggleSwitch
                        isReadOnly={isReadOnly}
                        value={widgetData.type}
                        options={toggleOptions}
                        onChange={toggleChangeHandler}
                    />
                    <div className="w-32 bg-purewhite">
                        <Dropdown
                            isDisabled={isReadOnly}
                            options={currencyOptions}
                            value={currencyOptions.find((curr) => curr.value === widgetData.currency)}
                            onChange={currencyChangeHandler}
                        />
                    </div>
                </div>
                <div className="mb-6 flex w-full flex-col gap-4">
                    {widgetChartItems.sort(compareByOrdinal).map((field) => (
                        <div key={field.ordinal} className="flex items-start gap-4">
                            <div className="flex grow flex-col items-start gap-1">
                                <TextInput
                                    hasError={!!itemsValidationMessages[getNameFieldId(field.ordinal)]}
                                    className="w-full flex-1 px-3.5 py-2.5 text-left"
                                    maxLength={VALIDATION_CONSTS.FIELD_NAME_MAX_LENGTH}
                                    name={getNameFieldId(field.ordinal)}
                                    value={String(field.name)}
                                    readOnly={isReadOnly}
                                    placeholder="Add category"
                                    onChange={nameValueChangeHandler}
                                    onFocus={focusHandler}
                                    onBlur={blurHandler}
                                />
                                {field.name.length >= VALIDATION_CONSTS.FIELD_NAME_MAX_LENGTH && (
                                    <div className="mt-1 text-sm">{`Maximum ${VALIDATION_CONSTS.FIELD_NAME_MAX_LENGTH} characters`}</div>
                                )}
                                {!!itemsValidationMessages[getNameFieldId(field.ordinal)] && (
                                    <ErrorLabel
                                        className="mt-1 text-sm text-error-700"
                                        errors={itemsValidationMessages[getNameFieldId(field.ordinal)]!}
                                    />
                                )}
                            </div>
                            <div className="flex w-28 flex-col items-start gap-1">
                                <TextInput
                                    hasError={!!itemsValidationMessages[getValueFieldId(field.ordinal)]}
                                    className="w-full self-end px-3.5 py-2.5 text-right"
                                    name={getValueFieldId(field.ordinal)}
                                    value={Number.isNaN(field.value) ? '' : String(field.value)}
                                    maxLength={VALIDATION_CONSTS.FIELD_VALUE_MAX_LENGTH}
                                    readOnly={isReadOnly}
                                    onChange={nameValueChangeHandler}
                                    onFocus={focusHandler}
                                    onBlur={blurHandler}
                                />
                                {field.value.toString().length >= VALIDATION_CONSTS.FIELD_VALUE_MAX_LENGTH && (
                                    <div className="mt-1 text-sm">{`Maximum ${VALIDATION_CONSTS.FIELD_VALUE_MAX_LENGTH} digits`}</div>
                                )}
                                {!!itemsValidationMessages[getValueFieldId(field.ordinal)] && (
                                    <ErrorLabel
                                        className="mt-1 text-sm text-error-700"
                                        errors={itemsValidationMessages[getValueFieldId(field.ordinal)]!}
                                    />
                                )}
                            </div>
                            {!isReadOnly && (
                                <TrashIcon
                                    className="mt-3 h-4 w-4 cursor-pointer transition-transform hover:scale-125"
                                    onClick={() => removeField(field.ordinal)}
                                />
                            )}
                        </div>
                    ))}
                </div>
                {!isReadOnly && (
                    <>
                        <Button
                            buttonSize="sm"
                            buttonStyle="secondary"
                            onMouseDown={addField}
                            isDisabled={maxCategoriesReached}
                        >
                            <div className="flex items-center justify-center gap-2">
                                <PlusIcon className="h-4 w-4" />
                                Add Row
                            </div>
                        </Button>
                        {maxCategoriesReached && (
                            <span className="mt-4 text-xs">{`Maximum of ${MAX_WIDGET_CATEGORIES_NUM} rows reached`}</span>
                        )}
                        <div className="mt-6 w-full border-t border-gray-100" />
                        {isExceeding(widgetData) && (
                            <div className="my-1">
                                <ErrorLabel errors="Total percentage exceeds 100%" />
                            </div>
                        )}
                        <div className="mt-4 flex items-center justify-start gap-4">
                            <Button
                                buttonSize="sm"
                                onMouseDown={onPublish}
                                isDisabled={
                                    isModelInvalid || areItemsInvalid || !areChartItemsAdded || isExceeding(widgetData)
                                }
                            >
                                <span>Publish</span>
                            </Button>
                            <Button
                                buttonSize="sm"
                                buttonStyle="secondary"
                                onMouseDown={() => {
                                    setEditedFields({});
                                    setEditedItems({});
                                    onReset();
                                }}
                            >
                                <span>Reset</span>
                            </Button>
                            <Button usePadding={false} buttonSize="sm" buttonStyle="text" onMouseDown={onRemove}>
                                <span>Delete</span>
                            </Button>
                        </div>
                    </>
                )}
            </div>
            <div className="flex flex-1 flex-col justify-start rounded-r-sm bg-white px-6 py-9">
                <div className="mb-3">
                    <HeaderIndicator type={hasChanged ? HeaderIndicatorType.Draft : HeaderIndicatorType.Published} />
                </div>
                <div className="w-full border-t border-primary-50" />
                <div className="mt-6 flex flex-col rounded-sm border border-primary-50 px-6 py-8">
                    <GraphChartWidgetPreview widgetData={widgetData} />
                </div>
            </div>
        </div>
    );
}
